import {AuthConfig} from "../../_core/types/theme/Auth.type";

const auth: AuthConfig = {
  login: {
    seo: {
      title: "Login - MysticWork",
      description: ""
    }
  },
  register: {
    seo: {
      title: "Register - MysticWork",
      description: ""
    }
  },
  resetPassword: {
    seo: {
      title: "Reset Password - MysticWork",
      description: ""
    }
  },
}
export default auth;