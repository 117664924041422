<template>
    <a href="javascript:;" class="btn btn-primary is-scrolltop" :class="{ 'is-show': showButton }" @click="scrollTop()"
        v-show="isMounted">
        <font-awesome-icon icon="angle-up" />
    </a>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
library.add(faAngleUp)

export default {
    data() {
        return {
            scrollHeight: 0,
            isMounted: false,
            showButton: false,
        }
    },
    components: {
        FontAwesomeIcon,
    },
    mounted() {
        this.isMounted = true;
        this.scrollHeight = window.pageYOffset || document.documentElement.scrollTop;

        window.addEventListener('scroll', function (e) {
            this.scrollHeight = window.pageYOffset || document.documentElement.scrollTop;
        }.bind(this));
    },
    watch: {
        scrollHeight() {
            this.showButton = this.scrollHeight > 500
        },
    },
    methods: {
        scrollTop() {
            if (!import.meta.env.SSR) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.btn.is-scrolltop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;

    -webkit-transition: all ease-in-out .3s;
    -moz-transition: all ease-in-out .3s;
    -ms-transition: all ease-in-out .3s;
    -o-transition: all ease-in-out .3s;
    transition: all ease-in-out .3s;

    &.is-show {
        visibility: visible;
        opacity: 1;
    }
}
</style>
