<template>
	<div class="star-rating-wrapper" :class="{ 'is-hovered': hoveredIconIndex, 'big': bigMode }">
		<label v-for="rating in ratingsArray" :style="getLabelStyle(rating)" @mouseover="canChange && onHover(rating)"
			@mouseout="canChange && onHoverOut()" @click="canChange && onRateChange(rating)">
			<input type="checkbox" :value="rating">
			&#9733;<!--{{ icon }}-->
		</label>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			hoveredIconIndex: 0,
			color: '#fd711b',
			// icon: '&#9733;'
		};
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		bigMode: {
			type: Boolean,
			default: false,
		},
		'can-change': {
			type: Boolean,
			default: false,
		},
		'icons-count': {
			type: Number,
			default: 5,
		},
		'custom-icon': {
			type: String,
			default: '&#9733;',
		},
		'custom-color': {
			type: String,
			default: '#fd711b',
		}
	},
	mounted() {
		// // Validate custom icon
		// if (this.customIcon.length !== 1)
		// 	throw new Error('[Rating Component]: Custom icon must be a single character only!');
		// else
		// 	this.icon = this.customIcon;
		// Validate custom color
		if (!this.validateColor(this.customColor))
			throw new Error('[Rating Component]: Custom color is not valid!');
		else
			this.color = this.customColor;
	},
	methods: {
		onRateChange(rate) {
			console.info('rate changed', rate)
			this.$emit('rating-change', rate)
		},
		/**
		 * Change hovered icon index
		 *
		 * @param index | Number
		 */
		onHover(index) {
			this.hoveredIconIndex = index;
		},
		/**
		 * Clear hovered icon index
		 */
		onHoverOut() {
			this.hoveredIconIndex = 0;
		},
		/**
		 * Get color styles for icon
		 *
		 * @param rating
		 * @returns Object
		 */
		getLabelStyle(rating) {
			const styles = {};
			// If icon hovered
			if (this.hoveredIconIndex && this.hoveredIconIndex >= rating) {
				styles.color = this.color;
				styles.opacity = 0.5;
			}
			// If icon is selected but not hovered
			else if (this.selectedIcons.indexOf(rating) !== -1 && !this.hoveredIconIndex)
				styles.color = this.color;

			return styles;
		},
		/**
		 * Validate color
		 *
		 * @param color | String
		 * @returns {boolean}
		 */
		validateColor(color) {
			if (color === "" || color === "inherit" || color === "transparent")
				return false;
			const image = document.createElement("img");
			image.style.color = "rgb(0, 0, 0)";
			image.style.color = color;
			if (image.style.color !== "rgb(0, 0, 0)")
				return true;
			image.style.color = "rgb(255, 255, 255)";
			image.style.color = color;
			return image.style.color !== "rgb(255, 255, 255)";
		}
	},
	computed: {
		/**
		 * All selected icons
		 * @returns {number[]}
		 */
		selectedIcons() {
			if (!this.value) {
				return []
			} else {
				return Array(this.value).fill().map((e, i) => i + 1)
			}
		},
		/**
		 * All ratings array out of count
		 * @returns {number[]}
		 */
		ratingsArray() {
			return Array(this.iconsCount).fill().map((e, i) => i + 1);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

.star-rating-wrapper {
	display: inline-block;

	label {
		color: $gray;
		/*padding: 3px;*/
		line-height: 1;
		cursor: pointer;
		font-size: 1.3rem;
		display: inline-block;
		margin-bottom: 0;

		input {
			display: none;
		}

		&[disabled] {
			pointer-events: none;
		}
	}

	&.is-hovered {
		label {
			color: $gray;
		}
	}

	&.big {
		label {
			font-size: 2rem;
		}
	}
}
</style>
