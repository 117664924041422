<template>
  <div v-if="isPromoVisible && !$route.path.includes('/get-started')" class="bg-gradient-primary flex items-center justify-between w-full px-16 md:px-32">
    <!-- Dummy item for flex -->
    <div />
    <div class="px-16 py-12 !pl-0">
      <div class="flex items-center justify-between md:justify-center">
        <p class="text-white text-b-s md:text-h-l mr-16">
          <span class="font-semibold">3 FREE min</span><br class="md:hidden"> with each of your first 3
          {{ config.advisors }}, then <b>50% OFF</b>
        </p>
        <Link href="/register">
          <se-button type="tertiary" class="bg-white hover:bg-gray-100 !px-32 hidden md:inline-block md:visible">
            Try Now
          </se-button>
          <se-button type="tertiary" size="small" class="bg-white hover:bg-gray-100 !px-24 inline-block md:hidden">
            Try Now
          </se-button>
        </Link>
      </div>
    </div>
    <se-icon class="cursor-pointer" name="x" @click="closePromo" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { getStorageValue, setCookie } from '@/src/helpers/permanentValueStorage';
import { theme } from '@/theme';
import Link from '@/src/components/Link';
import SeButton from '@/src/shared/ui-kit/button/Button';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';

const config = theme.general;
const isPromoVisible = ref<boolean>(true);
isPromoVisible.value = getStorageValue('promoHidden') !== true;


const closePromo = () => {
  isPromoVisible.value = false;
  setCookie('promoHidden', true);
};
</script>

<script lang="ts">
export default {
  name: 'SeHeaderPromoBanner',
};
</script>
