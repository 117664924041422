<template>
	<li class="nav-item nav-search d-flex border align-items-center pt-0 shadow-sm">
		<button class="btn nav-link nav-link-search pt-1 pb-1 pl-1 " style="padding-left: 0.5rem" @click="openSearch">
			<font-awesome-icon icon="search" />
		</button>
		<div :class="{ open: showSearchInput || smallScreenMode }" class="full-width">
			<input type="text" ref="menu-search-input" placeholder="Search..." class="input full-width border-0 outline-0" style="height: 40px; outline: 0" @keyup.enter="submitSearch"
				v-model="search">
		</div>
	</li>
</template>

<script>
import { mapState } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
library.add(faSearch)

export default {
	name: "SearchMenu",
	data() {
		return {
			showSearchInput: false,
			search: '',
		}
	},
	components: {
		FontAwesomeIcon,
	},
	methods: {
		submitSearch() {
			if (this.search === '') {
				return false;
			}
			this.$router.push({ path: '/search', query: { q: this.search } })
		},
		openSearch() {
			this.showSearchInput = !this.showSearchInput
			if (this.showSearchInput) {
				this.$refs['menu-search-input'].focus()
			}
		},
	},
	computed: {
		...mapState(usersStore, [
			'categories',
		]),
		...mapState(rootStore, [
			'smallScreenMode',
		]),
	}
}
</script>

<style scoped lang="scss">
@import '@/src/styles/variables';

.navbar-container .search-input {
	padding-top: 0;
	float: none;
	display: inline;
}

li {
	padding-top: 0.5rem;
}

.nav-search .nav-link-search {
	padding-right: 0 !important;
	display: inline !important;
}


.nav-search {
  max-height: 3rem;

  @media (max-width: $desktop) and (min-width: $tablet) {
    max-width: 8.375rem;
  }
  @media (min-width: $desktop){
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}



.fixed-navbar .search-input input {
	padding: 0.5rem;
	border: solid 1px #404E67;
	max-width: calc(100% - 3rem);
}</style>
