import Form from '@/src/helpers/form-validation'
import { TimeCalculations } from '@/src/helpers/timeCalculations'
import _ from '@/src/helpers/arrayHelpers'
import { isValidMessage } from "@/src/helpers/messageChecker"
import Compressor from 'compressorjs'
import { defineStore } from 'pinia'
import { usersStore } from '@/src/store/users'
import { chatStore } from '@/src/store/chat'

export const inboxStore = defineStore('inbox', {
	namespaced: true,
	state: () => ({
		currentInbox: {},
		currentInboxMessages: [],
		message: '',
		sendingMessage: false,
		reading: false,
		closing: false,
		//appointments fields
		appointmentForm: {
			appointment_type_id: null,
			date: '',
			time: '',
			comment: '',
			datetime: '',
			user_id: null,
			duration: 0,
			inbox_id: 0,
			sender_id: 0,
		},
		// appointmentForm: new Form({
		// 	appointment_type_id: null,
		// 	date: '',
		// 	time: '',
		// 	comment: '',
		// 	datetime: '',
		// 	user_id: null,
		// 	duration: 0,
		// 	inbox_id: 0,
		// 	sender_id: 0,
		// }),
		dayEventsLoading: false,
		isActiveAppointmentModal: false,
		userTimeZone: 'UTC',
		currentInboxUserTzOffset: 0,
		currentUserTzOffset: 0,
		visitorTimeZone: 'UTC',
		intervals: {
			start: '09:00',
			finish: '18:00',
			step: 30
		},
		availability: [],
		dayEvents: [],
		//charge related
		isActiveChargeModal: false,
		notEnoughMoneyInbox: false,
		//fre minutes sending
		isActiveFreeMinutesModal: false,
		loadingFreeMinutes: false,
		//GLOBAL LAYOUT
		isActiveMessageModal: false,
		recepient: null,
		savedRecepient: {},
		//loading inbox messages
		loadingMessages: false,
		//loading charging
		loadingCharge: false,
		updatingCharge: 0,
		//receivedInboxMessages
		// receivedInboxMessages: []
	}),
	getters: {
		pendingAppointments() {
			if (!this.chatInfo || !this.chatInfo.appointments)
				return [];
			return _.filter(this.chatInfo.appointments, item => item.status === 'pending');
		},
		acceptedAppointments() {
			if (!this.chatInfo || !this.chatInfo.appointments)
				return [];
			return _.filter(this.chatInfo.appointments, item => item.status === 'accepted');
		},
		pendingAppointmentsIds(getters) {
			return _.map(getters.pendingAppointments, 'id')
		},
		disabledTime() {
			if (!this.dayEvents.today)
				return [];
			let time = [];

			this.dayEvents.today.forEach(event => {
				for (let i = 0; i < event.type.duration; i++) {
					time.push(TimeCalculations.rawFormat(
						TimeCalculations.rawSetTimezone(
							TimeCalculations.rawAddTime(
								TimeCalculations.rawMomentObject(event.start_at, '', this.currentInbox.opponent_info.timezone), 
								i, 'm'
							), 
							this.visitorTimeZone
						),
						'HH:mm'
					))
				}
			});
			return time;
		},
		timeIntervals(getters) {
			let intervals = [];
			let start_explode = this.intervals.start.toString().split(':');
			let hrs = parseInt(start_explode[0]) + this.currentInboxUserTzOffset - this.currentUserTzOffset;
			let min = parseInt(start_explode[1]);

			let finish_explode = this.intervals.finish.toString().split(':');
			let fhrs = parseInt(finish_explode[0]) + this.currentInboxUserTzOffset - this.currentUserTzOffset;
			let fmin = parseInt(finish_explode[1]);

			if (hrs < 0)
				hrs = 0;

			if (fhrs > 24)
				fhrs = 24;

			let id = 0;
			while (fhrs >= hrs) {
				if (fhrs === hrs && min + this.intervals.step > fmin)
					break;
				if (hrs >= 24)
					break;
				let hours = hrs.toString().length === 1 ? '0' + hrs.toString() : hrs;
				let minutes = min.toString().length === 1 ? '0' + min.toString() : min;


				let disabled = getters.disabledTime.indexOf(hours + ':' + minutes) !== -1;
				if (!disabled) {
					for (let i = 0; i < this.intervals.step; i++) {
						let checkMin = min + i;
						let checkHrs = hrs;
						if (checkMin > 60) {
							checkMin = checkMin - 60;
							checkHrs += 1;
						}
						let checkhrs_string = checkHrs.toString().length === 1 ? '0' + checkHrs.toString() : checkHrs;
						let checkmins_string = checkMin.toString().length === 1 ? '0' + checkMin.toString() : checkMin;
						let checkstr = checkhrs_string + ':' + checkmins_string;
						if (getters.disabledTime.indexOf(checkstr) !== -1) {
							disabled = true;
							break;
						}
					}
					// disabled = this.isTimeDisabled(hrs, min);
				}

				intervals.push({
					value: hours + ':' + minutes,
					label: hours + ':' + minutes,
					disabled: disabled,
					id: id++
				});

				let res = min + this.intervals.step;
				if (res < 60)
					min = res;
				else {
					min = res - 60;
					hrs++;
				}
			}
			return intervals;
		},
		tomorrowIntervalsInfo(getters) {
			if (!this.appointmentForm.date)
				return null;
			let day = new Date(this.appointmentForm.date);
			let dayIndex = day.getDay() + 1 > 6 ? 0 : day.getDay() + 1;
			let dayOfWeek = 'monday';
			switch (dayIndex) {
				case 1:
					dayOfWeek = 'monday';
					break;
				case 2:
					dayOfWeek = 'tuesday';
					break;
				case 3:
					dayOfWeek = 'wednesday';
					break;
				case 4:
					dayOfWeek = 'thursday';
					break;
				case 5:
					dayOfWeek = 'friday';
					break;
				case 6:
					dayOfWeek = 'saturday';
					break;
				case 0:
					dayOfWeek = 'sunday';
					break;
			}
			let intervals = null;
			this.availability.forEach(item => {
				if (item.day === dayOfWeek && item.available)
					intervals = {
						start: item.start_time,
						finish: item.end_time,
						step: this.intervals.step
					}
			});
			return intervals;
		},
		tomorrowIntervals(getters) {
			if (!getters.tomorrowIntervalsInfo)
				return [];
			let intervals = [];
			let start_explode = getters.tomorrowIntervalsInfo.start.toString().split(':');
			let hrs = parseInt(start_explode[0]) + this.currentInboxUserTzOffset - this.currentUserTzOffset;
			let min = parseInt(start_explode[1]);

			let finish_explode = getters.tomorrowIntervalsInfo.finish.toString().split(':');
			let fhrs = parseInt(finish_explode[0]) + this.currentInboxUserTzOffset - this.currentUserTzOffset;
			let fmin = parseInt(finish_explode[1]);

			if (hrs < 0) {
				hrs = hrs + 24;
				fhrs = 24;
			} else {
				return [];
			}

			let id = 0;
			while (fhrs >= hrs) {
				if (fhrs === hrs && min + this.intervals.step > fmin)
					break;
				if (hrs >= 24)
					break;
				let hours = hrs.toString().length === 1 ? '0' + hrs.toString() : hrs;
				let minutes = min.toString().length === 1 ? '0' + min.toString() : min;


				let disabled = getters.disabledTime.indexOf(hours + ':' + minutes) !== -1;
				if (!disabled) {
					for (let i = 0; i < this.intervals.step; i++) {
						let checkMin = min + i;
						let checkHrs = hrs;
						if (checkMin > 60) {
							checkMin = checkMin - 60;
							checkHrs += 1;
						}
						let checkhrs_string = checkHrs.toString().length === 1 ? '0' + checkHrs.toString() : checkHrs;
						let checkmins_string = checkMin.toString().length === 1 ? '0' + checkMin.toString() : checkMin;
						let checkstr = checkhrs_string + ':' + checkmins_string;
						if (getters.disabledTime.indexOf(checkstr) !== -1) {
							disabled = true;
							break;
						}
					}
					// disabled = this.isTimeDisabled(hrs, min);
				}

				intervals.push({
					value: hours + ':' + minutes,
					label: hours + ':' + minutes,
					disabled: disabled,
					id: id++
				});

				let res = min + this.intervals.step;
				if (res < 60)
					min = res;
				else {
					min = res - 60;
					hrs++;
				}
			}
			return intervals;
		},
		yesterdayIntervalsInfo(getters) {
			if (!this.appointmentForm.date)
				return null;
			let day = new Date(this.appointmentForm.date);
			let dayIndex = day.getDay() - 1 < 0 ? 6 : day.getDay() - 1;
			let dayOfWeek = 'monday';
			switch (dayIndex) {
				case 1:
					dayOfWeek = 'monday';
					break;
				case 2:
					dayOfWeek = 'tuesday';
					break;
				case 3:
					dayOfWeek = 'wednesday';
					break;
				case 4:
					dayOfWeek = 'thursday';
					break;
				case 5:
					dayOfWeek = 'friday';
					break;
				case 6:
					dayOfWeek = 'saturday';
					break;
				case 0:
					dayOfWeek = 'sunday';
					break;
			}
			let intervals = null;
			this.availability.forEach(item => {
				if (item.day === dayOfWeek && item.available)
					intervals = {
						start: item.start_time,
						finish: item.end_time,
						step: this.intervals.step
					}
			});
			return intervals;
		},
		yesterdayIntervals(getters) {
			if (!getters.yesterdayIntervalsInfo)
				return [];
			let intervals = [];
			let start_explode = getters.yesterdayIntervalsInfo.start.toString().split(':');
			let hrs = parseInt(start_explode[0]) + this.currentInboxUserTzOffset - this.currentUserTzOffset;
			let min = parseInt(start_explode[1]);

			let finish_explode = getters.yesterdayIntervalsInfo.finish.toString().split(':');
			let fhrs = parseInt(finish_explode[0]) + this.currentInboxUserTzOffset - this.currentUserTzOffset;
			let fmin = parseInt(finish_explode[1]);

			if (fhrs > 24) {
				hrs = 0;
				fhrs = fhrs - 24;
			} else {
				return [];
			}

			let id = 1;
			while (fhrs >= hrs) {
				if (fhrs === hrs && min + this.intervals.step > fmin)
					break;
				if (hrs >= 24)
					break;
				let hours = hrs.toString().length === 1 ? '0' + hrs.toString() : hrs;
				let minutes = min.toString().length === 1 ? '0' + min.toString() : min;


				let disabled = getters.disabledTime.indexOf(hours + ':' + minutes) !== -1;
				if (!disabled) {
					for (let i = 0; i < this.intervals.step; i++) {
						let checkMin = min + i;
						let checkHrs = hrs;
						if (checkMin > 60) {
							checkMin = checkMin - 60;
							checkHrs += 1;
						}
						let checkhrs_string = checkHrs.toString().length === 1 ? '0' + checkHrs.toString() : checkHrs;
						let checkmins_string = checkMin.toString().length === 1 ? '0' + checkMin.toString() : checkMin;
						let checkstr = checkhrs_string + ':' + checkmins_string;
						if (getters.disabledTime.indexOf(checkstr) !== -1) {
							disabled = true;
							break;
						}
					}
					// disabled = this.isTimeDisabled(hrs, min);
				}

				intervals.push({
					value: hours + ':' + minutes,
					label: hours + ':' + minutes,
					disabled: disabled,
					id: id++
				});

				let res = min + this.intervals.step;
				if (res < 60)
					min = res;
				else {
					min = res - 60;
					hrs++;
				}
			}
			return intervals;
		},
		allIntervals(getters) {
			return [...getters.yesterdayIntervals, ...getters.timeIntervals, ...getters.tomorrowIntervals];
		},
	},
	actions: {
		setIsReading(value) {
			this.reading = value;
		},
		saveInboxRecepient(user) {
			this.savedRecepient = user;
		},
		// closeMessageModal() {
		// 	if (this.isActiveMessageModal)
		// 		this.message = '';
		// 	this.isActiveMessageModal = false;
		// 	this.recepient = null;
		// 	this.savedRecepient = {};
		// },
		setCurrentInboxValue(value) {
			this.currentInbox = value;
		},
		setLoadingMessages(value) {
			this.loadingMessages = value;
		},
		setAppointmentFormIDs(value) {
			this.appointmentForm.sender_id = value.user_id;
			this.appointmentForm.inbox_id = value.inbox_id;
		},
		setMessage(value) {
			this.message = value;
		},
		setSendingMessage(value) {
			this.sendingMessage = value;
		},
		setClosingInbox(value) {
			this.closing = value;
		},
		setChargeModalState(modal) {
			this.isActiveChargeModal = modal;
		},
		setFreeMinutesModalState(modal) {
			this.isActiveFreeMinutesModal = modal;
		},
		setLoadingFreeMinutes(value) {
			this.loadingFreeMinutes = value;
		},
		setLoadingCharge(value) {
			this.loadingCharge = value;
		},
		setUpdatingCharge(value) {
			this.updatingCharge = value;
		},
		setDeleteTempMessage(message) {
			this.currentInbox.messages.forEach(item => {
				if (item.id === message.id)
					this.currentInbox.messages.splice(this.currentInbox.messages.indexOf(message), 1);
			});
		},
		// setNewInboxMessage(message) {
		// 	if (this.currentInbox?.messages) {
		// 		this.currentInbox.messages.push(message);
		// 	}
		// 	if (this.currentUser?.inbox) {
		// 		this.currentUser.inbox.push(message);
		// 	}
		// },
		setMessageFailed(temp_id) {
			if (!this.currentInbox?.messages) {
				return
			}
			this.currentInbox.messages.forEach(item => {
				if (item.id === temp_id) {
					item.sending = false;
					item.failed = true;
					// this.chatInfo = JSON.parse(JSON.stringify(this.chatInfo));
				}
			});
		},
		// setReplaceTempMessage(payload) {
		// 	if (!this.currentInbox || !this.currentInbox.messages) {
		// 		return false;
		// 	}
		// 	this.currentInbox.messages.forEach(item => {
		// 		if (item.id === payload.temp_id) {
		// 			let msg = payload.message;
		// 			msg.is_unread = true;
		// 			this.currentInbox.messages[this.currentInbox.messages.indexOf(item)] = msg;
		// 			// this.chatInfo = JSON.parse(JSON.stringify(this.chatInfo));
		// 		}
		// 	});
		// },
		setSavedRecipient(recipient) {
			this.savedRecepient = recipient;
		},

		// setNewReceivedInboxMessage(value) {
		// 	console.info('NEW RECEIVED MESSAGE')
		// 	this.receivedInboxMessages.push(value)

		// 	// Updating inbox and inboxShort list.
		// 	const users = usersStore()
		// 	users.getInbox({full: false, open: true, closed: true})
		// },
		closeMessageModal() {
			if (this.isActiveMessageModal) {
				this.message = ''
			}
			this.isActiveMessageModal = false
			this.recepient = null
			this.savedRecepient = {}
		},
		//get inbox messages
		async getMessages() {
			const users = usersStore()
			let id = this.currentInbox.id;
			this.setLoadingMessages(true)
			await this.$api.get(`/api/user/inbox/${id}/messages`)
				.then(res => {
					users.loadInboxMessages({ id: id, messages: res.data })
					let inb = null;
					users.currentUser.inbox.forEach(item => {
						if (item.id === id)
							inb = item;
					});
					if (inb !== null && res.data.length) {
						this.setCurrentInbox(inb)
					}
					this.setLoadingMessages(false)
				})
				.catch(async error => {
					this.setLoadingMessages(false)
				});
		},
		//get inbox messages
		loadMore(skip = 0) {
			// let skip = ;
			let id = this.currentInbox.id;
			this.loadingMessages = true;
			const users = usersStore()
			return this.$api.get(`/api/user/inbox/${id}/messages?skip=${skip}`)
				.then(res => {
					const inbox = users.currentUser.inbox.find(item => item.id === id)
					if (inbox)
						inbox.messages.unshift(...res.data);
					this.loadingMessages = false;
					return true;
				})
				.catch(error => this.loadingMessages = false);
		},
		openMessageModal(recepient) {
			if (!this.$auth.check()) {
				return false;
			}

			this.$api.get('/api/user/inbox/check', {
				recipient_id: recepient.id,
			}).then(({ data }) => {
				if (data.needs_subscription) {
					this.$router.push({ path: `/advisor/${recepient.url}/subscribe` });

					return;
				}

				if (data.inbox) {
					this.isActiveMessageModal = false;
					this.$router.push({ path: '/dashboard/inbox', query: { id: data.inbox } });
				} else {
					this.setSavedRecipient(recepient);
					this.isActiveMessageModal = true;
					this.recepient = recepient.id;
					this.message = '';
				}
			})
			.catch(error => {
				if (error.response) {
					this.$toast({
						message: '<b>Error: </b>' + (error.response.message || error.response),
						type: 'error'
					});
				}
			})
		},
		//open message modal
		forceOpenMessageModal(advisor) {
			if (import.meta.env.SSR) {
				return false
			}

			if (!this.$auth?.check()) {
				this.$router.push({
					path: '/get-started',
					// path: '/register',
					query: { redirect_url: this.$router.route.path, action: 'sendInboxMessage', param: advisor.id }
				})
			} else {
				this.openMessageModal(advisor)
			}
		},
		setCurrentInbox(inbox) {
			this.setCurrentInboxValue({})
			this.setCurrentInboxValue(inbox)
			this.$router.push({
				path: this.$router.route.path,
				query: { id: inbox.id },
			}, true)
			if (inbox.unread_count > 0) {
				this.readInbox(inbox.id)
			}
		},
		sendMessage() {
			if (!this.$auth.check()) {
				return false;
			}
			if (this.message === '' || this.message.trim() === '') {
				this.message = '';
				return false;
			}
			// let temp_id = 'temp_' + Math.floor((Math.random() * (99 - 10 + 1) + 10));

			let recepient = this.recepient ? this.recepient : this.currentInbox.opponent_info.id;
			let request_data = {
				'message': this.message,
				'recepient_id': recepient,
			};

			const users = usersStore()
			// let msg = {
			// 	id: temp_id,
			// 	sender_id: users.currentUser.id,
			// 	inbox_id: this.currentInbox && this.currentInbox.id ? this.currentInbox.id : null,
			// 	body: this.message,
			// 	sending: true,
			// 	failed: false,
			// 	type: 'text'
			// };

			if (!this.isActiveMessageModal)
				this.setMessage('');

			// if (this.currentInbox && this.currentInbox.id) {
			// 	this.setNewInboxMessage(msg);
			// 	// this.currentInbox = JSON.parse(JSON.stringify(this.currentInbox));
			// }

			request_data.status = users.usersInApp.indexOf(recepient) === -1 ? 'offline' : 'online';
			this.setSendingMessage(true);
			return this.$api.post('/api/user/inbox/message', request_data)
				.then(res => {
					this.setSendingMessage(false);
					// this.message = '';
					this.checkMessage(res.data);
					// this.setReplaceTempMessage({temp_id: temp_id, message: res.data});
					if (this.isActiveMessageModal)
						this.$toast({
							message: 'Your inbox message has been sent.',
							type: 'success'
						});
					this.closeMessageModal()
					console.info(res);
					return res.data;
				})
				.catch(error => {
					this.setSendingMessage(false);
					this.closeMessageModal()
					// this.setMessageFailed(temp_id);
					console.info(error, error.response);
					if (error.response) {
						this.$toast({
							message: '<b>Error: </b>' + (error.response.message || error.response),
							type: 'error'
						});
					} else if (error.response?.data) {
						this.$toast({
							message: '<b>Error: </b>' + error.response.data,
							type: 'error'
						});
					}
					return false;
				})
		},
		checkMessage(msg) {
			console.info('checking message', msg, isValidMessage(msg.body))
			const users = usersStore()
			if (!isValidMessage(msg.body)) {
				this.$api.post('/personal-info-sharing', {
					type: 'words',
					source: 'inbox',
					source_id: msg.id,
					user_id: users.currentUser.id
				})
					.then(res => {
						console.info(res);
					})
			}
		},
		sendImage(event) {
			const users = usersStore()
			const _this = this
			new Compressor(event.target.files[0], {
				maxWidth: 1500,
				maxHeight: 1500,
				convertTypes: 'image/png',
				convertSize: 500000, // Large PNG images will be converted to JPEG
				success(result) {
					let recepient = _this.recepient ? _this.recepient : _this.currentInbox.opponent_info.id;
					let data = new FormData();
					data.append('type', 'image');
					data.append('recepient_id', recepient);
					data.append('status', users.usersInApp.indexOf(recepient) === -1 ? 'offline' : 'online');
					data.append('image', result, result.name);

					_this.setSendingMessage(true);
					_this.$api.post('/api/user/inbox/message', data)
						.then(res => {
							_this.setSendingMessage(false);
							// _this.message = '';
							_this.closeMessageModal()
							// _this.$toast({
							// 	message: 'Your message were sent.',
							// 	type: 'success'
							// });
							console.info(res);
						})
						.catch(error => {
							_this.setSendingMessage(false);
							_this.closeMessageModal()
							console.info(error, error.response);

							if (error.response) {
								_this.$toast({
									message: '<b>Error: </b>' + (error.response.message || error.response),
									type: 'error'
								});
							}
						})
				},
				error(err) {
					_this.$toast({
						message: '<b>Error: </b>' + err.message,
						type: 'error'
					});
				},
			});
		},
		readInbox(id) {
			this.setIsReading(true);
			this.$api.post(`/api/user/inbox/${id}/read`, {})
				.then(res => {
					console.info(res);
					this.setIsReading(false);
					// this.reading = false;
				})
				.catch(error => {
					this.setIsReading(false);
					console.info(error, error.response);
				})
		},
		readCurrentInbox() {
			if (this.currentInbox.unread_count > 0 && !this.reading)
				this.readInbox(this.currentInbox.id);
		},
		//appointment for setters
		setAppointmentUserId() {
			const users = usersStore()
			this.setAppointmentFormIDs({ user_id: users.currentUser.id, inbox_id: this.currentInbox.id })
		},
		setAppointmentDate(date) {
			this.appointmentForm.date = date;
		},
		setAppointmentTime(time) {
			this.appointmentForm.time = time;
		},
		setAppointmentType(type) {
			this.appointmentForm.appointment_type_id = type.id;
			this.appointmentForm.duration = type.duration;
		},
		resetAppointmentForm() {
			(new Form(this.appointmentForm)).reset();
			this.setAppointmentUserId();
		},
		scheduleEvent() {
			(new Form(this.appointmentForm)).post(this.$api, '/api/user/appointments/event')
				.then(res => {
					this.resetAppointmentForm();
					this.setModalState(null, false);
				})
		},
		setUserAvailability(availability) {
			this.availability = availability;
		},
		setModalState(statement) {
			this.isActiveAppointmentModal = statement;
		},
		requestCharge(form) {
			this.setLoadingCharge(true);
			form.submit(this.$api, 'post', '/api/user/inbox/charge')
				.then(res => {
					this.setChargeModalState(false);
					this.setLoadingCharge(false);
					console.info('res', res)
				})
				.catch(error => {
					console.info(error, error.response)
					if (error.response) {
						console.info('err', error.response)
						this.$toast({
							message: '<b>Error: </b>' + error.response.data,
							type: 'error'
						});
					} else if (typeof error === 'string') {
						console.info('err', error)
						this.$toast({
							message: '<b>Error: </b>' + error,
							type: 'error'
						});
					}
					this.setChargeModalState(false);
					this.setLoadingCharge(false);
				})
		},
		sendFreeMinutes(payload) {
			this.setLoadingFreeMinutes(true);
			this.$api.post(`/api/user/inbox/${payload.inbox_id}/free-minutes`, payload)
				.then(res => {
					this.setFreeMinutesModalState(false);
					this.setLoadingFreeMinutes(false);
					console.info(res);
				})
				.catch(err => {
					console.info(err, err.response);
					this.setFreeMinutesModalState(false);
					this.setLoadingFreeMinutes(false);
				})
		},
		acceptCharge(charge) {
			const users = usersStore()
			if (Number(users.currentUser.amount) < Number(charge.amount)) {
				const chat = chatStore()
				chat.saveAction('acceptCharge')
				chat.saveParam(charge.requester_id)
				this.setNotEnoughMoneyInbox(true)
				users.setChargeAmount(charge.amount)
				return false
			}
			this.readInbox(this.currentInbox.id);
			this.setUpdatingCharge(charge.id);
			this.$api.put(`/api/user/inbox/charge/${charge.id}`, { status: 'accepted' })
				.then(res => {
					console.info(res.data);
					this.setUpdatingCharge(0);
				})
				.catch(err => {
					this.setUpdatingCharge(0);
				})
		},
		rejectCharge(id) {
			this.readInbox(this.currentInbox.id);
			this.setUpdatingCharge(id);
			this.$api.put(`/api/user/inbox/charge/${id}`, { status: 'rejected' })
				.then(res => {
					console.info(res.data);
					this.setUpdatingCharge(0);
				})
				.catch(err => {
					this.setUpdatingCharge(0);
				})
		},
		cancelCharge(id) {
			this.setUpdatingCharge(id);
			this.$api.put(`/api/user/inbox/charge/${id}`, { status: 'canceled' })
				.then(res => {
					console.info(res.data);
					this.setUpdatingCharge(0);
				})
				.catch(err => this.setUpdatingCharge(0))
		},
		setNotEnoughMoneyInbox(value) {
			this.notEnoughMoneyInbox = value;
		},
		closeInbox(id) {
			this.setClosingInbox(true);
			this.$api.put(`/api/user/inbox/${id}`, { is_closed: true })
				.then(res => this.setClosingInbox(false))
				.catch(error => this.setClosingInbox(false))
		},
		openInbox(id) {
			this.setClosingInbox(true);
			this.$api.put(`/api/user/inbox/${id}`, { is_closed: false })
				.then(res => this.setClosingInbox(false))
				.catch(error => this.setClosingInbox(false))
		}
	}
});
