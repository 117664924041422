<template>
  <header class="sticky top-0 left-0 w-full bg-white z-[101]">
    <div
      class="px-16 md:px-32 py-12 md:py-16 border-b border-neutral-20 flex justify-between items-center l-header"
    >
      <div class="flex-grow flex justify-between items-center">
        <div class="flex items-center">
          <div v-if="!$route.path.includes('/get-started')" class="mr-16 md:mr-24 lg:hidden" @click="isMenuOpen = true">
            <se-icon name="menu" :size="36" class="hidden md:visible md:flex" />
            <se-icon name="menu" :size="24" class="flex md:hidden" />
          </div>
          <div>
            <Link :href="isAdvisor ? '/' : '/home'">
              <img alt="Logo" :src="config.logoDark" class="h-32 md:h-48 inline-block" />
            </Link>
          </div>
        </div>
        <div class="flex items-center">
          <nav class="l-header__nav is-mobile" :class="{ 'is-open': isMenuOpen }">
            <div class="p-16 border-b border-neutral-20 w-full mb-8">
              <div class="cursor-pointer" @click="isMenuOpen = false">
                <se-icon name="x" :size="24" />
              </div>
            </div>
            <div class="px-16 py-12" @click="isAdvisor ? $router.push('/') : $router.push('/home')">
              <img alt="Logo" :src="config.logoDark" class="h-40" />
            </div>
            <template v-if="isAdvisor || isUser">
              <Link v-if="isAdvisor" href="/" class="flex items-center">
                <se-icon name="home" :size="20" class="mr-16" />
                Home
              </Link>
              <Link href="/home" class="flex items-center">
                <se-icon name="layout-dashboard" :size="20" class="mr-16" />
                {{ isUser ? 'Advisors' : 'Dashboard'}}
              </Link>
              <se-header-categories>
                <div class="flex items-center">
                  <se-icon name="eye" :size="20" class="mr-16" />
                  Categories
                </div>
              </se-header-categories>
            </template>

            <se-header-menu />
          </nav>
          <se-header-search />
        </div>
      </div>
      <div v-if="currentUser?.id" class="pl-24 md:pl-16 flex gap-4 items-center">
        <se-header-notifications />
        <se-header-user-menu>
          <se-avatar :id="currentUser.id" :src="currentUser.avatar" :name="currentUser.name" :size="48" />
        </se-header-user-menu>
      </div>
    </div>
    <se-header-alert-banner />
    <nav v-if="!isPaymentPage" class="l-header__nav l-header__sub">
      <template v-if="isAdvisor || isUser">
        <Link href="/home" class="flex items-center">
          <se-icon name="layout-dashboard" :size="20" class="mr-8" />
          <span class="font-semibold">{{ isUser ? 'Advisors' : 'Dashboard'}}</span>
        </Link>
        <Link v-if="isAdvisor" href="/" class="flex items-center">
          <se-icon name="home" :size="20" class="mr-8" />
          <span class="font-semibold">Home</span>
        </Link>
        <se-header-categories>
          <div class="flex items-center">
            <se-icon name="eye" :size="20" class="mr-8" />
            <span class="font-semibold">Categories</span>
          </div>
        </se-header-categories>
      </template>

      <se-header-menu class="!w-auto header-link" />
    </nav>
  </header>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { usersStore } from '@/src/store/users';
import Link from '@/src/components/Link';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeAvatar from '@/src/shared/ui-kit/avatar/Avatar';
import { theme } from '@/theme';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import SeHeaderCategories from './HeaderCategories';
import SeHeaderSearch from './HeaderSearch';
import SeHeaderNotifications from './HeaderNotifications';
import SeHeaderMenu from './HeaderMenu';
import SeHeaderUserMenu from './menus/UserMenu';
import SeHeaderAlertBanner from './HeaderAlertBanner';

const { $router, $route } = getGlobalProperties();
const { currentUser, isAdvisor, isUser } = storeToRefs(usersStore());

const config = theme.general;

const isMenuOpen = ref<boolean>(false);

const isPaymentPage = computed(() => [
  '/get-started',
].includes($route.path));
</script>

<script lang="ts">
export default {
  name: 'SeHeaderAuth',
};
</script>
