<template>
  <div class="w-full" v-bind="$attrs">
    <Link href="/advisors/online" class="flex items-center">
      <se-icon name="user-round" :size="20" class="mr-16" />Advisors Online
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/how-it-works" class="flex items-center">
      <se-icon name="message-circle-question" :size="20" class="mr-16" />
      How It Works?
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/about" class="flex items-center">
      <se-icon name="building-2" :size="20" class="mr-16" />About Us
    </Link>
  </div>
</template>

<script lang="ts" setup>

import Link from '@/src/components/Link';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenuGuest',
};
</script>
