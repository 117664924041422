<template>
	<BootstrapModal :is-active="isActiveMessageModal" :heading="heading" @close="closeMessageModal"
		@submit="forceSendMessage" @cancel="closeMessageModal" :submit-disabled="submitDisabled"
		:submit-loading="sendingMessage" submit-text="Send">
		<fieldset class="form-group">
			<label>Type message and click send button.</label>
			<textarea class="form-control" v-model="message" placeholder="Type your message here..."></textarea>
		</fieldset>
	</BootstrapModal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { inboxStore } from '@/src/store/inbox'

import BootstrapModal from '@/src/components/BootstrapModal.vue'

let inbox = null
export default {
	components: {
		BootstrapModal,
	},
	data() {
		return {
		}
	},
	setup() {
		inbox = inboxStore()
	},
	methods: {
		...mapActions(inboxStore, [
			'sendMessage',
			'closeMessageModal',
		]),
		forceSendMessage() {
			this.sendMessage()
		}
	},
	computed: {
		...mapState(inboxStore, [
			'isActiveMessageModal',
			'sendingMessage',
			// 'message',
			'savedRecepient',
		]),
		message: {
			get: () => inbox.message,
			set: val => {
				inbox.setMessage(val)
			}
		},
		heading() {
			return this.savedRecepient?.name ? `To ${this.savedRecepient.name}` : 'Send message'
		},
		submitDisabled() {
			return !this.message?.length
		},
	}
}
</script>
