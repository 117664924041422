import { TimeCalculations } from '@/src/helpers/timeCalculations'
import { defineStore } from 'pinia'
import { usersStore } from '@/src/store/users'

export const presenceStore = defineStore('presence', {
    namespaced: true,
    state: () => ({
        //stay online timer
        stayOnlineTimer: null,
        stayOnlineCountdownTimer: null,
        stayOnlineCountdownTimerSeconds: 50,
    }),
    getters: {},
    actions: {
        setTimer(value) {
            this.stayOnlineTimer = value;
        },
        setTimerSeconds(value) {
            this.stayOnlineCountdownTimerSeconds = value;
        },
        setCountdownTimer(value) {
            this.stayOnlineCountdownTimer = value;
        },
        makeAnAction() {
            const users = usersStore()
            if (users.currentUser?.status !== 'online' || this.stayOnlineCountdownTimer) {
                if (!import.meta.env.SSR && this.stayOnlineTimer)
                    this.stayOnlineTimer.cancel();
                return false;
            }

            if (this.stayOnlineTimer)
                this.stayOnlineTimer.cancel();

            this.makeActionTimer();
        },
        makeActionTimer() {
            // register the global timer
            let _this = this
            let id = setTimeout(function () {
                _this.setTimerSeconds(50);
                _this.makeActionCountdown(Date.now());
                //loop the call sound
                _this.$chatSound.play();
            }, 1000 * 60 * 360);
            // }, 1000 * 10);
            //@TODO change time
            // return a Global Timer object
            this.setTimer({
                "id": function () {
                    return id;
                },
                "cancel": function () {
                    if (id !== null) {
                        clearInterval(id);
                        id = null;
                    }
                }
            })
        },
        makeActionCountdown(start_time) {
            // register the global timer
            let _this = this
            let id = setInterval(function () {
                let seconds = TimeCalculations.getSecondsFromDate(start_time, Date.now())
                if (50 - seconds <= 0) {
                    _this.goOfflineAction();
                    // console.info('times up');
                    if (_this.stayOnlineCountdownTimer) {
                        _this.stayOnlineCountdownTimer.cancel();
                        _this.stayOnlineCountdownTimer = null;
                    }
                }
                _this.setTimerSeconds(50 - seconds);
                // console.info('timer', seconds)
                // _this.stayOnlineCountdownTimerSeconds = 50 - seconds;
            }, 1000);

            // return a Global Timer object
            this.setCountdownTimer({
                "id": function () {
                    return id;
                },
                "cancel": function () {
                    if (id !== null) {
                        clearInterval(id);
                        id = null;
                    }
                }
            });
            // this.stayOnlineCountdownTimer = {
            //     "id": function () {
            //         return id;
            //     },
            //     "cancel": function () {
            //         if (id !== null) {
            //             clearInterval(id);
            //             id = null;
            //         }
            //     }
            // };
        },
        imHereAction() {
            if (this.stayOnlineTimer)
                this.stayOnlineTimer.cancel();
            this.stayOnlineTimer = null;
            if (this.stayOnlineCountdownTimer)
                this.stayOnlineCountdownTimer.cancel();
            this.stayOnlineCountdownTimer = null;
            this.stayOnlineCountdownTimerSeconds = 50;

            //stop annoying sound
            this.$chatSound.stop();

            this.makeAnAction();
        },
        goOfflineAction() {
            const users = usersStore()
            users.changeOnlineStatus('offline', { root: true });
            if (this.stayOnlineTimer)
                this.stayOnlineTimer.cancel();
            this.stayOnlineTimer = null;
            if (this.stayOnlineCountdownTimer)
                this.stayOnlineCountdownTimer.cancel();
            this.stayOnlineCountdownTimer = null;
            this.stayOnlineCountdownTimerSeconds = 50;
            // this.changeOnlineStatus('offline');
            //stop annoying sound
            this.$chatSound.stop();
        },
    }
});
