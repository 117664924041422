// the environment that will be considered when building the skin, either `production` or `development`
const viteConfig = (typeof process !== 'undefined' ? process.env.VITE_CONFIG : import.meta.env.VITE_CONFIG);

const environments = {
  localSeeer: {
    // hasBasicAuth: true,
    theme: {
      name: 'seeer',
      title: 'Seeer',
      domain: (typeof process !== 'undefined' ? process.env.HOSTNAME : import.meta.env.VITE_HOSTNAME),
      supportEmail: 'support@seeer.com',
      social: {
        facebook: 'https://www.facebook.com/seeercom/',
        instagram: 'https://www.instagram.com/seeercom/',
      },
      sounds: {
        chat: '/static/sounds/seeer/new_chat.mp3',
        message: '/static/sounds/seeer/new_message.mp3',
        notification: '/static/sounds/seeer/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Best Online Psychics',
          subheader: 'Consult the most accurate psychics, fortune-tellers and spiritual advisors online',
          content: 'Happiness, love, career, crossing over - get the answers to your questions',
          cta: 'Get started for FREE',
          bottom_text: "Seeer is an online platform that offers psychic chat and live psychic readings. With a wide range of spiritual consultants to choose from, including <a href='/advisors/psychic-readings'>Fortune Tellers</a>, <a href='/advisors/love-and-relationships'>Love and Relationship Psychics</a>, <a href='/advisors/astrologers-reader'>Astrologers</a>, <a href='/advisors/tarot-readers'>Tarot Card Readers</a>, and more, Seeer provides a variety of services to meet your needs. Registration is easy, and you can select your reader from among hundreds of gifted psychic advisors. At Seeer, we are committed to maintaining a fair and safe environment for our users, and we have a zero-tolerance policy for those who breach our terms of service.",
        },
        about: {
          meta: {
            title: 'About us - Seeer',
            description: 'Experience personalized readings on Seeer. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - Seeer',
            description: 'Choose your advisor > Click \'Chat\' > Enjoy your reading.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'You Deserve to Know',
          subheader: 'Reveal tomorrow, today. Connect with our top-rated psychics and get FREE minutes',
          cta: 'GET STARTED',
          astrix: 'Limited Time Offer - 50% OFF all psychic readings',
          // banner_feedback: {
          // 	avatar: 'landing-feedback-avatar.png',
          // 	name: 'Maria, 35,',
          // 	profession: 'a surgeon from Oregon',
          // 	title: 'She changed my life!',
          // 	text: "I was lost, confused, scared and with no direction in love. After first session with Anastasia I felt a sense of calm, peace, serenity and direction in my life.\n\nI can’t recommend her and this website enough!",
          // },

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          // feedbacks: [
          // 	{
          // 		avatar: 'landing-feedback-avatar.png',
          // 		name: 'Maria, 35,',
          // 		profession: 'a surgeon from Oregon',
          // 		title: 'Anastasia changed my life!',
          // 		text: "I was lost, confused, scared and with no direction in my love life. It got to point where it was affecting every area of my life. I was destroying everything and suffering every day!\n\nAfter first session with Anastasia I felt a sense of calm, peace, serenity and direction in my life. I can’t recommend her and this website enough!",
          // 	},
          // 	{
          // 		avatar: 'landing-feedback-avatar.png',
          // 		name: 'Maria, 35,2',
          // 		profession: 'a surgeon from Oregon',
          // 		title: 'Anastasia changed my life!',
          // 		text: "I was lost, confused, scared and with no direction in my love life. It got to point where it was affecting every area of my life. I was destroying everything and suffering every day!\n\nAfter first session with Anastasia I felt a sense of calm, peace, serenity and direction in my life. I can’t recommend her and this website enough!",
          // 	},
          // 	{
          // 		avatar: 'landing-feedback-avatar.png',
          // 		name: 'Maria, 35,3',
          // 		profession: 'a surgeon from Oregon',
          // 		title: 'Anastasia changed my life!',
          // 		text: "I was lost, confused, scared and with no direction in my love life. It got to point where it was affecting every area of my life. I was destroying everything and suffering every day!\n\nAfter first session with Anastasia I felt a sense of calm, peace, serenity and direction in my life. I can’t recommend her and this website enough!",
          // 	},
          // ],

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'Seeer can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining Seeer.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of Seeer first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at Seeer',
        description: "Discover happiness and fulfillment with Seeer's top online psychics. Gain insights and life guidance from the most accurate psychic advisors at Seeer.com!",
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/seeer/favicon.png',
        logo: '/static/images/theme_icons/seeer/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: (typeof process !== 'undefined' ? process.env.VITE_APP_URL : import.meta.env.VITE_APP_URL),
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '707860723570-6lrnldjhvjmgpuhku55v4k709rpq9l0p.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '652165913136967',
      pixel: '5665071366935053',
    },
    apple: {
      clientId: 'test.seeer.signin',
    },

    bugsnag: {
      apiKey: '213c3ceab454739d671993973648f667',
      stage: 'development',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: false,
    },
    paynl: {
      enabled: true,
    },

    vite: {
      hmr: {
        port: (typeof process !== 'undefined' ? process.env.VITE_HMR_PORT : import.meta.env.VITE_HMR_PORT),
        clientPort: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_PORT : import.meta.env.VITE_HMR_CLIENT_PORT),
        protocol: (typeof process !== 'undefined' ? process.env.VITE_HMR_PROTOCOL : import.meta.env.VITE_HMR_PROTOCOL),
        clientHost: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_HOST : import.meta.env.VITE_HMR_CLIENT_HOST),
      },
    },
  },

  stagingSeeer: {
    hasBasicAuth: true,
    theme: {
      name: 'seeer',
      title: 'Seeer',
      domain: 'stg.seeer.com',
      supportEmail: 'support@seeer.com',
      social: {
        facebook: 'https://www.facebook.com/seeercom/',
        instagram: 'https://www.instagram.com/seeercom/',
      },
      sounds: {
        chat: '/static/sounds/seeer/new_chat.mp3',
        message: '/static/sounds/seeer/new_message.mp3',
        notification: '/static/sounds/seeer/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Best Online Psychics',
          subheader: 'Consult the most accurate psychics, fortune-tellers and spiritual advisors online',
          content: 'Happiness, love, career, crossing over - get the answers to your questions',
          cta: 'Get started for FREE',
          bottom_text: "Seeer is an online platform that offers psychic chat and live psychic readings. With a wide range of spiritual consultants to choose from, including <a href='/advisors/psychic-readings'>Fortune Tellers</a>, <a href='/advisors/love-and-relationships'>Love and Relationship Psychics</a>, <a href='/advisors/astrologers-reader'>Astrologers</a>, <a href='/advisors/tarot-readers'>Tarot Card Readers</a>, and more, Seeer provides a variety of services to meet your needs. Registration is easy, and you can select your reader from among hundreds of gifted psychic advisors. At Seeer, we are committed to maintaining a fair and safe environment for our users, and we have a zero-tolerance policy for those who breach our terms of service.",
        },
        about: {
          meta: {
            title: 'About us - Seeer',
            description: 'Find out how Seeer.com became an online psychic reading platform created to gather the top accurate psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - Seeer',
            description: 'Experience personalized readings on Seeer. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'You Deserve to Know',
          subheader: 'Reveal tomorrow, today. Connect with our top-rated psychics and get FREE minutes',
          cta: 'GET STARTED',
          astrix: 'Limited Time Offer - 50% OFF all psychic readings',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'Seeer can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining Seeer.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of Seeer first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at Seeer',
        description: "Discover happiness and fulfillment with Seeer's top online psychics. Gain insights and life guidance from the most accurate psychic advisors at Seeer.com!",
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/seeer/favicon.png',
        logo: '/static/images/theme_icons/seeer/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.stg.seeer.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '910973222207-eq7qj8njdk44smva8j66bfuodc6k2o6q.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '652165913136967',
      pixel: '5665071366935053',
    },
    apple: {
      clientId: 'com.seeer.signin',
    },

    bugsnag: {
      apiKey: '213c3ceab454739d671993973648f667',
      stage: 'stagingSeeer',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: false,
    },
    paynl: {
      enabled: true,
    },
  },

  productionSeeer: {
    theme: {
      name: 'seeer',
      title: 'Seeer',
      domain: 'seeer.com',
      supportEmail: 'support@seeer.com',
      social: {
        facebook: 'https://www.facebook.com/seeercom/',
        instagram: 'https://www.instagram.com/seeercom/',
      },
      sounds: {
        chat: '/static/sounds/seeer/new_chat.mp3',
        message: '/static/sounds/seeer/new_message.mp3',
        notification: '/static/sounds/seeer/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Best Online Psychics',
          subheader: 'Consult the most accurate psychics, fortune-tellers and spiritual advisors online',
          content: 'Happiness, love, career, crossing over - get the answers to your questions',
          cta: 'Get started for FREE',
          bottom_text: "Seeer is an online platform that offers psychic chat and live psychic readings. With a wide range of spiritual consultants to choose from, including <a href='/advisors/psychic-readings'>Fortune Tellers</a>, <a href='/advisors/love-and-relationships'>Love and Relationship Psychics</a>, <a href='/advisors/astrologers-reader'>Astrologers</a>, <a href='/advisors/tarot-readers'>Tarot Card Readers</a>, and more, Seeer provides a variety of services to meet your needs. Registration is easy, and you can select your reader from among hundreds of gifted psychic advisors. At Seeer, we are committed to maintaining a fair and safe environment for our users, and we have a zero-tolerance policy for those who breach our terms of service.",
        },
        about: {
          meta: {
            title: 'About us - Seeer',
            description: 'Find out how Seeer.com became an online psychic reading platform created to gather the top accurate psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - Seeer',
            description: 'Experience personalized readings on Seeer. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'You Deserve to Know',

          subheader: 'Reveal tomorrow, today. Connect with our top-rated psychics and get FREE minutes',
          cta: 'GET STARTED',
          astrix: 'Limited Time Offer - 50% OFF all psychic readings',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'Seeer can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining Seeer.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of Seeer first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Seeer?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at Seeer',
        description: "Discover happiness and fulfillment with Seeer's top online psychics. Gain insights and life guidance from the most accurate psychic advisors at Seeer.com!",
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/seeer/favicon.png',
        logo: '/static/images/theme_icons/seeer/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.seeer.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '910973222207-eq7qj8njdk44smva8j66bfuodc6k2o6q.apps.googleusercontent.com',
      gtm: 'GTM-MFWCTNQ',
    },
    facebook: {
      appId: '652165913136967',
      pixel: '285775262496049',
    },
    apple: {
      clientId: 'com.seeer.signin',
    },

    bugsnag: {
      apiKey: '213c3ceab454739d671993973648f667',
      stage: 'productionSeeer',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: false,
    },
    paynl: {
      enabled: true,
    },
  },

  localMystic: {
    // hasBasicAuth: true,
    theme: {
      name: 'mystic',
      title: 'MysticWork',
      domain: (typeof process !== 'undefined' ? process.env.HOSTNAME : import.meta.env.VITE_HOSTNAME),
      supportEmail: 'support@mysticwork.com',
      social: {
        facebook: 'https://www.facebook.com/mysticworkcom/',
        instagram: 'https://www.instagram.com/mysticworkcom',
      },
      sounds: {
        chat: '/static/sounds/mystic/new_chat.mp3',
        message: '/static/sounds/mystic/new_message.mp3',
        notification: '/static/sounds/mystic/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Find Your Clarity',
          subheader: 'Chat with one of our top psychics.',
          content: '',
          cta: 'Start with 3 Minutes FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - MysticWork',
            description: 'mysticwork.com is created to gather the finest group of psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - MysticWork',
            description: 'Experience personalized readings on MysticWork. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Advisor',
          astrix: 'Limited Time Offer - 50% OFF all psychic readings',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'MysticWork can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining MysticWork.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of MysticWork first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at MysticWork.Com',
        description: 'Join psychic chat with one of the best online psychic readers and get psychic readings at MysticWork.com',
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/mystic/favicon.png',
        logo: '/static/images/theme_icons/mystic/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: (typeof process !== 'undefined' ? process.env.VITE_APP_URL : import.meta.env.VITE_APP_URL),
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '707860723570-6lrnldjhvjmgpuhku55v4k709rpq9l0p.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '3506825576212055',
      pixel: '5665071366935053',
    },
    apple: {
      clientId: 'test.seeer.signin',
    },

    bugsnag: {
      apiKey: '1d2957c7c1ba2ca209ff9f06f16cd161',
      stage: 'local',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: true,
    },
    paynl: {
      enabled: false,
    },

    vite: {
      hmr: {
        port: (typeof process !== 'undefined' ? process.env.VITE_HMR_PORT : import.meta.env.VITE_HMR_PORT),
        clientPort: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_PORT : import.meta.env.VITE_HMR_CLIENT_PORT),
        protocol: (typeof process !== 'undefined' ? process.env.VITE_HMR_PROTOCOL : import.meta.env.VITE_HMR_PROTOCOL),
        clientHost: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_HOST : import.meta.env.VITE_HMR_CLIENT_HOST),
      },
    },
  },

  stagingMystic: {
    hasBasicAuth: true,
    theme: {
      name: 'mystic',
      title: 'MysticWork',
      domain: 'stg.MysticWork.com',
      supportEmail: 'support@mysticwork.com',
      social: {
        facebook: 'https://www.facebook.com/mysticworkcom/',
        instagram: 'https://www.instagram.com/mysticworkcom',
      },
      sounds: {
        chat: '/static/sounds/mystic/new_chat.mp3',
        message: '/static/sounds/mystic/new_message.mp3',
        notification: '/static/sounds/mystic/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Find Your Clarity',
          subheader: 'Chat with one of our top psychics.',
          content: '',
          cta: 'Start with 3 Minutes FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - MysticWork',
            description: 'mysticwork.com is created to gather the finest group of psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - MysticWork',
            description: 'Experience personalized readings on MysticWork. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Advisor',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'MysticWork can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining MysticWork.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of MysticWork first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at MysticWork.Com',
        description: 'Join psychic chat with one of the best online psychic readers and get psychic readings at MysticWork.com',
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/mystic/favicon.png',
        logo: '/static/images/theme_icons/mystic/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.stg.mysticwork.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '610124606831-rpk2ikg9dfn16gob4u31qfdtnmpou89i.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '3506825576212055',
      pixel: '5665071366935053',
    },
    apple: {
      clientId: 'com.mysticwork',
    },

    bugsnag: {
      apiKey: '1d2957c7c1ba2ca209ff9f06f16cd161',
      stage: 'stagingMystic',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: true,
    },
    paynl: {
      enabled: false,
    },
  },

  productionMystic: {
    theme: {
      name: 'mystic',
      title: 'MysticWork',
      domain: 'MysticWork.com',
      supportEmail: 'support@mysticwork.com',
      social: {
        facebook: 'https://www.facebook.com/mysticworkcom/',
        instagram: 'https://www.instagram.com/mysticworkcom',
      },
      sounds: {
        chat: '/static/sounds/mystic/new_chat.mp3',
        message: '/static/sounds/mystic/new_message.mp3',
        notification: '/static/sounds/mystic/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Find Your Clarity',
          subheader: 'Chat with one of our top psychics.',
          content: '',
          cta: 'Start with 3 Minutes FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - MysticWork',
            description: 'mysticwork.com is created to gather the finest group of psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - MysticWork',
            description: 'Experience personalized readings on MysticWork. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Advisor',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'MysticWork can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining MysticWork.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of MysticWork first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is MysticWork?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at MysticWork.Com',
        description: 'Join psychic chat with one of the best online psychic readers and get psychic readings at MysticWork.com',
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/mystic/favicon.png',
        logo: '/static/images/theme_icons/mystic/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.mysticwork.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '610124606831-rpk2ikg9dfn16gob4u31qfdtnmpou89i.apps.googleusercontent.com',
      gtm: 'GTM-NL5VT6H',
    },
    facebook: {
      appId: '3506825576212055',
      pixel: '298844727900586',
    },
    apple: {
      clientId: 'com.mysticwork',
    },

    bugsnag: {
      apiKey: '1d2957c7c1ba2ca209ff9f06f16cd161',
      stage: 'productionMystic',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: true,
    },
    paynl: {
      enabled: false,
    },
  },

  localPsychic: {
    // hasBasicAuth: true,
    theme: {
      name: 'psychic',
      title: 'PsychicVOP',
      domain: (typeof process !== 'undefined' ? process.env.HOSTNAME : import.meta.env.VITE_HOSTNAME),
      supportEmail: 'support@psyvop.com',
      social: {
        facebook: 'https://www.facebook.com/PsychicVOP/',
        instagram: 'https://www.instagram.com/psychicvop',
      },
      sounds: {
        chat: '/static/sounds/psychic/new_chat.mp3',
        message: '/static/sounds/psychic/new_message.mp3',
        notification: '/static/sounds/psychic/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Seeking insight?',
          subheader: 'Find clarity from one of our top psychics.',
          content: '',
          cta: 'Get started for FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - PsychicVOP',
            description: 'psyvop.com is created to gather the finest group of psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - PsychicVOP',
            description: 'Experience personalized readings on PsychicVOP. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Advisor',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'PsychicVOP can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining PsychicVOP.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of PsychicVOP first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at Psychic VOP',
        description: 'Join psychic chat with one of the best online psychic readers and get psychic readings at PsychicVOP.com',
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/psychic/favicon.png',
        logo: '/static/images/theme_icons/psychic/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: (typeof process !== 'undefined' ? process.env.VITE_APP_URL : import.meta.env.VITE_APP_URL),
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '707860723570-6lrnldjhvjmgpuhku55v4k709rpq9l0p.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '656971095958390',
      pixel: '5665071366935053',
    },
    apple: {
      clientId: 'test.seeer.signin',
    },

    bugsnag: {
      apiKey: '3cf205db7f3ab094c06e26cab9680686',
      stage: 'local',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: true,
    },
    paynl: {
      enabled: false,
    },

    vite: {
      hmr: {
        port: (typeof process !== 'undefined' ? process.env.VITE_HMR_PORT : import.meta.env.VITE_HMR_PORT),
        clientPort: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_PORT : import.meta.env.VITE_HMR_CLIENT_PORT),
        protocol: (typeof process !== 'undefined' ? process.env.VITE_HMR_PROTOCOL : import.meta.env.VITE_HMR_PROTOCOL),
        clientHost: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_HOST : import.meta.env.VITE_HMR_CLIENT_HOST),
      },
    },
  },

  stagingPsychic: {
    hasBasicAuth: true,
    theme: {
      name: 'psychic',
      title: 'PsychicVOP',
      domain: 'stg.PsychicVOP.com',
      supportEmail: 'support@psyvop.com',
      social: {
        facebook: 'https://www.facebook.com/PsychicVOP/',
        instagram: 'https://www.instagram.com/psychicvop',
      },
      sounds: {
        chat: '/static/sounds/psychic/new_chat.mp3',
        message: '/static/sounds/psychic/new_message.mp3',
        notification: '/static/sounds/psychic/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Seeking insight?',
          subheader: 'Find clarity from one of our top psychics.',
          content: '',
          cta: 'Get started for FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - PsychicVOP',
            description: 'psyvop.com is created to gather the finest group of psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - PsychicVOP',
            description: 'Experience personalized readings on PsychicVOP. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Advisor',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'PsychicVOP can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining PsychicVOP.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of PsychicVOP first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at Psychic VOP',
        description: 'Join psychic chat with one of the best online psychic readers and get psychic readings at PsychicVOP.com',
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/psychic/favicon.png',
        logo: '/static/images/theme_icons/psychic/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.stg.psychicvop.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '742572958489-t8m8mn1ia38o6iohd1320lvqv5oq4agc.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '656971095958390',
      pixel: '5665071366935053',
    },
    apple: {
      clientId: 'com.psychicvop.signin',
    },

    bugsnag: {
      apiKey: '3cf205db7f3ab094c06e26cab9680686',
      stage: 'stagingPsychic',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: true,
    },
    paynl: {
      enabled: false,
    },
  },

  productionPsychic: {
    theme: {
      name: 'psychic',
      title: 'PsychicVOP',
      domain: 'PsychicVOP.com',
      supportEmail: 'support@psyvop.com',
      social: {
        facebook: 'https://www.facebook.com/PsychicVOP/',
        instagram: 'https://www.instagram.com/psychicvop',
      },
      sounds: {
        chat: '/static/sounds/psychic/new_chat.mp3',
        message: '/static/sounds/psychic/new_message.mp3',
        notification: '/static/sounds/psychic/new_notification.mp3',
      },
      captions: {
        Advisor: 'Advisor',
        Advisors: 'Advisors',
        advisor: 'advisor',
        advisors: 'advisors',
      },
      text: {
        homepage: {
          header: 'Seeking insight?',
          subheader: 'Find clarity from one of our top psychics.',
          content: '',
          cta: 'Get started for FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - PsychicVOP',
            description: 'psyvop.com is created to gather the finest group of psychic advisors into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - PsychicVOP',
            description: 'Experience personalized readings on PsychicVOP. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
          },
        },
        best_psyhic_reader: {
          header: 'Know Who Your True Love Is',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} advisors',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Advisor',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an advisor and',
          advisor_heading: 'See the future of your Love Relationship Now',
          more_advisors_cta: 'View more Advisors',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Advisors',
          },

          can_help_with: {
            title: 'PsychicVOP can help you with:',
            items: [
              'See if he still loves you',
              'Create a great love life',
              'Career previsions',
              'Untangle your family life',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our advisors sign NDAs upon joining PsychicVOP.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another advisor at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of PsychicVOP first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the advisor',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Psychics',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is PsychicVOP?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Psychic Chat, Live Psychic Readings at Psychic VOP',
        description: 'Join psychic chat with one of the best online psychic readers and get psychic readings at PsychicVOP.com',
        keywords: 'free psychic, online psychic, free psychic advisors, free chat psychic',
        image: '/static/images/theme_icons/psychic/favicon.png',
        logo: '/static/images/theme_icons/psychic/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.psychicvop.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '742572958489-t8m8mn1ia38o6iohd1320lvqv5oq4agc.apps.googleusercontent.com',
      gtm: 'GTM-TC79NVD',
    },
    facebook: {
      appId: '656971095958390',
      pixel: '465578620454460',
    },
    apple: {
      clientId: 'com.psychicvop.signin',
    },

    bugsnag: {
      apiKey: '3cf205db7f3ab094c06e26cab9680686',
      stage: 'productionPsychic',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: true,
    },
    paynl: {
      enabled: false,
    },
  },

  localTimesy: {
    // hasBasicAuth: true,
    theme: {
      name: 'timesy',
      title: 'Timesy',
      domain: (typeof process !== 'undefined' ? process.env.HOSTNAME : import.meta.env.VITE_HOSTNAME),
      supportEmail: 'support@timesy.com',
      social: {
        facebook: 'https://www.facebook.com/timesycom',
        instagram: 'https://www.instagram.com/timesycom/',
      },
      sounds: {
        chat: '/static/sounds/timesy/new_chat.mp3',
        message: '/static/sounds/timesy/new_message.mp3',
        notification: '/static/sounds/timesy/new_notification.mp3',
      },
      captions: {
        Advisor: 'Business Coach',
        Advisors: 'Business Coaches',
        advisor: 'business coach',
        advisors: 'business coaches',
      },
      text: {
        homepage: {
          header: 'Seeking insight?',
          subheader: 'Find clarity from one of our top business coaches.',
          content: '',
          cta: 'Get started for FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - Timesy',
            description: 'timesy.com is created to gather the finest group of business coaches into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - Timesy',
            description: 'Choose your coach > Click \'Chat\' > Enjoy your consultation.',
          },
        },
        best_psyhic_reader: {
          header: 'Know How To Gain Success',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Business Coaches',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total consultations provided',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first consultation",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Business Coaches',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Coaches',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Coaches',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} business coaches',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Coach',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an business coach and',
          advisor_heading: 'See the future of your Business Now',
          more_advisors_cta: 'View more Business Coaches',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Business Coaches',
          },

          can_help_with: {
            title: 'Timesy can help you with:',
            items: [
              'See if businees is for you',
              'Create a great public life',
              'Career previsions',
              'Untangle your business',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our business coaches sign NDAs upon joining Timesy.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another business coach at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of Timesy first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the business coach',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Coaches',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Business Coaches Chat, Live Business Consultations at Timesy',
        description: 'Join business chat with one of the best online business consultations and get business consultations at Timesy.com',
        keywords: 'free business coaches, online business coaches, free business coaches, free chat business coaches',
        image: '/static/images/theme_icons/timesy/favicon.png',
        logo: '/static/images/theme_icons/timesy/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: (typeof process !== 'undefined' ? process.env.VITE_APP_URL : import.meta.env.VITE_APP_URL),
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '707860723570-6lrnldjhvjmgpuhku55v4k709rpq9l0p.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '969275304467318',
      pixel: '', // TODO: get new
    },
    apple: {
      clientId: 'test.seeer.signin',
    },

    bugsnag: {
      apiKey: '7536ad3d2649303a19f202d80eb5b644',
      stage: 'local',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: false,
    },
    paynl: {
      enabled: false,
    },

    vite: {
      hmr: {
        port: (typeof process !== 'undefined' ? process.env.VITE_HMR_PORT : import.meta.env.VITE_HMR_PORT),
        clientPort: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_PORT : import.meta.env.VITE_HMR_CLIENT_PORT),
        protocol: (typeof process !== 'undefined' ? process.env.VITE_HMR_PROTOCOL : import.meta.env.VITE_HMR_PROTOCOL),
        clientHost: (typeof process !== 'undefined' ? process.env.VITE_HMR_CLIENT_HOST : import.meta.env.VITE_HMR_CLIENT_HOST),
      },
    },
  },

  stagingTimesy: {
    hasBasicAuth: true,
    theme: {
      name: 'timesy',
      title: 'Timesy',
      domain: 'stg.timesy.com',
      supportEmail: 'support@timesy.com',
      social: {
        facebook: 'https://www.facebook.com/timesycom',
        instagram: 'https://www.instagram.com/timesycom/',
      },
      sounds: {
        chat: '/static/sounds/timesy/new_chat.mp3',
        message: '/static/sounds/timesy/new_message.mp3',
        notification: '/static/sounds/timesy/new_notification.mp3',
      },
      captions: {
        Advisor: 'Business Coach',
        Advisors: 'Business Coaches',
        advisor: 'business coach',
        advisors: 'business coaches',
      },
      text: {
        homepage: {
          header: 'Seeking insight?',
          subheader: 'Find clarity from one of our top business coaches.',
          content: '',
          cta: 'Get started for FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - Timesy',
            description: 'timesy.com is created to gather the finest group of business coaches into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - Timesy',
            description: 'Choose your coach > Click \'Chat\' > Enjoy your consultation.',
          },
        },
        best_psyhic_reader: {
          header: 'Know How To Gain Success',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Business Coaches',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total consultations provided',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first consultation",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Business Coaches',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} business coaches',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Coach',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an business coach and',
          advisor_heading: 'See the future of your Business Now',
          more_advisors_cta: 'View more Business Coaches',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Business Coaches',
          },

          can_help_with: {
            title: 'Timesy can help you with:',
            items: [
              'See if businees is for you',
              'Create a great public life',
              'Career previsions',
              'Untangle your business',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our business coaches sign NDAs upon joining Timesy.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another business coach at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of Timesy first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the business coach',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Coaches',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Business Chat, Live Business Consultations at Timesy',
        description: 'Join business chat with one of the best online business coaches and get business consultations at Timesy.com',
        keywords: 'free business coaches, online business coaches, free business coaches, free chat business coaches',
        image: '/static/images/theme_icons/timesy/favicon.png',
        logo: '/static/images/theme_icons/timesy/favicon.png',
        type: 'website',
      },
    },
    api: {
      // backendUrl: "https://localhost/api",
      // backendUrl: "https://api3stg.timesy.com",
      backendUrl: 'https://api3.stg.timesy.com',
      // backendUrl: "https://172.31.18.208",
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '864503600128-3us69gpkh4l22uu0monu7o7aef2kbg86.apps.googleusercontent.com',
      gtm: 'GTM-MGL73CG',
    },
    facebook: {
      appId: '883086619326465',
      pixel: '', // TODO: get new
    },
    apple: {
      clientId: 'com.timesy.signin',
    },

    bugsnag: {
      apiKey: '7536ad3d2649303a19f202d80eb5b644',
      stage: 'stagingTimesy',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: false,
    },
    paynl: {
      enabled: false,
    },
  },

  productionTimesy: {
    theme: {
      name: 'timesy',
      title: 'Timesy',
      domain: 'timesy.com',
      supportEmail: 'support@timesy.com',
      social: {
        facebook: 'https://www.facebook.com/timesycom',
        instagram: 'https://www.instagram.com/timesycom/',
      },
      sounds: {
        chat: '/static/sounds/timesy/new_chat.mp3',
        message: '/static/sounds/timesy/new_message.mp3',
        notification: '/static/sounds/timesy/new_notification.mp3',
      },
      captions: {
        Advisor: 'Business Coach',
        Advisors: 'Business Coaches',
        advisor: 'business coach',
        advisors: 'business coaches',
      },
      text: {
        homepage: {
          header: 'Seeking insight?',
          subheader: 'Find clarity from one of our top business coaches.',
          content: '',
          cta: 'Get started for FREE',
          bottom_text: '',
        },
        about: {
          meta: {
            title: 'About us - Timesy',
            description: 'timesy.com is created to gather the finest group of business coaches into one place.',
          },
        },
        howitworks: {
          meta: {
            title: 'How it works - Timesy',
            description: 'Choose your coach > Click \'Chat\' > Enjoy your consultation.',
          },
        },
        best_psyhic_reader: {
          header: 'Know How To Gain Success',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Get My 3 Free Minutes>',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Business Coaches',
          more_advisors_cta: 'View more advisors',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total consultations provided',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first consultation",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Business Coaches',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        top_psychic_readers: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Find Happiness In Your Life Right Now',
          subheader: '3 FREE Minutes + 50% Off your first session',
          cta: 'Try It Now >',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: 'Try It Now >',
          expect_heading: 'What Should I Expect?',
        },
        get_accurate_readings: {
          top_alert: 'You get {free_minutes} free minutes with {free_chats_for_new_user} business coaches',
          header: 'Look into the future',
          subheader: 'Get an accurate* reading on what life holds for you',
          cta: 'Find the Perfect Coach',
          astrix: '*99.5% accuracy from 542,834 client reviews',

          advisor_subheading: 'Choose an business coach and',
          advisor_heading: 'See the future of your Business Now',
          more_advisors_cta: 'View more Business Coaches',

          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          stars: {
            title: '542,834+',
            text: 'Customers Reviews',
          },
          advisors: {
            title: '1500+',
            text: 'Qualified Business Coaches',
          },

          can_help_with: {
            title: 'Timesy can help you with:',
            items: [
              'See if businees is for you',
              'Create a great public life',
              'Career previsions',
              'Untangle your business',
              'Financial predictions',
            ],
          },

          benefits: [
            {
              icon: 'landing-benefits-shield.svg',
              title: 'Secure Payments',
              text: 'Through Paypal and our payment providers we offer deep layers of security for your payments.',
            },
            {
              icon: 'landing-benefits-phone-lock.svg',
              title: '100% Confidentiality',
              text: 'All of our business coaches sign NDAs upon joining Timesy.com so you get complete confidentiality.',
            },
            {
              icon: 'landing-benefits-safe.svg',
              title: 'Money Back Guarantee',
              text: "You get a full refund if you're not happy with your first reading.",
            },
          ],

          how_it_works: {
            title: 'How it works',
            disclaimer: '* You can stop the chat or switch to another business coach at any point',
            items: [
              {
                icon: 'landing-how-works-stopwatch.svg',
                text: '1. Choose number of minutes',
              },
              {
                icon: 'landing-how-works-user-plus.svg',
                text: '2. Sign Up with Social or email',
              },
              {
                icon: 'landing-how-works-credit-card.svg',
                text: '3. Choose payment method & Pay',
              },
              {
                icon: 'landing-how-works-start-chat.svg',
                text: '4. Start chatting & see the future',
              },
              {
                icon: 'landing-how-works-rocket.svg',
                text: '5. After the <strong>free {free_minutes} minutes</strong> you can continue the paid chat',
              },
            ],
          },

          first_session: {
            title: 'Get the most out of Timesy first session',
            items: [
              'Prepare - set an intention for the coming session',
              'Define the challenge that you are facing as clear as possible',
              'Do a list with all the possible information needed in the chat',
              'Stay flexible to new solutions in the chat & ask follow up questions with the business coach',
              'Set an intention and action steps at the end of the call and commit to them',
              'Come back for a new session if you feel you need extra guidance.',
            ],
          },

          special_offer: {
            title: 'Special Offer',
            subtitle: '<strong>Try {free_minutes} Minutes FREE!</strong> With All Online Coaches',
            button: 'Try Now',
          },
        },
        landing: {
          top_alert: 'You have 3 FREE minutes with every new advisor!',
          header: 'Are you dealing with a lot of thoughts?',
          subheader: '🔮 Get answers with a psychic reading',
          cta: '🌟Get Clarity Now!',
          astrix: '* credit card or paypal required',
          advisor_heading: 'Choose One of Our Top Rated Psychics',
          more_advisors_cta: 'View More',
          who_is_heading: 'Who is Timesy?',
          chat: {
            title: '1,128,366+',
            text: 'Total readings done',
          },
          check: {
            title: 'Guaranteed',
            text: "Full refund if you're not happy with your first reading",
          },
          stars: {
            title: '452,834+',
            text: 'Customers Reviews',
          },
          special_offer_heading: 'Special Offer',
          special: {
            title: 'Try 3 Minutes FREE',
            text: 'With ALL Online Psychics',
          },
          try_it_now_cta: '🌟Get Clarity Now!',
          expect_heading: 'What Should I Expect?',
        },
      },
      meta: {
        title: 'Online Business Chat, Live Business Consultations at Timesy',
        description: 'Join business chat with one of the best online business consultations and get business consultations at Timesy.com',
        keywords: 'free business coaches, online business coaches, free business coaches advisors, free chat business coaches',
        image: '/static/images/theme_icons/timesy/favicon.png',
        logo: '/static/images/theme_icons/timesy/favicon.png',
        type: 'website',
      },
    },
    api: {
      backendUrl: 'https://api3.timesy.com',
    },

    tinymce: {
      key: 'oj2mmonc9y6pjsngwyzqz7w7fzrh0bnh1xu11iqeiy8x2qj8',
    },

    google: {
      clientId: '864503600128-3us69gpkh4l22uu0monu7o7aef2kbg86.apps.googleusercontent.com',
      gtm: 'GTM-5VP28XM',
    },
    facebook: {
      appId: '883086619326465',
      pixel: '', // TODO: get new
    },
    apple: {
      clientId: 'com.timesy.signin',
    },

    bugsnag: {
      apiKey: '7536ad3d2649303a19f202d80eb5b644',
      stage: 'productionTimesy',
    },

    paypal: {
      enabled: true,
    },
    stripe: {
      enabled: false,
    },
    paynl: {
      enabled: false,
    },
  },
};

export default {
  viteConfig,
  isProduction: (typeof process !== 'undefined' ? process.argv[process.argv.indexOf('--mode') + 1] : import.meta.env.MODE) === 'production',
  // isTesting: viteConfig === "testing",
  server: {
    port: (typeof process !== 'undefined' ? process.env.VITE_PORT : import.meta.env.VITE_PORT),
    staticCachePeriodSeconds: 60 * 60 * 24,
  },

  api: {
    ...environments[viteConfig]?.api,
    timeout: 10000,
    // path: '/api3',
    path: '/api',
    // Using full URL for SSR mode to request node.js server running locally.
    // url: (typeof process !== 'undefined' ? 'http://localhost:'+process.env.VITE_PORT : '') + '/api3',
    // url: (typeof process !== 'undefined' ? 'https://' + environments[viteConfig]?.theme.domain.toLowerCase() : '') + '/api',
    url: (typeof process !== 'undefined' ? environments[viteConfig]?.api.backendUrl : '/api'),
    // Substituting secondary authorization header name because basic authentication uses Authorization header.
    authorizationHeaderName: (
      typeof process === 'undefined' && (environments[viteConfig]?.hasBasicAuth ?? false)
        ? 'Api-Authorization'
        : 'Authorization'
    ),
    vite_proxy_enabled: (typeof process !== 'undefined' ? process.env.VITE_API_PROXY_ENABLED || false : false),
    vite_proxy_auth: typeof process !== 'undefined' && process.env.VITE_API_PROXY_ENABLED ? {
      username: process.env.API_AUTH_USERNAME,
      password: process.env.API_AUTH_PASSWORD,
    } : {},
    prioritize_ipv4: (typeof process !== 'undefined' ? process.env.VITE_API_PRIORITIZE_IPV4 || false : false),
  },

  theme: environments[viteConfig]?.theme,

  tinymce: environments[viteConfig]?.tinymce,

  google: environments[viteConfig]?.google,
  facebook: environments[viteConfig]?.facebook,
  apple: environments[viteConfig]?.apple,

  bugsnag: environments[viteConfig]?.bugsnag,

  paypal: environments[viteConfig]?.paypal,
  stripe: environments[viteConfig]?.stripe,
  paynl: environments[viteConfig]?.paynl,

  vite: environments[viteConfig]?.vite,
};
