<template>
    <ul class="nav navbar-nav d-flex justify-content-end align-items-baseline" style="margin-top: -2px;">

        <li class="dropdown dropdown-notification nav-item" :class="{ show: showNotificationsDropdown }"
            @click="showNotifications" v-click-outside="hideNotifications">
            <a href="javascript:;" data-toggle="dropdown" class="nav-link nav-link-label">
                <font-awesome-icon icon="bell" />
                <span class="badge badge-pill badge-default badge-danger badge-default badge-up"
                    v-show="unreadNotifications">{{ unreadNotifications }}</span>
            </a>
            <NotificationsDropDown />
        </li>

        <li class="dropdown dropdown-notification nav-item">
            <Link class="nav-link nav-link-label" href="/dashboard/inbox">
            <font-awesome-icon icon="envelope" />
            <span class="badge badge-pill badge-default badge-warning badge-default badge-up" v-show="unreadInbox">{{
                unreadInbox }}</span>
            </Link>
        </li>

        <li class="dropdown dropdown-user nav-item" :class="{ show: isActiveUserDropdown }">
            <a href="javascript:;" data-toggle="dropdown" class="dropdown-toggle nav-link dropdown-user-link"
                @click="showUserDropDown" v-click-outside="hideUserDropDown">
                <span class="avatar" :class="'avatar-' + (currentUser.status ?? 'none')">
                    <Avatar :username="currentUser.name" :src="currentUser.avatar" :size="30" />
                    <i v-show="isAdvisor"></i>
                </span>
                <span class="user-name">{{ croppedUsername }}</span>
                <font-awesome-icon icon="angle-down" v-show="!smallScreenMode" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" v-show="!smallScreenMode">
                <Link href="/dashboard/profile/edit" class="dropdown-item">
                <font-awesome-icon icon="user" /> Edit Profile
                </Link>
                <Link href="/dashboard/inbox" class="dropdown-item">
                <font-awesome-icon icon="envelope" /> My Inbox
                </Link>
                <Link href="/dashboard/history" class="dropdown-item">
                <font-awesome-icon icon="comment" /> Chats
                </Link>
                <Link href="/dashboard/profile/plans" class="dropdown-item" v-if="isAdvisor">
                <font-awesome-icon icon="user-check" /> Subscription Plans
                </Link>
                <Link href="/dashboard/profile/settings" class="dropdown-item">
                <font-awesome-icon icon="user-shield" /> Account Settings
                </Link>
                <div class="dropdown-divider"></div>
                <Link href="/dashboard/unsubscribe" class="dropdown-item" v-if="isUser || isAdvisor">
                <font-awesome-icon icon="times" /> Unsubscribe
                </Link>
                <Link href="javascript:;" class="dropdown-item" @click="$auth.logout()">
                <font-awesome-icon icon="power-off" /> Logout
                </Link>
            </div>
        </li>

    </ul>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'
import vClickOutside from 'click-outside-vue3'

import NotificationsDropDown from '@/src/components/NotificationsDropDown.vue'
import Avatar from '@/src/components/Avatar.vue'
import Link from '@/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell, faEnvelope, faAngleDown, faUser, faUserCheck, faUserShield, faComment, faPowerOff, faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faBell, faEnvelope, faAngleDown, faUser, faUserCheck, faUserShield, faComment, faPowerOff, faTimes)

export default {
    name: "client-area-menu",
    data() {
        return {
            isActiveUserDropdown: false,
            showNotificationsDropdown: false,
        }
    },
    components: {
        NotificationsDropDown,
        Avatar,
        Link,
        FontAwesomeIcon,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    methods: {
        ...mapActions(usersStore, [
            'getUnreadInbox',
        ]),
        showUserDropDown() {
            this.isActiveUserDropdown = !this.isActiveUserDropdown;
        },
        showNotifications() {
            this.showNotificationsDropdown = !this.showNotificationsDropdown;
        },
        hideUserDropDown() {
            this.isActiveUserDropdown = false;
        },
        hideNotifications() {
            this.showNotificationsDropdown = false;
        },
    },
    computed: {
        ...mapState(usersStore, [
            'currentUser',
            'unreadNotifications',
            'isAdvisor',
            'isUser',
        ]),
        ...mapState(rootStore, [
            'smallScreenMode',
        ]),
        unreadInbox() {
            return this.getUnreadInbox()
        },
        croppedUsername(){
            if (this.currentUser?.name) {
                let nameParts = this.currentUser?.name?.split(/(\s+)/)
                if (Array.isArray(nameParts)) {
                    return nameParts[0]
                }
            }
            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
.user-name {
    margin-right: 0.25em;
}

.dropdown-menu {
    overflow-y: visible !important;
}
</style>
