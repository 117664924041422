import { usePageContext } from '@/src/helpers/usePageContext'
import config from '@/config'

async function getMeta(vm) {
	const { meta } = vm.$options
	if (meta) {
		return typeof meta === "function"
			? await meta({ config, pageContext: usePageContext(), self: vm })
			: meta
	} else {
		return null
	}
}

const serverMetaInfoMixin = {
	// No need in this as it's updated in _default.page.server.js
	// async onBeforeRender(pageContext) {
	// 	const meta = getMeta(this)
	// 	if (meta) {
	// 		if (!pageContext.documentProps) {
	// 			pageContext.documentProps = {}
	// 		}
	// 		pageContext.documentProps.title = meta.title
	// 		pageContext.documentProps.description = meta.description
	// 	}
	//
	// 	return pageContext
	// }
}

const clientMetaInfoMixin = {
	async updated() {
		const meta = await getMeta(this)
		if (meta) {
			document.title = meta.title
			document.querySelector("meta[name=description]")
				.setAttribute("content", meta.description)
		}
	}
}

export default (import.meta.env.SSR ? serverMetaInfoMixin : clientMetaInfoMixin)
