<template>
	<BootstrapModal :is-active="isVisible" @close="closeModal" @submit="saveFeedbackInternal" :showHeader="false"
		@cancel="closeModal" :submit-disabled="false" :submit-loading="savingFeedback" cancel-text="Later"
		submit-text="Save" v-if="isUser">
		<h3 class="text-center">Leave feedback</h3>
		<div class="field text-center padded">
			<label class="label">Rate this chat</label>
			<p class="control">
				<RatingComponent :value="rate" :can-change="true" @rating-change="setNewRating" />
			</p>
		</div>
		<div class="field text-center">
			<label class="label">Do you have any additional feedback?</label>
			<p class="control">
				<textarea class="form-control" placeholder="Type a feedback comment here..." v-model="message"></textarea>
			</p>
		</div>
	</BootstrapModal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { chatStore } from '@/src/store/chat'
import { usersStore } from '@/src/store/users'

import RatingComponent from '@/src/components/RatingComponent.vue'
import BootstrapModal from '@/src/components/BootstrapModal.vue'

export default {
	data() {
		return {
			message: '',
			rate: 5,
		}
	},
	components: {
		RatingComponent,
		BootstrapModal,
	},
	watch: {
		rate: {
			immediate: true,
			handler() {
				this.setFeedbackRating(this.rate);
			}
		},
		message: {
			immediate: true,
			handler() {
				this.setFeedbackMessage(this.message);
			}
		}
	},
	methods: {
		...mapActions(chatStore, [
			'resetFeedbackForm',
			'setFeedbackRating',
			'setFeedbackMessage',
			'saveFeedBack',
		]),
		closeModal() {
			this.message = ''
			this.rate = null
			if (this.$route.path !== this.redirectUrl) {
				this.$router.push(this.redirectUrl)
			}
			this.resetFeedbackForm()
		},
		saveFeedbackInternal() {
			this.saveFeedBack(this.redirectUrl)
		},
		setNewRating(rate) {
			this.rate = rate;
		},
	},
	computed: {
		...mapState(chatStore, [
			'feedbackForm',
			'savingFeedback',
		]),
		...mapState(usersStore, [
			'currentUser',
			'isUser',
		]),
		isVisible() {
			return this.feedbackForm.chat_id !== null && this.isUser;
		},
		redirectUrl() {
			if (this.feedbackForm.chat_id) {
				return { path: '/dashboard/history/:id', params: { id: this.feedbackForm.chat_id } }
			} else {
				return '/home'
			}
		},
	}
}
</script>

<style scoped>
.padded {
	padding: 20px 0;
}
</style>
