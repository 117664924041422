<template>
	<div class="drop-wrapper" v-if="isAdvisor" v-click-outside="hideDropDown">
		<span class="d-none d-sm-inline">&nbsp; Your status: &nbsp;</span>
		<div class="btn-group dropup text-center" :class="{ 'show': isActiveStatusDropDown }">
			<button type="button" class="btn btn-sm ml-1" :disabled="currentUser.status === 'busy'"
				:class="{ 'btn-success': currentUser.status === 'online', 'btn-danger': currentUser.status === 'offline', 'btn-warning': currentUser.status === 'busy', 'btn-sm': sm }"
				@click="showDropDown">
				{{ currentUser.status }}
				<font-awesome-icon icon="angle-down" />
			</button>
			<div class="chat-types">
				<Link href="/dashboard/profile/edit">Chat modes:</Link>
				<span v-show="currentUser.video_enabled"><font-awesome-icon icon="video" /></span>
				<span v-show="currentUser.audio_enabled"><font-awesome-icon icon="phone" /></span>
				<span v-show="currentUser.text_enabled"><font-awesome-icon icon="comments" /></span>
				<span
					v-show="!currentUser.video_enabled && !currentUser.audio_enabled && !currentUser.text_enabled">none</span>
			</div>
			<div class="dropdown-menu arrow">
				<button class="dropdown-item is-online" @click="changeOnlineStatus('online'); hideDropDown()"
					:disabled="currentUser.status !== 'offline'">
					Online
				</button>
				<button class="dropdown-item is-offline" @click=" changeOnlineStatus('offline'); hideDropDown() "
					:disabled=" currentUser.status !== 'online' ">
					Offline
				</button>
			</div>
		</div>
		<div class="mr-1 test-sound-group">
			<button class="btn btn-sm btn-primary" @click=" testBell " v-if=" !playing ">
				<font-awesome-icon icon="play" />
				Test Sounds
			</button>
			<button class="btn btn-sm btn-danger" @click=" stopSound " v-else>
				<font-awesome-icon icon="stop" />
				Stop
			</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'

import Link from '@/src/components/Link.vue'
import vClickOutside from 'click-outside-vue3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay, faStop, faAngleDown, faVideo, faPhone, faComments } from '@fortawesome/free-solid-svg-icons'
library.add(faPlay, faStop, faAngleDown, faVideo, faPhone, faComments)

export default {
	name: "status-drop-down",
	props: {
		sm: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			isActiveStatusDropDown: false,
			playing: false,
		}
	},
	components: {
		FontAwesomeIcon,
		Link,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	methods: {
		...mapActions(usersStore, [
			'changeOnlineStatus',
		]),
		testBell() {
			this.$chatSound.play();
			this.playing = true;
		},
		stopSound() {
			this.playing = false;
			this.$chatSound.stop();
		},
		showDropDown() {
			this.isActiveStatusDropDown = !this.isActiveStatusDropDown;
		},
		hideDropDown() {
			this.isActiveStatusDropDown = false;
		}
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
			'isAdvisor',
		]),
	}
}
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

button[disabled] {
	pointer-events: none;
}

.dropdown-item.is-online {
	color: #16d39a;
}

.dropdown-item.is-offline {
	color: #ff7588;
	margin-left: 0;
}

.drop-wrapper>div {
	display: block;
}

.test-sound-group {
	padding-top: 0.5rem;
}

.drop-wrapper {
	position: fixed;
	/*
	bottom: -1px;
	right: 3rem;
	display: flex;
	*/
	right: -1px;
	bottom: 73px;
	border: 1px solid #e8e8e8;
	background-color: var(--background);
	padding: 1rem;
	border-radius: .5rem .5rem 0 0;
	justify-content: center;
	align-items: center;
	z-index: 100;

	/*@media screen and (min-width: 1000px) {
		right: 5rem;
	}

	@media screen and (max-width: 500px){
		right: 0;
		left: 0;
	}*/

	.dropdown-menu {
		position: absolute;
		transform: translate3d(-50px, -95px, 0px);
		top: 0px;
		left: 0px;
		will-change: transform;

		&:before,
		&:after {
			right: 4px;
			left: auto;
		}

	}
}

.chat-types {
	display: block;
	font-size: 75%;
	margin-left: 0.25em;
	margin-right: 0.25em;

	a {
		display: block;
	}

	span {
		display: inline-block;
		margin-left: 0.25em;
		margin-right: 0.25em;
	}
}

@media screen and (max-width: $tablet) {
	.chat-application .drop-wrapper .dropdown-menu {
		transform: translate3d(0px, 25px, 0px);
	}

	.chat-application .drop-wrapper .dropdown-menu {

		&:before,
		&:after {
			left: 4px;
			right: auto;
			top: -8px !important;
			transform: rotate(180deg);
			z-index: 1;
		}
	}

	.drop-wrapper {
		top: auto;
		left: auto;
	}

	.chat-application .drop-wrapper {
		bottom: auto;
    top: 133px;
		/* top: 117px; */
		right: 0;
		left: 0;
		text-align: center;
	}

  .chat-application .drop-wrapper {
     display: none;
  }

	.chat-application .drop-wrapper>div {
		display: inline-block;
		padding-right: 1rem;
	}

	.chat-application .test-sound-group {
		padding-top: 0;
	}

	.chat-application .chat-types {
		display: inline-block;
	}

	.chat-application .chat-types a {
		display: inline-block;
	}
}

/*
@media screen and (max-width: $tablet){
	.chat-application #scroll-area, .chat-app-window {
	    bottom: 93px;
	}
}*/
@media screen and (max-width: $mobile) {
	.test-sound-group {
		padding-right: 0 !important;
	}

	.btn-group {
		padding-right: 0 !important;
	}
}</style>
