<template>
  <div
    class="c-input"
    :class="[
      `c-input--${props.size}`,
      {
        'is-disabled': props.disabled,
        'is-invalid': props.invalid,
      }]"
  >
    <div v-if="$slots.prefix" class="c-input__prefix">
      <slot name="prefix" :value="value" />
    </div>
    <input
      v-model="value"
      :placeholder="props.placeholder"
      :type="props.type"
      :disabled="props.disabled"
      v-bind="$attrs"
    />
    <div v-if="$slots.suffix || props.clearable" class="c-input__suffix">
      <slot name="suffix" :value="value" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { InputSize } from './types/InputSize';

const props = withDefaults(defineProps<{
  modelValue: string | number,
  placeholder?: string,
  disabled?: boolean,
  invalid?: boolean,
  type?: string,
  size?: InputSize,
  clearable?: boolean,
}>(), {
  placeholder: '',
  disabled: false,
  invalid: false,
  type: 'text',
  size: 'medium',
  clearable: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: string | number): any}>();

const value = computed<string | number>({
  get() {
    return props.modelValue;
  },
  set(val: string | number) {
    emit('update:modelValue', val);
  },
});
</script>

<script lang="ts">
export default {
  name: 'SeInput',
};
</script>
