<template>
	<div id="appContent" :class="wrapPage ? 'bg-neutral-10': ''" :style="`min-height: ${appHeight}px;`">
<!--		<Header v-if="showDashboardHeaderFooter" @onLogOut="emitLogout" />-->
    <se-header v-if="!hideHeader"/>

		<div class="app-content center-layout" @click="hideSideBarMenu" :class="wrapPage ? 'container mt-3 mb-4': '!mt-0 !mb-0'">
			<div class="content-wrapper">
<!--        <span>{{ wrapPage }} - {{ $route.path }}</span>-->
        <slot />
			</div>
		</div>

    <div id="footer-extra"></div>
		<se-footer v-if="showDashboardHeaderFooter && !hideFooter" />

		<ScrollTopButton />
<!--		<ActiveChatButton />-->
		<StatusDropdown :sm="false" />
		<NotifyMePopUp />
		<ConfirmationModal title="Cancel notifications?" yesText="Yes" noText="No" :isActive="cancelNotify !== null"
			yesLoadingClass="is-loading" :yesLoading="cancelingNotifications" @onClose="setCancelNotifyConfirmation(null)"
			@onApprove="cancelNotifications" @onReject="setCancelNotifyConfirmation(null)" />
	</div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import { getWindow } from '@/src/shared/helpers/getWindow';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';


const {$route} = getGlobalProperties()

const chat = chatStore()
const { setInitiatingChatLoader } = chat

if (
	$route.query.action
	&& ![
		'/paypal/success',
		'/paypal/fail',
		'/stripe/success',
		'/stripe/fail',
		'/paynl/success',
		'/paynl/fail',
		'/thankyou',
	].includes($route.path)
	&& !$route.path.startsWith('/get-started')
) {
	if (
		// Protecting from running an action which is not supposed to be run with this mechanism.
		[
			'startTextChat',
			'startAudioChat',
			'startVideoChat',
			// TODO: replace startFree... with start...
			'startFreeTextChat',
			'startFreeAudioChat',
			'startFreeVideoChat',
			'sendInboxMessage',
		].includes($route.query.action)
	) {
		console.log('Action found starting loader', $route.query.action)
		setInitiatingChatLoader(true);
	} else {
		console.error('Action not found', $route.query.action)
	}
}

const appHeight = ref(100);
onMounted(() => {
	const { windowHeight } = getWindow();
	appHeight.value = windowHeight?.value ? windowHeight?.value : 100;
});
</script>

<script lang="ts">
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'
import { inboxStore } from '@/src/store/inbox'
import { chatStore } from '@/src/store/chat'

import Header from '@/src/layouts/Header.vue'
import ScrollTopButton from '@/src/components/ScrollTopButton.vue'
import StatusDropdown from '@/src/components/StatusDropdown.vue'
import ActiveChatButton from '@/src/components/ActiveChatButton.vue'
import NotifyMePopUp from '@/src/components/NotifyMePopUp.vue'
import ConfirmationModal from '@/src/components/ConfirmationModal.vue'
import SeFooter from "@/src/shared/layout/Footer.vue";
import SeHeader from "@/src/shared/layout/Header.vue";


export default {
	name: "Layout",
	data() {
		const windowHeight = '100'
	
		return {
			windowHeight: windowHeight,
			cancelingNotifications: false,
      hideHeader: [
        '/paypal/fail',
        '/paypal/success',
        '/stripe/fail',
        '/stripe/success',
        '/paynl/success',
        '/paynl/fail',
        '/thankyou',
      ].includes(this.$route.path),
			showDashboardHeaderFooter: ![
				// '/',
				'/login',
				'/register',
				// '/get-started',
				'/advisor/register',
				'/reset-password',
				'/set-password',
				'/paypal/fail',
				'/paypal/success',
				'/stripe/fail',
				'/stripe/success',
				'/paynl/success',
				'/paynl/fail',
				'/thankyou',
			].includes(this.$route.path)
				&& !this.$route.path.includes('/get-started')
				&& !this.$route.path.includes('/have-acc'),
			hideFooter: !(
				['/'].includes(this.$route.path)
				|| (
					!this.$route.path.match(/^(admin|dashboard)/)
					&& ![
						// '/get-accurate-readings',
						'/top-psychic-readers',
						'/best-psychic-reader',
						'/landing',
						'/promo'
					].includes(this.$route.path)
				)
			) || [
				'/dashboard/chat',
				'/dashboard/inbox',
			].includes(this.$route.path),
      wrapPage: ![
        '/',
        '/about',
        '/how-it-works',
        '/advisors',
        '/search',
        '/cookie-policy',
        '/disclaimer',
        '/privacy-policy',
        '/terms',
        '/get-accurate-readings',
        '/get-accurate-readings1',
        '/get-accurate-readings2',
        '/get-accurate-readings3',
        '/top-psychic-readers',
        '/best-psychic-reader',
      ].includes(this.$route.path) && !this.$route.path.startsWith('/advisors') && !this.$route.path.startsWith('/advisor') && !this.$route.path.startsWith('/get-started')
		};
	},
	components: {
    SeHeader,
    SeFooter,
		Header,
		ActiveChatButton,
		StatusDropdown,
		ConfirmationModal,
		NotifyMePopUp,
		ScrollTopButton,
	},
	mounted() {
		// Redirecting a new user who added balance back to advisor from who he registered.
		document.body.classList.add(this.$theme.name);
		// if (
		// 	this.$route.query.action &&
		// 	this.isAuthenticated
		// ) {
		// 	this[this.$route.query.action](this.$route.query.param);
		// }

		this.recalculateWindow();
		this.$nextTick(function () {
			window.addEventListener("resize", this.recalculateWindow);
			//Init

			if ('virtualKeyboard' in navigator) {
				navigator.virtualKeyboard.overlaysContent = true
				navigator.virtualKeyboard.addEventListener('geometrychange', (event) => {
					const { x, y, width, height } = event.target.boundingRect;
					let chatInput = document.querySelector(".chat-application .chat-app-form");
					if (chatInput) {
						chatInput.style.marginBottom = `${height}px`;
					}

					this.recalculateWindow()
				})
			}
		});
	},
	// beforeDestroy() {
	// 	window.removeEventListener("resize", this.recalculateWindow);
	// },
	watch: {
		showSideBarMenu() {
			// console.info("changed", this.showSideBarMenu);
			this.recalculateWindow();
		},
		"currentUser.id"() {
			if (!import.meta.env.SSR) {
				// Redirecting a new user who added balance back to advisor from who he registered.
				if (
					this.$route.query.action
					&& this.isAuthenticated
					&& ![
						'/paypal/success',
						'/paypal/fail',
						'/stripe/success',
						'/stripe/fail',
						'/paynl/success',
						'/paynl/fail',
						'/thankyou',
					].includes(this.$route.path)
					&& !this.$route.path.startsWith('/get-started')
				) {
					if (
						// Protecting from running an action which is not supposed to be run with this mechanism.
						[
							'startTextChat',
							'startAudioChat',
							'startVideoChat',
							// TODO: replace startFree... with start...
							'startFreeTextChat',
							'startFreeAudioChat',
							'startFreeVideoChat',
							'notifyMe',
							'sendInboxMessage',
						].includes(this.$route.query.action) &&
						typeof this[this.$route.query.action] == 'function'
					) {
						this[this.$route.query.action](this.$route.query.param, this.$route.query.minutes || null)
					} else {
						console.error('Action not found', this.$route.query.action)
						this.setInitiatingChatLoader(false);
					}
				}
			}
		},
	},
	methods: {
		...mapActions(rootStore, [
			"setWindowHeight",
			"setWindowWidth",
			"setSmallScreenMode",
			"setAdvisorCardsPerRow",
			"hideSideBarMenu",
		]),
		...mapActions(usersStore, [
			"setCancelNotifyConfirmation",
			"setNotifyMeAbout",
		]),
		...mapActions(inboxStore, [
			"openMessageModal",
		]),
		...mapActions(chatStore, [
			"startTextChat",
			"startAudioChat",
			"startVideoChat",
			// TODO: replace startFree... with start...
			"startFreeTextChat",
			"startFreeAudioChat",
			"startFreeVideoChat",
			"setInitiatingChatLoader",
		]),
		emitLogout() {
			this.$emit("onLogOut");
		},
		// Notify me from register action.
		notifyMe(user) {
			console.info(user, typeof user, parseInt(user));
			if (typeof user === "object") this.setNotifyMeAbout(user);
			else {
				this.$api.get(`/user/${user}`).then((res) => {
					this.setNotifyMeAbout(res.data);
				});
			}
		},
		// Send inbox message action from register page.
		sendInboxMessage(id) {
			this.$api.get(`/user/${id}`).then((res) => {
				this.openMessageModal(res.data);
			});
		},
		recalculateWindow(event) {
			this.setWindowHeight(document.documentElement.clientHeight);
			this.setWindowWidth(document.documentElement.clientWidth);
			this.setSmallScreenMode(this.windowWidth < 769);
			this.setAdvisorCardsPerRow(this.windowWidth < 769 ? 1 : this.windowWidth < 1192 ? 2 : 3)
			// horizontal-layout 2-columns pace-done menu-hide vertical-layout vertical-overlay-menu fixed-navbar
			if (
				this.smallScreenMode &&
				!document.body.classList.contains("vertical-layout")
			) {
				document.body.classList.add("vertical-layout");
				document.body.classList.add("vertical-overlay-menu");
			}

			if (
				!this.smallScreenMode &&
				document.body.classList.contains("vertical-layout")
			) {
				document.body.classList.remove("vertical-layout");
				document.body.classList.remove("vertical-overlay-menu");
			}

			if (
				this.showSideBarMenu &&
				document.body.classList.contains("menu-hide")
			) {
				document.body.classList.remove("menu-hide");
				document.body.classList.add("menu-open");
			} else if (
				!this.showSideBarMenu &&
				document.body.classList.contains("menu-open")
			) {
				document.body.classList.remove("menu-open");
				document.body.classList.add("menu-hide");
			}
		},
		// Cancel notifications.
		cancelNotifications(param = null) {
			if (import.meta.env.SSR) {
				return false;
			}

			const id =
				this.cancelNotify && this.cancelNotify.id
					? this.cancelNotify.id
					: param;
			this.cancelingNotifications = true;
			this.$api
				.delete(`/api/user/${id}/notify`, {
					headers: this.$headers ? this.$headers.get(this.$auth) : {},
				})
				.then((res) => {
					console.info(res);
					this.cancelingNotifications = false;
					this.currentUser.notify.forEach((item) => {
						if (item.id === this.cancelNotify.id)
							this.currentUser.notify.splice(
								this.currentUser.notify.indexOf(item),
								1
							);
					});
					this.setCancelNotifyConfirmation(null);
				})
				.catch((error) => {
					console.info(error, error.response);
					this.cancelingNotifications = false;
					this.setCancelNotifyConfirmation(null);
				});
		},
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
			'cancelNotify',
			'isAuthenticated',
		]),
		...mapState(rootStore, [
			'showSideBarMenu',
			'windowWidth',
			'smallScreenMode',
		]),
	},
};
</script>
