export { phoneForDb }

function phoneForDb(phone) {
    phone = (phone ?? '').toString().replace(/[^\+0-9]/g, '')
    // Considering that phone control adds country phone code for blank phone.
    if (phone.length <= 5) {
        phone = ''
    }
    return phone
}
