import Cookies from 'js-cookie'
import {usePageContext} from "./usePageContext.js";
import {getCurrentInstance} from "vue";

// export {PermanentValueStorage}

/** Saves value in local storage or cookies.
 */
export function getStorageValue(name, pageContext = usePageContext()) {
    if (!import.meta.env.SSR) {
        const localStorageValue = window.localStorage?.getItem(name)
        const cookieStorageValue = Cookies.get(name)
        if (localStorageValue && localStorageValue !== 'null') {
            return localStorageValue
        }
        if (cookieStorageValue && cookieStorageValue !== 'null') {
            return cookieStorageValue
        }
    } else {
        let cookies = pageContext?.headers?.cookie?.split('; ').filter(el => el.includes(name))
        console.log("SSR Cookie ", name, cookies?.length ? cookies[0].split('=')[1] : null)
        return cookies?.length ? cookies[0].split('=')[1] : null
    }
    return null
}

export function setStorageValue(name, value, lifetime = 365) {
    if (!import.meta.env.SSR) {
        if (window.localStorage) {
            window.localStorage.setItem(name, value)
        } else {
            Cookies.set(name, value, {sameSite: 'strict', expires: lifetime})
        }
    }
}

export function setCookie(name, value, lifetime = 365) {
    if (!import.meta.env.SSR) {
        Cookies.set(name, value, {sameSite: 'strict', expires: lifetime})
    }
}

export function removeStorageValue(name) {
    if (!import.meta.env.SSR) {
        if (window.localStorage) {
            window.localStorage.removeItem(name)
        }
        Cookies.remove(name)
    }
}