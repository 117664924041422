<template>
  <se-header-menu-admin v-if="isAdmin || (!isSupport && !isSales && $route.path.startsWith('/admin-dashboard'))" />
  <se-header-menu-support v-else-if="isSupport" />
  <se-header-menu-sales v-else-if="isSales" />
  <se-header-menu-user v-else-if="isUser || (!isAdvisor && $route.path.startsWith('/dashboard'))" />
  <se-header-menu-advisor v-else-if="isAdvisor" />
  <se-header-menu-guest v-else />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { usersStore } from '@/src/store/users';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import SeHeaderMenuUser from './menus/HeaderMenuUser';
import SeHeaderMenuGuest from './menus/HeaderMenuGuest';
import SeHeaderMenuAdvisor from './menus/HeaderMenuAdvisor';
import SeHeaderMenuSupport from './menus/HeaderMenuSupport';
import SeHeaderMenuAdmin from './menus/HeaderMenuAdmin';
import SeHeaderMenuSales from './menus/HeaderMenuSales';

const {
  isAdmin, isSupport, isUser, isAdvisor, isSales
} = storeToRefs(usersStore());

const { $route } = getGlobalProperties();
</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenu',
};
</script>
