<template>
    <div class="popup popup-nonblocking" :class="{ 'is-active': loaded && showCookieWarning }">
        <div class="popup-container">
            <div v-show="showCookieWarning">
                <div class="popup-header text-left">
                    <h2>We use cookies</h2>
                </div>
                <div class="popup-scroll text-left">
                    <p>Click “Accept” to enable Us to use cookies to personalize this site, deliver ads, and measure their effectiveness on other apps and websites, including social media. Customize your preferences in your Cookie Settings or click “Reject” if you do not want us to use cookies for this purpose. Learn more in our <a href="/cookie-policy" target="_blank">Cookie Notice</a>.</p>
                </div>
                <div class="popup-footer text-right">
                    <button class="btn btn-secondary" @click="disableCookies">
                        Reject
                    </button>
                    <button class="btn btn-success px-5" @click="acceptCookies">
                        Accept
                    </button>
                </div>
            </div>
            <!-- <div v-show="!showCookieWarning" @click="enableCookieWarning">
                <font-awesome-icon icon="user-shield" class="font-size-large" title="Cookie Settings" />
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { rootStore } from '@/src/store/root'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserShield } from '@fortawesome/free-solid-svg-icons'
library.add(faUserShield)

export default {
    data() {
        return {
            loaded: false,
        }
    },
    components: {
        FontAwesomeIcon,
    },
    mounted() {
        this.loaded = true
    },
    methods: {
        ...mapActions(rootStore, [
            'disableCookies',
            'acceptCookies',
            'enableCookieWarning',
        ]),
    },
    computed: {
        ...mapState(rootStore, [
            'showCookieWarning',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.popup {
    width: auto;
    height: auto!important;
    bottom: 1rem;
    right: 1rem;
    top: auto;
    left: auto;
    padding: 0;
    margin: 0;
}
.popup .popup-container {
    margin: 0!important;
    width: 100%;
}
</style>