export function replaceDisabledWords(message) {
    let replace_string = '<code data-tooltip="This text is disabled. We do not allow personal information sharing." data-tooltip-length="medium" data-tooltip-pos="up">DISABLED</code>';
    return (message ?? '')
        .replace(new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi, 'g'), replace_string)
        .replace(new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, 'g'), replace_string)
        .replace(new RegExp('facebook', 'g'), replace_string)
        .replace(new RegExp('FB', 'g'), replace_string)
        .replace(new RegExp('Face Book', 'g'), replace_string)
        .replace(new RegExp('G M A I L', 'g'), replace_string)
        .replace(new RegExp('H O T M A I L', 'g'), replace_string)
        .replace(new RegExp('Y A H O O', 'g'), replace_string)
        .replace(new RegExp('E M A I L', 'g'), replace_string)
        .replace(new RegExp('Call Me', 'g'), replace_string)
        .replace(new RegExp('P h o n e', 'g'), replace_string)
        .replace(new RegExp('S K Y P E', 'g'), replace_string)
        .replace(new RegExp('gmail', 'g'), replace_string)
        .replace(new RegExp('Gmail', 'g'), replace_string)
        .replace(new RegExp('yahoo', 'g'), replace_string)
        .replace(new RegExp('Yahoo', 'g'), replace_string)
        .replace(new RegExp('aol', 'g'), replace_string)
        .replace(new RegExp('Aol', 'g'), replace_string)
        .replace(new RegExp('skype', 'g'), replace_string)
        .replace(new RegExp('Skype', 'g'), replace_string)
        .replace(new RegExp('phone number', 'g'), replace_string)
        .replace(new RegExp('outlook', 'g'), replace_string)
        .replace(new RegExp('yandex', 'g'), replace_string);
}

export function isValidMessage(message) {
    const regexArray = [
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
        'facebook',
        'FB',
        'Face Book',
        'G M A I L',
        'H O T M A I L',
        'Y A H O O',
        'E M A I L',
        'Call Me',
        'P h o n e',
        'S K Y P E',
        'gmail',
        'Gmail',
        'yahoo',
        'Yahoo',
        'aol',
        'Aol',
        'skype',
        'Skype',
        'phone number',
        'outlook',
        'yandex'
    ];
    let isValid = true;
    regexArray.forEach(item => {
        let reg = new RegExp(item, 'g');
        if (reg.test(message)) {
            console.info(item, 'reg')
            isValid = false;
        }
    });
    return isValid;
}
