import {GeneralConfig} from "../../_core/types/theme/General.type";

const company = {
    name: 'Timesy BV',
    kvk: '82843554',
    address: 'Raamplein 1, 1016XK Amsterdam',
    phone: '0617422916',
};

const general: GeneralConfig =  {
    siteName: 'Timesy',
    domain: 'timesy.com',
    logoDark: '/static/images/theme_icons/timesy/logo_horizontal.png',
    logoWhite: '/static/images/theme_icons/timesy/logo_horizontal.png',
    logoColor: '/static/images/theme_icons/timesy/logo_horizontal.png',
    logoSymbol: '/static/images/theme_icons/timesy/logo_w.png',
    company,
    address: `${company.name}<br>KVK-nummer: ${company.kvk}<br>${company.address}<br><br>Call: <a href="tel:${company.phone}" class="!text-primary-100">${company.phone}</a><br>Mon - Fri 9:00 - 17:00`,
    advisors: 'coaches',
    advisor: 'coach',
    supportEmail: 'support@timesy.com',
    socials: {
        facebook: 'https://www.facebook.com/timesycom/',
        instagram: 'https://www.instagram.com/timesycom/',
    }
};

// @ts-ignore
export default general;
