import Form from '@/src/helpers/form-validation'
import _ from '@/src/helpers/arrayHelpers'
import { TimeCalculations } from '@/src/helpers/timeCalculations'
import { isValidMessage } from "@/src/helpers/messageChecker"
import Compressor from 'compressorjs'
import { Howl } from 'howler'
import config from '@/config'
import { defineStore } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'

export const chatStore = defineStore('chat', {
	state: () => ({
		message: '',
		initiatingChat: '',
		loadingChatInfo: false,
		loadingMessages: false,
		isActiveDeleteConfirmationModal: false,
		sendingMessage: false,
		loadingMore: false,
		lastPage: false,
		typingTimer: null,
		notEnoughMoney: false,
		chatInfo: {},
		chatsHistory: [],
		chatHistory: null,
		getChatHistoryError: false,
		chatsHistoryLoading: false,
		deleteChatLoading: false,
		pausing: false,
		unpausing: false,
		//feedback
		savingFeedback: false,
		feedbackForm: new Form({
			'chat_id': null,
			'feedback': '',
			'feedback_rate': null
		}),
		lastChatRequestUser: {},
		//save action
		savedAction: null,
		savedParam: null,
		savedAdvisor: null,
		//cancel
		canceling: false,
		//chat confirmation
		isActiveTextChatApproval: false,
		isActiveAudioChatApproval: false,
		isActiveVideoChatApproval: false,
		isActiveFreeChatApproval: false,
		//chatinfo tries
		chatInfoTry: 1,
		//received messages to check
		receivedMessages: [],
		// Keep state of video and audio output.
		audioOn: false,
		videoOn: false,
		stopTimerLoading: false,
	}),

	getters: {
		messages() {
			if (!this.chatInfo || !this.chatInfo.id)
				return [];
			return this.chatInfo.messages;
		},
		sounds() {
			return {
				message: new Howl({
					src: ['/static/sounds/' + config.theme.name + '/new_message.mp3'],
					loop: false,
					onend: function () {
						console.log('Message sound played!');
					}
				}),
				chat: new Howl({
					src: ['/static/sounds/' + config.theme.name + '/new_chat.mp3'],
					loop: true,
					onend: function () {
						console.log('Chat sound played!');
					}
				}),
				mail: new Howl({
					src: ['/static/sounds/' + config.theme.name + '/new_notification.mp3'],
					loop: false,
					onend: function () {
						console.log('Notification sound played!');
					}
				}),
				// message: new Audio("/sounds/'+config.theme.name+'/new_message.mp3"),
				// chat: new Audio("/sounds/'+config.theme.name+'/new_chat.mp3"),
				// mail: new Audio("/sounds/'+config.theme.name+'/new_notification.mp3"),
			}
		},
	},

	actions: {
		setSavingFeedback(value) {
			this.savingFeedback = value;
		},
		setChatInfoPaidMinutes(value) {
			this.chatInfo.paid_minutes = value;
		},
		// setNewReceivedMessage(value) {
		// 	this.receivedMessages.push(value);
		// },
		setIsSubscribedToChat(value) {
			this.isSubscribedToChat = value;
		},
		readMessages(sender_id) {
			_.map(this.ChatMessages, item => {
				if (item.unread && item.sender_id === sender_id)
					item.unread = false;
			})
		},
		setStartTyping(id) {
			this.typingArray.push(id);
		},
		setStopTyping(id) {
			if (this.typingArray.indexOf(id) !== -1)
				this.typingArray.splice(this.typingArray.indexOf(id), 1);
		},
		setInitiatedChat(id) {
			this.initiatedChat = id;
		},
		resetChatMessages() {
			this.ChatMessages = [];
		},
		newChatMessage(payload) {
			this.ChatMessages.push({
				id: payload.id,
				sender_id: parseInt(payload.username),
				body: payload.text,
				created_at: payload.created_at,
				unread: true,
				is_image: !!payload.is_image
			});
		},
		newChatSystemMessage(payload) {
			this.ChatMessages.push({ sender_id: 'system', body: payload.text, created_at: payload.created_at });
		},
		setChatInfoLoading(value) {
			this.loadingChatInfo = value;
		},
		setChatInfoTry(value) {
			this.chatInfoTry = value;
		},
		resetFeedbackForm() {
			this.feedbackForm = new Form({
				'chat_id': null,
				'feedback': '',
				'feedback_rate': null
			})
		},
		setFeedbackRating(rating) {
			this.feedbackForm.feedback_rate = rating;
		},
		setFeedbackMessage(msg) {
			this.feedbackForm.feedback = msg;
		},
		setLastChatRequestUser(user) {
			this.lastChatRequestUser = user;
		},
		setChatApproval(show, mode) {
			const users = usersStore()
			if (
				show
				&& !this.currentUserHasEnoughAmountForChat(users.currentUser, this.savedAdvisor, mode)
				&& !users.getFreeMinutes(this.savedAdvisor)
			) {
				// Showing not enough money dialog instead of chat confirmation if there is not enough
				// money to start the chat.
				this.setNotEnoughMoney(true)
			} else {
				switch (mode) {
					case 'text':
						this.isActiveTextChatApproval = show
						break
					case 'audio':
						this.isActiveAudioChatApproval = show
						break
					case 'video':
						this.isActiveVideoChatApproval = show
						break
					default:
						console.error(`${mode} is not supported.`)
				}
			}
		},
		setFreeChatApproval(statement) {
			this.isActiveFreeChatApproval = statement;
		},
		saveAdvisor(advisor) {
			this.savedAdvisor = advisor;
		},
		saveAction(action) {
			this.savedAction = action;
		},
		saveParam(param) {
			this.savedParam = param;
		},
		setChatHistoryError(value) {
			this.getChatHistoryError = value;
		},
		setChatsHistoryLoading(value) {
			this.chatsHistoryLoading = value;
		},
		setChatsHistory(value) {
			this.chatsHistory = value;
		},
		setChatHistory(value) {
			this.chatHistory = value;
		},
		setDeleteChatLoading(value) {
			this.deleteChatLoading = value;
		},
		setIsActiveDeleteConfirmationModal(value) {
			this.isActiveDeleteConfirmationModal = value;
		},
		setChatDeleted(id) {
			if (this.chatsHistory?.data?.length > 0) {
				this.chatsHistory.data.forEach(chat => {
					if (chat.id === id)
						chat.deleted_at = Date.now();
				});
			}
		},
		setChatFeeback({ chat, feedback }) {
			let chatIndex = this.chatsHistory.data.indexOf(chat);
			this.chatsHistory.data[chatIndex].feedback = feedback;
		},
		setChatFeebackRate({ chat, feedback_rate }) {
			let chatIndex = this.chatsHistory.data.indexOf(chat);
			this.chatsHistory.data[chatIndex].feedback_rate = feedback_rate;
		},
		setSendingMessage(value) {
			this.sendingMessage = value;
		},
		setNotEnoughMoney(value) {
			this.notEnoughMoney = value;
		},
		setMessage(value) {
			this.message = value;
		},
		setDeleteTempMessage(message) {
			this.chatInfo.messages.forEach(item => {
				if (item.id === message.id)
					this.chatInfo.messages.splice(this.chatInfo.messages.indexOf(message), 1);
			});
		},
		setChatCanceling(value) {
			this.canceling = value;
		},
		setChatInfo(value) {
			this.chatInfo = value;
		},
		setChatInfoStatus(value) {
			if (!this.chatInfo.id) {
				return false
			}
			this.chatInfo.status = value
		},
		setChatInfoIsFree(value) {
			if (!this.chatInfo.id)
				return false;
			this.chatInfo.is_free = value.is_free;
			this.chatInfo.started_at = value.started_at;
		},
		setChatClientAmount(value) {
			if (!this.chatInfo.id)
				return false;
			console.info('setting new amount', this.chatInfo.client.amount, value)
			this.chatInfo.client.amount = value;
		},
		setChatInfoPauses(pauses) {
			this.chatInfo.pauses = pauses;
		},
		setNewChatMessage(value) {
			if (value) {
				let msg = _.filter(this.chatInfo.messages, item => item?.id === value.id);
				// console.info(msg)
				if (!msg || msg.length !== 0 || !this.chatInfo || !this.chatInfo.messages)
					return false;
				this.chatInfo.messages.push(value);
			}
		},
		// setMessageFailed(temp_id) {
		// 	if (this.chatInfo.messages) {
		// 		this.chatInfo.messages.forEach(item => {
		// 			if (item.id === temp_id) {
		// 				item.sending = false;
		// 				item.failed = true;
		// 				// this.chatInfo = JSON.parse(JSON.stringify(this.chatInfo));
		// 			}
		// 		});
		// 	}
		// },
		// setReplaceTempMessage(payload) {
		// 	this.chatInfo.messages.forEach(item => {
		// 		if (item.id === payload.temp_id) {
		// 			let msg = payload.message;
		// 			msg.is_unread = true;
		// 			this.chatInfo.messages[this.chatInfo.messages.indexOf(item)] = msg;
		// 			// this.chatInfo = JSON.parse(JSON.stringify(this.chatInfo));
		// 		}
		// 	});
		// },
		setFeedbackFormChatId(id) {
			this.feedbackForm.chat_id = id;
		},
		setPausing(pausing) {
			this.pausing = pausing
		},
		setUnpausing(unpausing) {
			this.unpausing = unpausing
		},
		setLoadingMessages(loadingMessages) {
			this.loadingMessages = loadingMessages
		},
		setLoadingMore(loadingMore) {
			this.loadingMore = loadingMore
		},
		setMessages(messages) {
			this.messages = messages
		},
		setLastPage(lastPage) {
			this.lastPage = lastPage
		},
		setCurrentChatUnreadCount(unreadCount) {
			this.currentChat.unread_count = unreadCount
		},
		setAudioOn(value) {
			this.audioOn = value

			// Turning off notifications while in audio/video chat.
			console.log('messages muted', value)
			this.$chatSound.volume(value ? 0 : 1)
			this.$messageSound.volume(value ? 0 : 1)
			this.$notificationSound.volume(value ? 0 : 1)
		},
		setVideoOn(value) {
			this.videoOn = value
		},

		updateChatInfoClientBalance() {
			console.info('attempting to update balance', !this.chatInfo.id)
			if (!this.chatInfo.id)
				return false;
			console.info('requesting new balance')
			this.$api.get(`/api/user/${this.chatInfo.client_id}/balance`)
				.then(res => {
					console.info('amount res', res)
					this.setChatClientAmount(res.data)
				})
				.catch(async err => {
					console.info(err, err.response);
				})
		},
		//get current chat messages
		getChatMessages(id) {
			this.setLoadingMessages(true);
			this.$api.get('/api/chat/' + id)
				.then(response => {
					this.setMessages(response.data);
					this.sortMessages()
					this.scrollToMessage()
					this.setLoadingMessages(false);
				})
				.catch(error => {
					console.error(error);
					this.setLoadingMessages(false);
				});
		},
		//load more messages to current chat
		loadMore(id) {
			this.setLoadingMore(true);
			this.$api.post('/api/chat/' + id, { offset: this.messages.length })
				.then(response => {
					if (response.data.length === 0) {
						this.setLastPage(true);
					}
					this.messages.push(...response.data);
					this.sortMessages()
					this.scrollToMessage(true)
					this.setLoadingMore(false);
				})
				.catch(error => {
					console.error(error);
					this.setLoadingMore(false);
				})
		},
		//sor messages out of creation date
		sortMessages() {
			this.messages.sort(function (a, b) {
				if (typeof a.created_at === 'string')
					a.created_at = Date.parse(a.created_at) / 1000;

				if (typeof b.created_at === 'string')
					b.created_at = Date.parse(b.created_at) / 1000;

				if (a.created_at > b.created_at) {
					return 1;
				}
				if (a.created_at < b.created_at) {
					return -1;
				}
				return 0;
			});
		},
		// //mark all recent chat messages as read
		// markAsRead(id) {
		// 	if (!this.chatInfo.messages || this.chatInfo.messages.length === 0)
		// 		return false;
		// 	if (this.chatInfo.id && this.chatInfo.unread_count > 0)
		// 		this.$api.post('/api/chat/' + id + '/read', {})
		// 			.then(response => {
		// 				this.setCurrentChatUnreadCount(0);
		// 				this.messages.forEach(msg => {
		// 					if (msg.from_tmp && msg.is_unread === 1)
		// 						msg.is_unread = 0;
		// 				});
		// 				const root = rootStore()
		// 				this.$messaging.markChatRead(
		// 					this.currentChat.id,
		// 					this.currentChat.tmp_user_id
		// 				)
		// 			})
		// 			.catch(error => console.error(error))
		// },
		//scroll block to message
		scrollToMessage(isFirst = false) {
			// console.log('need scroll')
			setTimeout(() => {
				let element = document.getElementById("scroll-area");
				if (element !== null) {
					if (isFirst) {
						element.scrollTop = 0;
						// console.log('scroll top')
					} else {
						element.scrollTop = element.scrollHeight;
						// console.log('scroll bottom')
						// console.log(element.scrollTop)
						// console.log(element.scrollHeight)
					}
				}
			}, 0);
		},
		getActionByChatMode(mode, is_free = false) {
			let action = ''
			switch (mode) {
				case 'text':
					if (is_free) {
						action = 'startFreeTextChat'
					} else {
						action = 'startTextChat'
					}
					// action = 'startTextChatSession'
					break
				case 'audio':
					if (is_free) {
						action = 'startFreeAudioChat'
					} else {
						action = 'startAudioChat'
					}
					// action = 'startAudioChatSession'
					break
				case 'video':
					if (is_free) {
						action = 'startFreeVideoChat'
					} else {
						action = 'startVideoChat'
					}
					// action = 'startVideoChatSession'
					break
				default:
					console.error(`getActionByChatMode: ${mode} mode is not supported.`)
			}
			return action
		},
		getChatModeByAction(action) {
			let mode = ''
			let is_free = false
			switch (action) {
				case 'startFreeTextChat':
					mode = 'text'
					is_free = true
					break
				case 'startTextChat':
					mode = 'text'
					break
				case 'startFreeAudioChat':
					mode = 'audio'
					is_free = true
					break
				case 'startAudioChat':
					mode = 'audio'
					break
				case 'startFreeVideoChat':
					mode = 'video'
					is_free = true
					break
				case 'startVideoChat':
					mode = 'video'
					break
				default:
					console.error(`getChatModeByAction: ${action} action is not supported.`)
			}
			return {
				mode,
				is_free,
			}
		},
		getPerMinuteFeeFieldByChatMode(mode) {
			let fieldName = ''
			switch (mode) {
				case 'text':
					fieldName = 'per_minute_fee_text'
					break
				case 'audio':
					fieldName = 'per_minute_fee_audio'
					break
				case 'video':
					fieldName = 'per_minute_fee_video'
					break
				default:
					console.error(`getPerMinuteFeeFieldByChatMode: ${mode} mode is not supported.`)
			}
			return fieldName
		},
		getChatInfo(id) {
			// if (this.chatInfo && this.chatInfo.id === id)
			// 	return false;
			console.info('chatInfo', id)
			if (!(this.chatInfo && this.chatInfo.id === id)) {
				this.setChatInfoLoading(true);
			}
			this.$api.get('/api/chat/' + id)
				.then(res => {
					this.setChatInfo(res.data)
					// Setting action from the chat type.
					if (this.chatInfo?.mode) {
						this.saveAction(this.getActionByChatMode(this.chatInfo?.mode))
					}
					this.setChatInfoTry(1)
					this.setChatInfoLoading(false);
				})
				.catch(error => {
					console.info(error)
					this.setChatInfoLoading(false);
					if (this.chatInfoTry < 3) {
						this.setChatInfoTry(this.chatInfoTry + 1);
						this.getChatInfo(id);
					}
				})
		},
		//send message
		sendMessage(message, mode = 'text') {
			console.info('send message')
			if (message === '' || message.trim() === '') {
				return false;
			}
			let temp_id = 'temp_' + Math.floor((Math.random() * (99 - 10 + 1) + 10));
			const users = usersStore()
			let msg = {
				body: message,
				chat_id: users.currentUser.active_chat ? users.currentUser.active_chat.id : this.chatInfo.id,
				id: temp_id,
				is_hire: 0,
				is_image: 0,
				// is_video: mode == 'video',
				// is_audio: mode == 'audio',
				sender_id: users.currentUser.id,
				sending: true,
				failed: false,
			};
			// this.setNewChatMessage(msg);
			this.setMessage('');

			this.setSendingMessage(true);
			return this.$api.post('/api/chat/message', {
				'body': msg.body,
				'chat_id': users.currentUser.active_chat ? users.currentUser.active_chat.id : this.chatInfo.id,
				'sender_id': users.currentUser.id,
				// 'is_video': msg.is_video,
				// 'is_audio': msg.is_audio,
			})
				.then(res => {
					this.setSendingMessage(false);
					this.checkMessage(res.data)
					// this.setReplaceTempMessage({temp_id: temp_id, message: res.data});
					console.info(res);
					return res.data;
				})
				.catch(error => {
					console.info(error, error.response);
					this.setSendingMessage(false);
					// this.setMessageFailed(temp_id);
					if (error.response)
						this.$toast({
							message: error.response,
							type: 'error'
						});
				})
		},
		//send hidden system message
		async sendSystemMessage(message) {
			console.info('send system message')
			if (message === '' || message.trim() === '') {
				return false;
			}
			let temp_id = 'temp_' + Math.floor((Math.random() * (99 - 10 + 1) + 10));
			const users = usersStore()
			let msg = {
				body: message,
				chat_id: users.currentUser.active_chat ? users.currentUser.active_chat.id : this.chatInfo.id,
				id: temp_id,
				// is_hire: 0,
				// is_image: 0,
				// is_video: mode == 'video',
				// is_audio: mode == 'audio',
				sender_id: users.currentUser.id,
				sending: true,
				failed: false,
				is_system: true,
			};
			// this.setNewChatMessage(msg);
			this.setMessage('');

			this.setSendingMessage(true);
			await this.$api.post('/api/chat/message', {
				'body': msg.body,
				'chat_id': users.currentUser.active_chat ? users.currentUser.active_chat.id : this.chatInfo.id,
				'sender_id': users.currentUser.id,
				// 'is_video': msg.is_video,
				// 'is_audio': msg.is_audio,
				is_system: true,
			})
				.then(res => {
					this.setSendingMessage(false);
					this.checkMessage(res.data)
					// this.setReplaceTempMessage({temp_id: temp_id, message: res.data});
					console.info(res);
					return res.data;
				})
				.catch(error => {
					console.info(error, error.response);
					this.setSendingMessage(false);
					// this.setMessageFailed(temp_id);
					if (typeof error.response == 'string') {
						this.$toast({
							message: error.response,
							type: 'error'
						});
					} else {
						console.error(error.response)
					}
				})
		},
		//send image
		sendImage(event) {
			const users = usersStore()
			const _this = this
			new Compressor(event.target.files[0], {
				maxWidth: 1500,
				maxHeight: 1500,
				convertTypes: 'image/png',
				convertSize: 500000, // Large PNG images will be converted to JPEG
				success(result) {
					let data = new FormData();
					data.append('is_image', 1);
					data.append('chat_id', users.currentUser.active_chat.id);
					data.append('sender_id', users.currentUser.id);
					data.append('image', result, result.name);

					_this.setSendingMessage(true);
					_this.$api.post('/api/chat/message', data)
						.then(res => {
							_this.setSendingMessage(false);
							console.info(res);
						})
						.catch(error => {
							_this.setSendingMessage(false);
							// _this.closeMessageModal()
							console.info(error, error.response);

							if (error.response) {
								_this.$toast({
									message: '<b>Error: </b>' + error.response,
									type: 'error'
								});
							}
						})
				},
				error(err) {
					_this.$toast({
						message: '<b>Error: </b>' + err.message,
						type: 'error'
					});
				},
			});
		},

		/** For video and audio chats to make time calculation my messages work, sending messages in the beginning of the chat.
		*/
		async sendChatStartMessages() {
			if (['video', 'audio'].includes(this.chatInfo.mode)) {
				let message = ''
				switch (this.chatInfo.mode) {
					case 'video':
						message = 'Video call started'
						break
					case 'audio':
						message = 'Voice call started'
						break
				}
				await this.sendSystemMessage(message, this.chatInfo.mode)
			}
		},
		/** For video and audio chats to make time calculation my messages work, sending messages during the chat.
		*/
		async sendChatProgressMessages() {
			if (['video', 'audio'].includes(this.chatInfo.mode) && this.chatInfo.status != 'paused') {
				let message = ''
				switch (this.chatInfo.mode) {
					case 'video':
						message = 'Video call on air'
						break
					case 'audio':
						message = 'Voice call on air'
						break
				}
				await this.sendSystemMessage(message, this.chatInfo.mode)
			}
		},
		/** For video and audio chats to make time calculation my messages work, sending messages in the end of the chat.
		*/
		async sendChatCloseMessages() {
			if (['video', 'audio'].includes(this.chatInfo.mode)) {
				let message = ''
				switch (this.chatInfo.mode) {
					case 'video':
						message = 'Video call ended'
						break
					case 'audio':
						message = 'Voice call ended'
						break
				}
				await this.sendSystemMessage(message, this.chatInfo.mode)
			}
		},

		checkMessage(msg) {
			const users = usersStore()
			if (!isValidMessage(msg.body))
				this.$api.post('/personal-info-sharing', {
					type: 'words',
					source: 'chat',
					source_id: msg.chat_id,
					user_id: users.currentUser.id
				})
					.then(res => {
						console.info(res);
					})
					.catch(err => console.info(err, err.response))
		},
		// Starts chat right now.
		async forceStartChat(advisor, mode = 'text', is_free = false) {
			const users = usersStore()
			if (!users.currentUser?.id) {
				if (is_free) {
					this.startFreeChat(advisor.id, mode)
				} else {
					console.log("forceStartChat this.startChat")
					this.startChat(advisor.id, mode)
				}
			} else {
				if (advisor.id == users.currentUser.id) {
					this.$toast({
						message: "You can't call yourself.",
						type: 'error',
					})
				} else {
					if (is_free) {
						this.startFreeChat(advisor.id, mode)
					} else {
						let action = this.getActionByChatMode(mode)
						if (!users.currentUser.have_added_funds_before) {
							console.info(`[start ${mode} chat]`, 'redirect to money page')
							console.log("forcestartchat".toUpperCase())
							this.$router.push({ path: '/get-started', query: { param: advisor.id, action: action } })
						} else {
							// Making sure advisor data is fresh and has field values calculated for the current user.
							await this.updateSavedAdvisor(advisor.id)
							this.saveAction(action)
							this.saveParam(advisor.id)
							this.setChatApproval(true, mode, advisor.id)
						}
					}
				}
			}
		},
		// Reads from API advisor data and saves as savedAdvisor.
		async updateSavedAdvisor(advisorId) {
			if (!advisorId) {
				return
			}
			const users = usersStore()
			const advisor = await users.getUserByIdRaw(advisorId)
			this.saveAdvisor(advisor)
		},
		setInitiatingChatLoader(value) {
			this.initiatingChat = value;
		},
		async startChat(advisorId = null, mode = 'text', minutes = null) {
			const users = usersStore()
			let action = this.getActionByChatMode(mode)

			console.log("users.currentUser?.id", users.currentUser?.id, users.isAuthenticated)
			if (!users.currentUser?.id || !users.isAuthenticated) {
				console.info(`[start ${mode} chat]`, 'user is not logged in')
				// redirect to sign in page instead of showing modal

				this.$router.push({
					path: '/get-started',
					query: {
						redirect_url: this.$router.route.path,
						action: action,
						param: advisorId,
					}
				});
				return;
			}

			// TODO: startloading
			this.initiatingChat = true;

			// Requesting fresh user data.
			if (users.currentUser?.id) {
				await users.getCurrentUser()
			}

			console.info(`[start ${mode} chat]`, advisorId);
			
			this.saveAction(action)
			this.saveParam(advisorId)

			this.setChatApproval(false, mode)
			this.resetChatMessages()

			// Making sure advisor data is fresh and has field values calculated for the current user.
			await this.updateSavedAdvisor(advisorId)
			// let perMinuteFeeFieldName = this.getPerMinuteFeeFieldByChatMode(mode)
			// let perMinuteFee = Number(this.savedAdvisor[perMinuteFeeFieldName])
			// let discountedPerMinuteFee = users.calculateDiscountedFee(perMinuteFee, this.savedAdvisor)
			// console.log('[startChat]', perMinuteFeeFieldName, perMinuteFee, discountedPerMinuteFee, this.savedAdvisor)

			if (users.currentUser?.active_chat !== null) {
				console.log(`[start ${mode} chat]`, 'has active chat')
				this.$router.push({ path: `/dashboard/chat`, query: { mode: mode } })
				this.initiatingChat = false;
			} else {
				console.info(`[start ${mode} chat]`, 'everything is ok, starting chat')

				this.setSendingMessage(true)
				const root = rootStore()
				let request_data = {
					message: this.message,
					access_string: root.AccessString,
					mode: mode,
				};
				if (advisorId) {
					request_data.id = advisorId;
				} else {
					request_data.url = users.userInfo.url;
				}

				this.$api.post('/api/chat/new', request_data)
					.then(async (res) => {
						console.log("/api/chat/new".toUpperCase(), res, res.data)
						const users = usersStore()
						await users.getCurrentUser()
						// TODO: after backend endpoint response is fixed update this with res.data.active_chat
						users.setActiveChat(usersStore().currentUser.active_chat)
						// users.currentUser.active_chat = res.data.active_chat;
						this.setInitiatedChat(null)
						this.getChatInfo(usersStore().currentUser.active_chat.id)
						this.setSendingMessage(false)
						this.setMessage('')
						console.info(`[start ${mode} chat]`, 'res', res);
					})
					.catch(error => {
						console.info(`[start ${mode} chat]`, error, error.response);
						this.setSendingMessage(false)

						if (error?.status === 402) {
							if (!users.currentUser.have_added_funds_before) {
								console.info(`[start ${mode} chat]`, 'redirect to money page')
								this.$router.push({
									path: '/get-started',
									query: {
										action,
										param: advisorId,
										...(minutes?.length && { minutes }),
									}
								})
							} else {
								this.setNotEnoughMoney(true)
							}
						} else {
							if (error.response?.message) {
								this.$toast({
									message: error.response?.message,
									type: 'error',
								})
							} else if (typeof error.response == 'string') {
								this.$toast({
									message: error.response,
									type: 'error',
								})
							}
							this.$router.push(this.$router.route.path);
						}
					}).finally(() => {
						this.initiatingChat = false;
					})
			}
		},
		async startFreeChat(advisorId, mode, from_page = true) {
			if (from_page) {
				this.setFreeChatApproval(false)
			}
			console.info(`[start free ${mode} chat]`, advisorId)

			let action = this.getActionByChatMode(mode, true)
			this.saveAction(action)
			this.saveParam(advisorId)

			// Making sure advisor data is fresh and has field values calculated for the current user.
			await this.updateSavedAdvisor(advisorId)

			const users = usersStore()
			if (from_page && (!users.currentUser?.id || !users.isAuthenticated)) {
				this.$router.push({
					path: '/get-started',
					query: {
						redirect_url: this.$router.route.path,
						action: action,
						param: advisorId,
					}
				})
			} else if (from_page && (users.currentUser.active_chat !== null)) {
				return false
			} else if (from_page && (Number(users.currentUser.amount) < 3)) {
				this.setNotEnoughMoney(true)
			} else {
				this.setSendingMessage(true)
				let request_data = {
					id: advisorId,
					mode: mode,
				};
				if (from_page) {
					request_data.from_page = from_page
				}

				this.$api.post('/api/chat/free', request_data)
					.then(res => {
						users.setActiveChat(res.data.active_chat)
						this.getChatInfo(res.data.active_chat.id)
						this.setSendingMessage(false)
						this.setMessage('')
						console.info(`[start free ${mode} chat]`, 'res', res);
					})
					.catch(error => {
						this.setSendingMessage(false)
						console.info(`[start free ${mode} chat]`, error.response);
						this.$toast({
							message: error.response.data,
							type: 'error'
						});
						this.$router.push(this.$router.route.path);
					})
			}
		},

		// Public methods for starting specific modes of chat.
		startTextChat(advisorId = null, minutes = null) {
			return this.startChat(advisorId, 'text', minutes)
		},
		startAudioChat(advisorId = null, minutes = null) {
			return this.startChat(advisorId, 'audio', minutes)
		},
		startVideoChat(advisorId = null, minutes = null) {
			return this.startChat(advisorId, 'video', minutes)
		},
		startFreeTextChat(advisorId = null) {
			return this.startFreeChat(advisorId, 'text')
		},
		startFreeAudioChat(advisorId = null) {
			return this.startFreeChat(advisorId, 'audio')
		},
		startFreeVideoChat(advisorId = null) {
			return this.startFreeChat(advisorId, 'video')
		},

		startAdminChat(advisorId, mode) {
			this.setChatApproval(false, mode)
			console.info('start admin chat', advisorId)
			const users = usersStore()
			if (users.currentUser.active_chat !== null) {
				return false
			} else {
				this.startFreeChat(advisorId, mode, false)
			}
		},
		cancelChat() {
			this.setChatCanceling(true);
			this.$api.post('/api/chat/cancel', {})
				.then(res => {
					console.info(res);
					this.$toast({
						message: 'Your chat request has been canceled!',
					});
					this.setChatCanceling(false)
					this.$router.push('/advisors/online')
				})
				.catch(error => {
					console.info(error, error.response);
					this.setChatCanceling(false);
				})
		},
		pauseChatToAddFunds() {
			const users = usersStore()
			if (!users.isUser) {
				return false
			}
			this.setPausing(true);
			this.$api.post('/api/chat/add-funds-pause', {})
				.then(res => this.setPausing(false))
				.catch(error => {
					console.info(error.response);
					// if (error?.status === 402) {
					// 	this.saveAdvisor(this.chatInfo?.advisor)
					// 	this.saveAction(this.getActionByChatMode(this.chatInfo?.mode))
					// 	this.setNotEnoughMoney(true)
					// } else {
					if (error.response && error.response.data) {
						this.$toast({
							message: error.response.data,
							type: 'error'
						});
					}
					// }
					this.setPausing(false);
				})
		},
		unPauseChat() {
			const users = usersStore()
			if (!users.isUser) {
				return false
			}
			this.setUnpausing(true);
			this.$api.post('/api/chat/unpause', {})
				.then(res => {
					this.setUnpausing(false)
					this.setChatInfo(res.data)
					const users = usersStore()
					users.setActiveChat(res.data)
				})
				.catch(error => {
					console.info(error.response);
					if (error?.status === 402) {
						this.saveAdvisor(this.chatInfo?.advisor)
						this.saveAction(this.getActionByChatMode(this.chatInfo?.mode))
						this.setNotEnoughMoney(true)
					} else {
						if (error.response && error.response.data) {
							this.$toast({
								message: error.response.data,
								type: 'error'
							});
						}
					}
					this.setUnpausing(false);
				})
		},
		async closeChat(force) {
			console.log('closeChat')
			// Protecting from sending each second close chat for closed chat.
			// if (this.chatInfo.status == 'closed' || this.closingChat) {
			if (this.chatInfo.status == 'closed') {
				if (force) {
					// Getting out of chat page in case if chat is ended but for some reason interface not updated.
					this.$router.push('/advisors/online')
				}
				return
			}
			// this.closingChat = true

			if (this.$video.isOnAir() && !this.$video.hasMalfunction() && this.$video.sendChatCloseMessages) {
				await this.$video.sendChatCloseMessages()
			}

			this.stopTimerLoading = true
			const users = usersStore()
			return this.$api.post('/api/chat/stop', {})
				.then(res => {
					users.setCurrentUser(res.data)
					this.setChatInfo({})
					// this.isActiveStopTimerConfirmation = false
					this.stopTimerLoading = false
					return true
				})
				.catch(error => {
					console.info(error, error.response)
					if (error.status == 404) {
						console.info('NOT FOUND')
						// This is the case when the chat is closed by timer of the other party already.

						// this.$toast({
						// 	message: 'You have no active chat at this moment.',
						// })
						// users.setActiveChat(null)
						// this.setChatInfo({})
						// this.$router.push('/advisors/online')
					}
					this.stopTimerLoading = false
					return true
				})
		},
		getChatsHistory(filters) {
			this.setChatsHistoryLoading(true);
			this.$api.post('/api/chat/history', filters)
				.then(res => {
					this.setChatsHistory(res.data);
					this.setChatsHistoryLoading(false);
				})
				.catch(error => {
					console.info(error.response);
					this.setChatsHistoryLoading(false);
				})
		},
		getChatHistory(id) {
			this.setChatHistory({});
			this.setChatsHistoryLoading(true);
			this.setChatHistoryError(false);
			return this.$api.get('/api/chat/' + id)
				.then(res => {
					this.setChatHistory(res.data);
					this.setChatsHistoryLoading(false);
					return res;
				})
				.catch(error => {
					console.info(error.response);
					this.setChatHistoryError(true);
					this.setChatsHistoryLoading(false);
				})
		},
		deleteChat(id) {
			this.setDeleteChatLoading(true);
			this.$api.delete('/api/chat/' + id)
				.then(res => {
					this.setChatHistory(res.data);
					this.setDeleteChatLoading(false);
					this.setIsActiveDeleteConfirmationModal(false);
					this.setChatDeleted(id);
					this.$router.push('/dashboard/history');
				})
				.catch(error => {
					console.info(error);
					this.setDeleteChatLoading(false);
				})
		},
		editFeedback(form) {
			return form.submit(this.$api, 'post', '/api/chat/' + form.chat_id + '/feedback')
				.then(res => {
					console.info(res);
					console.info('ch h', this.chatsHistory)
					if (this.chatsHistory.data.length !== 0) {
						this.chatsHistory.data.forEach(chat => {
							if (chat.id === form.chat_id) {
								let feedback = res.feedback;
								let feedback_rate = res.feedback_rate;
								this.setChatFeeback({ chat, feedback });
								this.setChatFeebackRate({ chat, feedback_rate });
							}
						});
					}
				})
				.catch(error => {
					console.info(error, error.response);
					this.$toast({
						message: error,
						type: 'error'
					});
				})
		},
		editFeedbackReply(form) {
			return form.submit(this.$api, 'post', '/api/chat/' + form.chat_id + '/feedback-reply')
				.then(res => {
					if (this.chatHistory) {
						this.chatHistory.feedback_reply = res.feedback_reply
					}
				})
				.catch(error => {
					console.info(error, error.response);
					this.$toast({
						message: error,
						type: 'error'
					});
				})
		},
		//save feedback
		saveFeedBack(redirectUrl = '/dashboard/history') {
			this.setSavingFeedback(true);
			let form = new Form({
				...this.feedbackForm
			});
			console.info(this.feedbackForm)
			form.submit(this.$api, 'post', '/api/chat/' + form.chat_id + '/feedback')
				.then(res => {
					console.info(res);
					this.$router.push(redirectUrl);
					this.$toast({
						message: 'Feedback has been saved. Thank you for your opinion! You can update your feedback any time within 7 days after chat session.',
						type: 'success'
					});
					this.setSavingFeedback(false)
					this.resetFeedbackForm()
				})
				.catch(error => {
					console.info(error.response);
					this.$router.push(redirectUrl);
					if (error.response)
						this.$toast({
							message: error.response,
							type: 'error'
						});
					this.setSavingFeedback(false)
					this.resetFeedbackForm()
				})
		},

		// Chat information helpers.
		chatLength(chatInfo) {
			if (!chatInfo) {
				return 0
			}
			if (!chatInfo.started_at) {
				return 0
			}

			let endedAtTime = chatInfo.ended_at
			if (!endedAtTime) {
				const root = rootStore()
				endedAtTime = TimeCalculations.rawAddTime(TimeCalculations.rawMomentObject(), -root.timeDifference, 'seconds')
			}
			// const root = rootStore()
			// console.log("chatInfo.started_at, endedAtTime, root.timeDifference", chatInfo.started_at, endedAtTime, root.timeDifference)
			return TimeCalculations.getSecondsFromDate(chatInfo.started_at, endedAtTime)
		},
		//chats free time in seconds
		chatFreeSeconds(chatInfo) {
			return this.chatFreeMinutes(chatInfo) * 60
		},
		chatFreeMinutes(chatInfo) {
			return chatInfo.free_minutes + chatInfo.penalty_minutes
		},
		//chat time without pauses
		chatSeconds(chatInfo) {
			return Math.max(0, this.chatLength(chatInfo) - this.chatPausedSeconds(chatInfo))
		},
		//calculate chat amount paid
		chatAmountPaid(chatInfo) {
			return chatInfo.paid_minutes * chatInfo.per_minute_fee;
		},
		//reformat creation date
		chatCreationDate(chatInfo) {
			return TimeCalculations.formatDate(chatInfo.created_at)
		},

		currentUserHasEnoughAmountForChat(user, advisor, mode) {
			const users = usersStore()

			let perMinuteFeeFieldName = this.getPerMinuteFeeFieldByChatMode(mode)
			let perMinuteFee = Number(advisor[perMinuteFeeFieldName])
			let discountedPerMinuteFee = users.calculateDiscountedFee(perMinuteFee, advisor)

			return Number(user?.amount) >= discountedPerMinuteFee
		},
		chatPausedSeconds(chat) {
			let paused_seconds = 0
			if (chat.pauses) {
				chat.pauses.forEach(pause => {
					let end = pause.ended_at
					if (!end) {
						const root = rootStore()
						end = TimeCalculations.rawAddTime(TimeCalculations.rawMomentObject(), -root.timeDifference, 'seconds')
					}
					paused_seconds += TimeCalculations.getSecondsFromDate(pause.started_at, end, 'seconds')
				})
			}
			return paused_seconds
		},

		// Calculates message time for output in chat history.
		renderTime(time, chat) {
			if (!chat?.started_at || !time) {
				return '0'.toHHMMSS();
			}

			const pauseTime = chat.pauses ? chat.pauses.reduce((timeAccumulator, pause) => {
				if (pause.ended_at && TimeCalculations.isTimeBetween(time, pause.created_at, pause.ended_at)) {
					timeAccumulator += TimeCalculations.getSecondsFromDate(pause.created_at, pause.ended_at)
				}
				return timeAccumulator;
			}, 0) : 0;
			const diff = TimeCalculations.getSecondsFromDate(chat.started_at, time)
			const finalTime = (diff > 0 ? diff : 0) - pauseTime;
			return finalTime.toString().toHHMMSS();
		},

	}
});
