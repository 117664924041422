<template>
    <div ref="testCallContainer" class="callContainer fullscreen">
        <div class="buttons">
            <button class="btn btn-light" @click="toggleVideoMute()" title="Toggle camera" v-if="videoMode"
                key="videoToggle">
                <font-awesome-icon icon="video" class="font-large-2" v-show="videoOn" />
                <font-awesome-icon icon="video-slash" class="font-large-2" v-show="!videoOn" />
            </button>

            <button class="btn btn-light" @click="toggleAudioMute()" title="Toggle microphone" v-if="audioMode"
                key="audioToggle">
                <font-awesome-icon icon="microphone" class="font-large-2" v-show="audioOn" />
                <font-awesome-icon icon="microphone-slash" class="font-large-2" v-show="!audioOn" />
            </button>

            <button class="btn btn-light" @click="stopTestCall()" title="End test call" key="close">
                <font-awesome-icon icon="times" class="font-large-2" />
            </button>
        </div>

        <div class="call-icon audio-only"><font-awesome-icon icon="phone" class="font-large-3" /></div>
        <div class="call-icon video-only"><font-awesome-icon icon="video" class="font-large-3" /></div>

        <div class="notice w-100 p-3">
            <div class="volumePreview">
                <div v-for="volume in audioVolumeHistory" class="volume" :class="'volume' + Math.round(volume / 10)"></div>
            </div>
            <p class="audio-only text-center font-medium-4">If you can hear your own voice, then your microphone is
                configured for voice call correctly.</p>
            <p class="video-only text-center font-medium-4">If you can see yourself and hear your own voice, then your
                camera and microphone are configured for video call correctly.</p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '@/src/store/users'
import { chatStore } from '@/src/store/chat'
import config from '@/config'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faVideo, faPhone, faTimes, faVideoSlash, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
library.add(faVideo, faPhone, faTimes, faVideoSlash, faMicrophone, faMicrophoneSlash)

export default {
    props: {
        audioMode: {
            type: Boolean,
            default: true,
        },
        videoMode: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            audioVolumeHistory: [],
            volumeUpdateInterval: null,
        }
    },
    mounted() {
    },
    watch: {
    },
    computed: {
        ...mapState(usersStore, [
            'currentUser',
        ]),
        ...mapState(chatStore, [
            'audioOn',
            'videoOn',
        ]),
    },
    methods: {
        start() {
            this.$video.testCall(this.videoMode, this.audioMode, this.$refs.testCallContainer, this.currentUser.id)

            let _this = this
            this.volumeUpdateInterval = setInterval(function () {
                _this.audioVolumeHistory = _this.$video?.audioVolumeHistory ?? []
                _this.$forceUpdate()
            }, 100)
        },
        stopTestCall() {
            this.$video.stopTestCall()
            if (this.volumeUpdateInterval) {
                clearInterval(this.volumeUpdateInterval)
                this.volumeUpdateInterval = null
            }
        },
        toggleVideoMute() {
            if (this.$video) {
                this.$video.toggleVideoMute()
                this.$forceUpdate()
            }
        },
        toggleAudioMute() {
            if (this.$video) {
                this.$video.toggleAudioMute()
                this.$forceUpdate()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
$normal-volume-color: #66ff66;
$warning-volume-color: #ffee55;
$bad-volume-color: #ff7777;
$window-background-color: #ffffff;
$volume-block-space: 0.1rem;
$volume-block-height: 1vh;
$volume-block-minimum-width: 2px;
$volume-levels: 10;

$normal-volume-background-image: linear-gradient($normal-volume-color calc($volume-block-height - $volume-block-space), transparent $volume-block-space);
$warning-volume-background-image: linear-gradient($warning-volume-color calc($volume-block-height - $volume-block-space), transparent $volume-block-space);
$bad-volume-background-image: linear-gradient($bad-volume-color calc($volume-block-height - $volume-block-space), transparent $volume-block-space);

.volumePreview {
    display: inline-block;
    height: calc($volume-levels * $volume-block-height);
    width: 100%;
    overflow: hidden;
}

.volumePreview .volume {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    float: left;
    width: max($volume-block-minimum-width, calc(0.5% - $volume-block-space));
    margin-left: $volume-block-space;
    height: 100%;
    overflow: hidden;
    vertical-align: bottom;
}

.volumePreview .volume:before {
    content: '';
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: $normal-volume-background-image;
    background-repeat: repeat-y;
    background-size: 100% $volume-block-height;
    background-position: left bottom;
}

.volumePreview .volume:after {
    content: '';
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 0%;
    background-color: transparent;
    background-image: none;
    background-repeat: repeat-y;
    background-size: 100% $volume-block-height;
    background-position: left bottom;
}

.volumePreview .volume.volume0:before {
    height: 0%;
}

.volumePreview .volume.volume1:before {
    height: 10%;
}

.volumePreview .volume.volume2:before {
    height: 20%;
}

.volumePreview .volume.volume3:before {
    height: 30%;
}

.volumePreview .volume.volume4:before {
    height: 40%;
}

.volumePreview .volume.volume5:before {
    height: 50%;
}

.volumePreview .volume.volume6:before {
    height: 60%;
}

.volumePreview .volume.volume7:before {
    height: 70%;
}

.volumePreview .volume.volume8:before {
    height: 80%;
}

.volumePreview .volume.volume9:before {
    height: 90%;
}

.volumePreview .volume.volume10:before {
    height: 100%;
}

.volumePreview .volume.volume8:after {
    top: 20%;
    height: 20%;
    background-image: $warning-volume-background-image;
}

.volumePreview .volume.volume9:after {
    top: 10%;
    height: 10%;
    background-image: $bad-volume-background-image;
}

.volumePreview .volume.volume10:after {
    height: 20%;
    background-image: $bad-volume-background-image;
}

/* Video-audio call test interface. */
.callContainer.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1000000;
    background-color: $window-background-color;
}

.callContainer.fullscreen .call-icon {
    position: fixed;
    left: calc(50% - 3rem);
    top: calc(50% - 7rem);
    width: auto;
    height: auto;
    display: none;
}

.callContainer.fullscreen .call-icon svg {
    width: 6rem;
    height: 6rem;
}

.callContainer.enabled {
    display: block;
}

.callContainer.fullscreen .notice {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1000010;
    background: rgba(255, 255, 255, 0.75);
}

.callContainer .audio-only,
.callContainer .video-only {
    display: none;
}

.callContainer.audio:not(.video) .audio-only,
.callContainer.audio.video .video-only {
    display: block;
}

.callContainer.fullscreen .buttons {
    position: fixed;
    top: 1rem;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1000010;
}</style>
