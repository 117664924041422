export const vueSanitizeOptions = {
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    '<br>',
    'input',
    'img',
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    div: ['class', 'style'],
    a: ['href', 'name', 'target', 'class', 'style'],
    img: [
      'src',
      'srcset',
      'alt',
      'title',
      'width',
      'height',
      'loading',
    ],
    i: ['class'],
    input: ['checked', 'disabled', 'type'],
    span: ['class', 'style', 'data-tooltip'],
  },
  selfClosing: [
    'img',
    'i',
    'br',
    'hr',
    'area',
    'base',
    'basefont',
    'input',
    'link',
    'meta',
  ],
  allowedSchemes: [
    'http',
    'https',
    'ftp',
    'mailto',
    'tel',
    'data',
  ],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: [
    'href',
    'src',
    'cite',
  ],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
};
