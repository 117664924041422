import {AboutConfig} from "../../_core/types/theme/About.type";

const about: AboutConfig =  {
    title: 'About Timesy',
    content: [
        'We understand how painful and confusing it can be when you are facing a difficult decision and just don\'t know where to turn to for insights and answers.',
        'Welcome to Timesy.com – a sanctuary crafted to ease the burden of life\'s uncertainties. We comprehend the pain and confusion that accompany crises, and we recognize the importance of having a trustworthy source to turn to. Timesy is dedicated to assembling an unparalleled group of business coaches in one accessible platform, ensuring you find someone you can confide in.',
        'Our platform is designed to provide you with access to a diverse range of highly skilled and experienced coaches who are committed to offering you the guidance and insights you need - day or night.',
        'With our careful selection process, you can trust that <strong>every coach on our platform has been thoroughly screened</strong> to ensure their expertise and professionalism. Whether you\'re seeking clarity during a crisis or guidance for important decisions, Timesy is here to connect you with the best business coach for your unique needs.',
        'Our mission is clear – to <strong>provide you with a seamless connection to a diverse array of highly skilled and seasoned coaches.</strong> No matter the time of day or night, our platform is designed to be your beacon of enlightenment. Whether you are grappling with a crisis or seeking counsel for crucial decisions, Timesy is committed to linking you with the perfect psychic coach for your unique needs.',
    ],
    seo: {
        title: 'About us - Timesy',
        description: 'Experience personalized readings on Timesy. Select your coach, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted coaches.',
    },
    topAdvisors: {
        title: 'Check Our Top Coaches',
        ctaText: 'Show All Coaches'
    }
};

// @ts-ignore
export default about;
