<template>
  <div class="c-dropdown__item" :class="{ 'is-selected': props.selected }">
    <slot />
    <se-icon v-if="selected" name="check" :size="24" class="ml-8" />
  </div>
</template>

<script lang="ts" setup>
import SeIcon from '../icon/Icon';

const props = defineProps<{
  selected?: boolean
}>();
</script>

<script lang="ts">
export default {
  name: 'SeDropdownItem',
};
</script>
