<template>
  <se-header-public v-if="!isAuthenticated" />
  <se-header-auth v-else />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { usersStore } from '@/src/store/users';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import SeHeaderPublic from './header/HeaderPublic';
import SeHeaderAuth from './header/HeaderAuth';

const { $route } = getGlobalProperties();

const { isAuthenticated } = storeToRefs(usersStore());

const isPromoPage = computed(() => [
  '/top-psychic-readers',
  '/best-psychic-reader',
  '/landing',
  '/promo',
].includes($route.path));
</script>

<script lang="ts">
export default {
  name: 'SeHeader',
};
</script>
