<template>
    <div v-if="hasActiveChat" class="w-full py-3 bg-primary-20">
        <Link class="flex items-center justify-center !text-primary-140 gap-3" href="/dashboard/chat">
            <se-icon name="message-circle" :size="24" />
            <p class="font-bold text-center">You have an active chat. Click this message to go back to chat.</p>
        </Link>
    </div>
    <div v-else-if="isEmailUnverifiedVisible" class="w-full p-[12px] sm:p-3 bg-warning-10">
        <div class="flex items-start md:items-center justify-center !text-warning-120 gap-3">
            <se-svg name="circle-alert" :width="24" :height="24" class="shrink-0"/>
            <div class="relative text-b-s md:text-b-m gap-1 font-bold">
              Please confirm your email: {{currentUser.email}}
              (<u @click="$router.push('/dashboard/profile/edit')" class="cursor-pointer">Edit</u>).
              Check your inbox for confirmation link or
                <span class="underline cursor-pointer whitespace-nowrap"
                      :class="{'pointer-events-none opacity-70': emailVerificationSending}"
                      @click="resendEmailClick"> click here to resend.
                <se-spinner class="inline-block ml-10 top-4" size="1rem" v-if="emailVerificationSending"/>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import SeSvg from "../../ui-kit/svg/Svg.vue";
import SeIcon from "../../ui-kit/icon/Icon.vue";
import Link from "../../../components/Link.vue";
import {getGlobalProperties} from "../../helpers/getGlobalProperties";
import {usersStore} from "../../../store/users";
import SeSpinner from "../../ui-kit/spinner/Spinner.vue";

const { $router, $route, $toast } = getGlobalProperties();
const userStore = usersStore()
const { currentUser, isAdvisor, isUser } = storeToRefs(userStore);
const { resendVerificationEmail, getCurrentUser, setUserEmailVerified } = userStore

const hasActiveChat = computed(() => currentUser.value && currentUser.value.active_chat && $route.path !== '/dashboard/chat');
const isEmailUnverifiedVisible = computed(() => currentUser.value && !currentUser.value.email_verified && $route.path !== '/dashboard/chat' && !$route.path.includes('/get-started') && !$route.path.includes('/dashboard/inbox'));
const emailVerificationSending = ref(false)

const resendEmailClick = (() => {
    emailVerificationSending.value = true
    resendVerificationEmail().then(() => {
        $toast({
            message: 'Email verification link sent to your email',
            type: 'success',
            icon: 'ion-close',
            duration: 10000
        })
    }).catch((err: any) => {
        if (err.status === 422) {
            $toast({
                message: 'Invalid email address. Please change email address in your profile  <a href="/dashboard/profile/edit" class="underline cursor-pointer">settings</a>',
                type: 'error',
                icon: 'ion-close',
                duration: 10000
            })
        } else if (err.status === 400) {
            $toast({
                message: 'Email is already verified',
                type: 'warning',
                icon: 'ion-close',
                duration: 10000
            })
            setUserEmailVerified(true)
            getCurrentUser()
        } else {
            $toast({
                message: 'Something went wrong',
                type: 'error',
                icon: 'ion-close',
                duration: 10000
            })
        }
    }).finally(() => {
        emailVerificationSending.value = false
    })
})

</script>

<script lang="ts">
export default {
    name: 'SeHeaderAlertBanner',
};
</script>

<style scoped lang="scss">

</style>
