<template>
	<li class="dropdown nav-item mega-dropdown" :class="{ 'show': loaded && isActiveMegaMenu }" v-click-outside="hideMegaMenu">
		<a href="javascript:;" data-toggle="dropdown" class="dropdown-toggle nav-link font-weight-bold"
			@click="showMegaMenu">
			{{ $theme.captions.Advisors }}
			<font-awesome-icon icon="angle-down" />
		</a>
		<ul class="mega-dropdown-menu dropdown-menu row" v-if="loaded">
			<li class="col-12">
				<h6 class="dropdown-menu-header text-uppercase"><font-awesome-icon icon="users" />
					{{ $theme.captions.Advisor }} Categories
				</h6>
				<ul class="drilldown-menu">
					<li class="menu-list">
						<ul>
							<li v-for="category in categories" :key="category.id">
								<Link class="dropdown-item" :href="'/advisors/' + category.url">
								<font-awesome-icon icon="user-circle" />{{ category.name }}
								</Link>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
	</li>
</template>

<script>
import { mapState } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'

import Link from '@/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle, faAngleDown, faUsers } from '@fortawesome/free-solid-svg-icons'
library.add(faUserCircle, faAngleDown, faUsers)
import vClickOutside from 'click-outside-vue3'

export default {
	name: "CategoriesMenu",
	data() {
		return {
			isActiveMegaMenu: false,
			loaded: false,
		}
	},
	components: {
		Link,
		FontAwesomeIcon,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	mounted() {
		this.loaded = true
	},
	methods: {
		showMegaMenu() {
			this.isActiveMegaMenu = !this.isActiveMegaMenu;
		},
		hideMegaMenu() {
			this.isActiveMegaMenu = false;
		},
	},
	computed: {
		...mapState(usersStore, [
			'categories',
		]),
		...mapState(rootStore, [
		]),
	}
}
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

.mega-dropdown {
  position: relative !important;

  .mega-dropdown-menu {
    width: auto;
    min-width: 13.75rem;
    max-width: 20rem;
    left: 0;
    margin: 0 !important;

    @media screen and (max-width: $tablet) {
      width: 100%;
      max-width: initial;
    }

    h6{
      white-space: nowrap;
    }
  }
}


</style>
