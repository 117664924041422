export { render as onRenderClient }

import { createApp } from '@/src/renderer/app'
import config from '@/config'
// import { usersStore } from '@/src/store/users'
// import { rootStore } from '@/src/store/root'
import Analytics from '@/src/helpers/analytics'
import Messaging from '@/src/helpers/messaging'
import VideoAudioChat from '@/src/helpers/videoAudioChat'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
	apiKey: config.bugsnag.apiKey,
	releaseStage: config.bugsnag.stage,
	plugins: [new BugsnagPluginVue()],
	// otherOptions: {}
})
const bugsnagVue = Bugsnag.getPlugin('vue')

// Enable Client Routing https://vike.dev/page-redirection
// export const clientRouting = true

// Prefetch links as soon as they enter the viewport
// export const prefetchStaticAssets = { when: 'VIEWPORT' }

let lastPath = null
let lastApp = null
async function render(pageContext) {
	const clientLibs = {
		Analytics,
		Messaging,
		VideoAudioChat,
	}
	const { app, store } = await createApp(pageContext, clientLibs)

	// Enabling sending bugs to bugsnag.
	app.use(bugsnagVue)

	// We hydrate the first page. (Since we do SSR, the first page is already
	// rendered to HTML and we merely have to hydrate it.)
	if (
		lastApp
		&& lastPath !== pageContext.urlPathname
		// && !['/login'].includes(pageContext.urlPathname)
	) {
		await lastApp.unmount()
		lastApp = null
		// app.changePage()
		lastPath = pageContext.urlPathname
	}
	// app.mount('#app')
	if (!lastApp && app) {
		await app.mount('#app')
		lastApp = app
	}
}
