<template>
  <!--  TODO: add dropdown elements -->
  <!--  <se-dropdown placement="bottom-end">-->
  <!--    <template #trigger>-->

  <Link class="flex justify-center items-center px-6" href="/dashboard/notifications">
    <se-svg v-if="unreadNotifications" name="dot-bell" :height="24" :width="24" />
    <se-icon v-else name="bell" :size="24" class="mr-8" />
  </Link>
  <!--    </template>-->
  <!--  </se-dropdown>-->
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import { usersStore } from '@/src/store/users';
import SeSvg from '@/src/shared/ui-kit/svg/Svg';
import Link from '@/src/components/Link';

const { unreadNotifications } = storeToRefs(usersStore());
</script>

<script lang="ts">
export default {
  name: 'SeHeaderNotifications',
};
</script>
