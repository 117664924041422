import {GeneralConfig} from "../../_core/types/theme/General.type";

const company = {
    name: 'Osendo BV',
    kvk: '82850836',
    address: 'Raamplein 1, 1016XK Amsterdam',
    phone: '0617422916',
};

const general: GeneralConfig =  {
    siteName: 'MysticWork',
    domain: 'mysticwork.com',
    logoDark: '/static/images/theme_icons/mystic/logo_color.svg',
    logoWhite: '/static/images/theme_icons/mystic/logo_color.svg',
    logoColor: '/static/images/theme_icons/mystic/logo_color.svg',
    logoSymbol: '/static/images/theme_icons/mystic/logo_symbol.svg',
    company,
    address: `${company.name}<br>KVK-nummer: ${company.kvk}<br>${company.address}<br><br>Call: <a href="tel:${company.phone}" class="!text-primary-100">${company.phone}</a><br>Mon - Fri 9:00 - 17:00`,
    advisors: 'advisors',
    advisor: 'advisor',
    supportEmail: 'support@mysticwork.com',
    socials: {
        facebook: 'https://www.facebook.com/mysticworkcom/',
        instagram: 'https://www.instagram.com/mysticworkcom/',
        youtube: 'https://www.youtube.com/@mysticwork6864',
        tiktok: 'https://www.tiktok.com/@mysticwork',
    }
};

// @ts-ignore
export default general;
