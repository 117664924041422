<template>
  <div v-if="smallScreenWithManyOptions || !largeMode || promotions.length > 3" class="mb-2">Select amount:</div>
  <div v-if="promotionsLoader" class="flex justify-center w-full text-center my-32">
    <loading-icon :size="6" thickness="xs"/>
  </div>
  <div v-else class="w-full mb-4">
    <select v-if="smallScreenWithManyOptions || !largeMode || promotions.length > 3" v-model="amount"
            placeholder="Select" value-key="amount"
            data-cy="addfunds-select" class="cust-select text-b-m mx-0 w-100 !p-12">
      <option value="" selected hidden>Select</option>
      <template v-for="item in promotions" :key="item.amount">
        <option
          :value="(item.off ? item : item.amount)" :class="{ 'is-discount': item.off }"
          :data-cy="'addfunds-item-' + item.amount"
        >
          <template v-if="item.off">
            {{ item.off }}% OFF - ${{ item.amount }} <b>(Save ${{ Math.round(item.amount * item.off / 100, 1) }})</b>
          </template>
          <template v-else-if="item.minutes">
            {{ item.minutes }} minutes - ${{ Number(item.amount).toFixed(2) }}
          </template>
          <template v-else>
            ${{ item.amount }}
          </template>
        </option>
      </template>
    </select>
    <div v-else-if="singleMode && minutes"
         class="cards-container large-payment-options text-center justify-content-center mb-5">
      <template v-for="item in promotions" :key="item.amount">
        <div v-if="amount == (item.off ? item : item.amount)">
          <label
            :class="{ 'is-discount': item.off, 'selected': amount == (item.off ? item : item.amount) }"
            class="mb-4 py-4 px-2 mx-2 large-payment-option text-center"
            :data-cy="'addfunds-item-' + item.amount"
            :for="'addfunds-item-' + item.amount"
          >
            <input
              type="radio"
              group="add-funds-buttons"
              v-model="amount"
              :id="'addfunds-item-' + item.amount"
              :value="(item.off ? item : item.amount)"
              class="hidden"
            />
            
            <div class="d-md-flex flex-row-reverse align-items-center justify-content-around">
              <div>
                <h4 class="small">
                  {{ advisor.default_free_minutes }} FREE minutes
                  <span v-if="advisor.discount">& {{ advisor.discount }}% off</span>
                  +
                </h4>
                <h3>
                  {{ item.minutes }} minutes
                </h3>
              </div>
              
              <div class="mt-4 mt-md-0">
                <div>
                  ${{ Number(item.amount).toFixed(2) }}
                  <span class="old-price" v-if="advisor?.discount">
                      ${{ (item.amount * 100 / Math.max(1, 100 - advisor.discount)).toFixed(2) }}
                  </span>
                </div>
                <!-- <button class="btn btn-primary mt-3">Selected</button> -->
                <se-button
                  size="small"
                  type="primary"
                >
                  Selected
                  <se-icon name="mail" :size="18" class="mr-6"/>
                </se-button>
              </div>
            </div>
          </label>
        </div>
      </template>
    </div>
    <div v-else class="cards-container flex large-payment-options justify-content-center">
      <template v-for="item in promotions" :key="item.amount">
        <label
          :class="{ 'is-discount': item.off, 'selected': amount == (item.off ? item : item.amount) }"
          class="mb-4 px-8 xs:px-16 py-20 pb-16 xs:py-24 large-payment-option text-center cursor-pointer"
          :data-cy="'addfunds-item-' + item.amount"
          :for="'addfunds-item-' + item.amount"
        >
          <span v-if="item.default" class="popular-tag">popular</span>
          <input
            type="radio"
            group="add-funds-buttons"
            v-model="amount"
            :id="'addfunds-item-' + item.amount"
            :value="(item.off ? item : item.amount)"
            class="hidden"
          />
          
          <div class="text-h-l xs:text-h-xl font-semibold">
            <span v-if="item.off">
                {{ item.off }}% OFF - ${{
                item.amount
              }} <b>(Save ${{ Math.round(item.amount * item.off / 100, 1) }})</b>
            </span>
            <span v-else-if="item.minutes">
                {{ item.minutes }} min <!-- - ${{ Number(item.amount).toFixed(2) }} -->
            </span>
            <span v-else>
                ${{ Number(item.amount).toFixed(2) }}
            </span>
          </div>
          
          <div
            class="inline-block bg-primary-10 text-primary-140 text-h-xs font-semibold rounded-[4px] px-8 py-[2px] mt-8">
            + {{ advisor.default_free_minutes }} FREE
            <span v-if="advisor?.discount" class="free-minutes">
                &amp; {{ advisor.discount }}% OFF
            </span>
          </div>
          
          <div class="h-[0.5px] w-full bg-neutral-30 my-16"></div>
          
          <div class="flex flex-col items-center">
            <p class="old-price" v-if="advisor?.discount">
              ${{ (item.amount * 100 / Math.max(1, 100 - advisor.discount)).toFixed(2) }}</p>
            <p class="new-price">${{ Number(item.amount).toFixed(2) }}</p>
          </div>
          
          <!--          <se-button-->
          <!--            class="w-[104px]"-->
          <!--            size="small"-->
          <!--            :type="amount === (item.off ? item : item.amount) ? 'primary' : 'tertiary'"-->
          <!--            @click="amount = (item.off ? item : item.amount)"-->
          <!--          >-->
          <!--            {{ amount === (item.off ? item : item.amount) ? 'Selected' : 'Select' }}-->
          <!--            <se-icon v-if="amount === (item.off ? item : item.amount)" name="check" :size="16" class="ml-6"/>-->
          <!--          </se-button>-->
        </label>
      </template>
    </div>
  </div>
  <se-payment-method :amount="amount" :redirect-url="redirectUrl" :advisor-id="advisorId"/>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia';
import {ref, watch, onMounted, computed} from 'vue';
import {usersStore} from '@/src/store/users';
import {getWindow} from "@/src/shared/helpers/getWindow";
import SeButton from '@/src/shared/ui-kit/button/Button';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SePaymentMethod from '@/src/modules/payment/components/payment-method';
import LoadingIcon from './LoadingIcon';

const props = withDefaults(defineProps<{
  redirectUrl: string,
  advisorId: number,
  chatMode: string,
  defaultToFirst: boolean,
  largeMode: boolean,
  singleMode: boolean,
  minutes: string,
}>(), {
  redirectUrl: "home",
  advisorId: 0,
  chatMode: '',
  defaultToFirst: false,
  largeMode: false,
  singleMode: false,
  minutes: '',
});

const userStore = usersStore();
const {getPromotions, getUserById} = userStore;
const {currentUser, chargeAmount, promotions} = storeToRefs(userStore);

const {windowWidth} = getWindow()

const fundsNeeded = computed(() => chargeAmount.value && chargeAmount.value > 0 ? Math.abs(Number(currentUser.value.amount) - chargeAmount.value) : 0);
const smallScreenWithManyOptions = computed(() => windowWidth.value <= 370 && promotions.value.length >= 3)

const amount = ref('')
const promotionsLoader = ref(true)
const advisor = ref({})

const updatePromotions = async () => {
  promotionsLoader.value = true;
  await getPromotions(props.advisorId, props.chatMode)
  promotionsLoader.value = false;
  
  if (props.largeMode && props.singleMode && props.minutes.length) {
    for (let promotionId in promotions.value) {
      if (promotions.value[promotionId].minutes == props.minutes) {
        amount.value = promotions.value[promotionId].amount
      }
    }
  }
  
  if (!props.largeMode && chargeAmount.value && fundsNeeded.value > 0) {
    const setPromotion = (promotion: any) => amount.value = promotion.off ? promotion : promotion.amount
    
    // Set amount to the first promotion which covers the needed funds
    for (let promotionId in promotions.value) {
      if (promotions.value[promotionId].amount >= fundsNeeded.value) {
        setPromotion(promotions.value[promotionId])
        
        break
      }
    }
    
    // If needed funds still exceed all the promotions, then select the last one
    if (!amount.value) {
      setPromotion(promotions.value[promotions.value.length - 1])
    }
  } else if (!amount.value || !props.singleMode) {
    if (props.defaultToFirst) {
      amount.value = promotions.value[0].amount
    } else {
      for (let promotionId in promotions.value) {
        if (promotions.value[promotionId].default) {
          amount.value = promotions.value[promotionId].amount
        }
      }
    }
  }
}
const getAdvisor = async () => {
  if (props.advisorId) {
    advisor.value = await getUserById(props.advisorId)
  }
}

watch(() => props.advisorId, () => {
  if (props.advisorId) {
    // Reloading promotions if having advisor specified.
    updatePromotions()
    getAdvisor()
  }
})

onMounted(() => {
  updatePromotions()
  getAdvisor()
});
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

.cards-container {
  @apply w-full;
}

.large-payment-options {
  @apply gap-16;
}

.large-payment-option {
  @apply rounded-12 w-full border border-neutral-30;
}

.large-payment-options .large-payment-option .btn-primary {
  display: none;
}

.large-payment-options .large-payment-option .btn-outline-dark {
  display: inline-block;
}

.large-payment-options .large-payment-option.selected .btn-primary {
  display: inline-block;
}

.large-payment-options .large-payment-option.selected .btn-outline-dark {
  display: none;
}

.large-payment-options .large-payment-option.selected {
  @apply border-primary-100 border-2;
}

.large-payment-option {
  @apply relative;
  
  .old-price {
    @apply text-b-s text-neutral-50 line-through;
  }
  
  .new-price {
    @apply text-h-m xs:text-h-l font-bold text-primary-100;
  }
  
  .popular-tag {
    @apply bg-gradient-primary rounded-[13px] uppercase absolute top-0 text-neutral-0 text-b-xs font-semibold inline-block px-6 translate-x-[-50%] translate-y-[-50%];
  }
}
</style>