import seeer from './seeer';
import timesy from './timesy';
import mystic from './mystic';
import psychic from './psychic';
import {Config} from "./_core/types/Config.type";
import config from '../config';

export enum AppTheme {
    SEEER = 'seeer',
    TIMESY = 'timesy',
    MYSTIC = 'mystic',
    PSYCHIC = 'psychic',
}
// Change default theme here
// Localy to test stuff for other themes
const defaultTheme = AppTheme.SEEER;

const themeName: AppTheme = config.theme.name as AppTheme || defaultTheme;

const configs: Record<AppTheme, Config> = {
    [AppTheme.SEEER]: seeer,
    [AppTheme.TIMESY]: timesy,
    [AppTheme.PSYCHIC]: psychic,
    [AppTheme.MYSTIC]: mystic,
}

const themeConfig = configs[themeName];

export const theme = themeConfig.theme;
