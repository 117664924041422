import { getClientData } from '@/src/helpers/checkClientData'
import {getStorageValue, removeStorageValue, setCookie} from '@/src/helpers/permanentValueStorage'
import config from '@/config'
import { usersStore } from '@/src/store/users'

const auth = {
    app: null,
    authenticated: false,
    TOKEN_VALUE_NAME: 'default_auth_token',
    // REMEMBER_LOGIN_VALUE_NAME: 'rememberMe',

    // impersonateData: {
    //     url: "oauth/impersonate",
    //     method: "POST",
    //     redirect: "/",
    // },
    // fetchData: {
    //     url: "api/user",
    //     method: "GET",
    //     enabled: true,
    // },

    /** Checks that token is valid, and assigns it to app.$api object for requests to backend.
    */
    refresh: async function (options) {
        if (this.check()) {
            let _this = this
            this.app.$api.setAuthHeaders(this.addApiTokenHeaders({}))
            await this.app.$api.get('/auth/check-token')
                .then((res) => {
                    console.info("check token success", res)
                    if (res?.status === 401) {
                        return Promise.reject(res)
                    }
                    if (options?.success) {
                        options.success(res)
                    }
                })
                .catch(async (res) => {
                    console.info("check token failed")
                    console.info(res)

                    if (options?.error) {
                        options.error(res)
                    }

                    // If unable to update user info this means the user token is obsolete.
                    if (res.status == 401) {
                        console.info("check token failed with 401")
                        _this.clearToken()
                    } else {
                        await _this.logoutInternal()
                    }
                })
        } else {
            this.clearToken()
        }

        this.app.$api.setAuthHeaders(this.addApiTokenHeaders({}))
    },

    /** Clears authentication token.
    */
    clearToken: function () {
        this.app.$api.setAuthHeaders({})
        this.headers.setToken('')
    },

    /** Saves current user IP.
    */
    saveUserIp: function () {
        this.app.$api.post('/api/user/ipinfo', getClientData())
            .then(res => null)
            .catch(function (error) {
                console.info(error.response)
            })
    },

    check: function () {
        return this.headers.checkToken()
    },

    login: async function (options) {
        // setStorageValue(this.REMEMBER_LOGIN_VALUE_NAME, options.rememberMe, 365)

        options.url = '/auth/authenticate'
        return await this.loginInternal(options)
    },

    googleSignIn: async function (options) {
        options.url = '/api/oauth-sign-in/google'
        return await this.loginInternal(options)
    },

    facebookSignIn: async function (options) {
        options.url = '/api/oauth-sign-in/facebook'
        return await this.loginInternal(options)
    },

    appleSignIn: async function (options) {
        options.url = '/api/oauth-sign-in/apple'
        return await this.loginInternal(options)
    },

    loginInternal: async function (options) {
        // this.authenticated = null
        let _this = this

        return await this.app.$api.post(options.url, options.data)
            .then(async (res) => {
                _this.headers.setToken(_this.getTokenFromResponse(res))

                await _this.refresh();

                if (options.success) {
                    await options.success(res)
                }

                if (options.redirect) {
                    _this.app.$router.push(options.redirect)
                }
            })
            .catch(async (res) => {
                console.log(res)
                _this.clearToken()
                _this.app.$api.setAuthHeaders({})

                if (options.error) {
                    await options.error(res)
                }
            })
    },

    logoutInternal: async function (options) {
        if (this.check()) {
            console.log('Logout internal')
            return this.app.$api.put('/api/user/logout', {})
                .then(async res => {
                    console.log('Successful logout internal')
                    this.clearToken()

                    if (options?.success) {
                        await options.success(res)
                    }

                    this.app.$router.push('/login');
                    // return res;
                })
                .catch(async error => {
                    console.log('Error logout internal')
                    this.clearToken()
                    console.info(error, error.response);
                    if (error.response && error.status !== 401) {
                        this.app.$toast({
                            message: '<b>Error: </b>' + error.response.data,
                            type: 'error'
                        })
                    }

                    if (options?.error) {
                        await options.error(res)
                    }
                })
        } else {
            this.clearToken()
        }
    },

    //logout user and redirect to login page
    logout() {
        this.logoutInternal({
            success: function () {
                removeStorageValue(auth.TOKEN_VALUE_NAME)
                this.app.$messaging.disconnect()
                console.info('logout success')
                // this.needToSubScribe = true
            }.bind(this),
        });
    },

    // Register the user and redirect him to login page.
    register: async function (options) {

        return await this.app.$api.post('/register', options.data)
            .then(async (res) => {

                if (options.success) {
                    await options.success(res)
                }

            })
            .catch(async (res) => {
                console.log(res)

                if (options.error) {
                    await options.error(res)
                }
            })
    },

    setTokenOptionsForRequest: function (token, requestOptions) {
        if (token) {
            requestOptions[config.api.authorizationHeaderName] = 'Bearer ' + token
        }
        return requestOptions
    },

    getTokenFromResponse: function (res) {
        let token = null

        if (res?.access_token) {
            token = res.access_token.trim()
        } else if (res?.data?.access_token) {
            token = res.data.access_token.trim()
        } else if (res?.headers) {
            tokenParts = (res.headers.Authorization || res.headers.authorization)
                .split(/Bearer\:?\s?/i);
            if (
                tokenParts[tokenParts.length > 1 ? 1 : 0].trim() !== 'undefined' &&
                tokenParts[tokenParts.length > 1 ? 1 : 0] !== 'undefined'
            ) {
                token = tokenParts[tokenParts.length > 1 ? 1 : 0].trim()
            }
        }

        return token
    },

    addApiTokenHeaders: function (headers) {
        console.log("addApiTokenHeaders")
        return this.setTokenOptionsForRequest(this.headers.getToken(), headers)
    },

    /** Opens a dialog that allows a user to confirm the authorized use of the current user account.
     * 
     */
    verifyUser: async function () {
        const users = usersStore()
        // Opening the verify user dialog.
        users.setShowVerifyUser(true)
        // Waiting for this dialog to be closed.
        while (users.showVerifyUser) {
            // console.log('checking')
            await new Promise(resolve => setTimeout(resolve, 50))
        }
        return users.lastVerifyUserResult
    },

    /** Opens a dialog that allows a user to input and verify the identity of the current user account.
     * 
     */
    requireIdentity: async function () {
        const users = usersStore()
        // Opening the verify user dialog.
        users.setShowStartIdentityVerification(true)
        // Waiting for this dialog to be closed.
        while (users.showStartIdentityVerification) {
            // console.log('checking')
            await new Promise(resolve => setTimeout(resolve, 50))
        }
        return users.currentUser.has_identity
    },

    headers: {
        get: function (auth) {
            console.log("headers.get")
            let token = (auth?.headers?.getToken() ?? this.getToken())

            return {
                'Accept': 'application/json',
                [config.api.authorizationHeaderName]: 'Bearer ' + token,
            }
        },
        checkToken: function () {
            // TODO: change to updating when token is obsolete.
            let authHeaders = !!auth.app.$api.authHeaders ? !!Object.keys(auth.app.$api.authHeaders).length : !!auth.app.$api.authHeaders
            this.authenticated = (!import.meta.env.SSR ? this.getToken() !== null : authHeaders)
            return this.authenticated
        },
        getToken: function () {
            return getStorageValue(auth.TOKEN_VALUE_NAME)
        },
        setToken: function (token) {
            // TODO: consider expires_in field to make token expire.
            setCookie(auth.TOKEN_VALUE_NAME, token, 365)

            // updating authenticated value.
            this.checkToken()
        },

        load: function () {
        },

        isInitialized: function () {
            return true;
        },

        init: function () {
        },
    }
}

export default (app) => {
    auth.app = app
    // app.auth = auth
    app.$auth = auth
    // updating authenticated value.
    auth.headers.checkToken()
    // app.use(auth)
    app.headers = auth.headers
    app.config.globalProperties.$auth = auth
    app.config.globalProperties.$headers = auth.headers
}
