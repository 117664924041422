<template>
	<transition name="modal">
		<div v-if="isActive">
			<div class="modal" tabindex="-1" role="dialog" aria-labelledby="Modal" style="display: block">
				<div class="modal-dialog modal-md" role="document" @click.stop="">
					<div class="modal-content">

						<div class="modal-header">
							<h5 class="modal-title">Confirm your action!</h5>
						</div>
						<div class="modal-body">
							<div class="alert alert-light text-center">
								{{ title }}

								<div v-if="$slots.default" class="mt-4">
									<slot />
								</div>
							</div>
						</div>
						<div class="modal-footer d-flex justify-content-between">
							<button class="btn btn-danger" @click="emitNo()" :class="{ 'is-loading': noLoading }">{{ noText
							}}</button>
							<button class="btn btn-success" @click="emitYes()" :class="{ 'is-loading': yesLoading }">{{
								yesText }}</button>
						</div>
					</div>
				</div>
			</div>

			<div class="modal-backdrop show"></div>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		"backdropClose": { default: true },
		"backdropCloseConfirm": { default: false },
		"backdropCloseConfirmText": { default: "Are you sure?" },
		isActive: {
			type: Boolean,
			required: true
		},
		yesLoading: Boolean,
		noLoading: Boolean,
		title: {
			type: String,
			required: true
		},
		noText: {
			type: String,
			default: 'Cancel'
		},
		yesText: {
			type: String,
			default: 'Okay'
		},
	},
	data() {
		return {}
	},
	methods: {
		closeModal() {
			this.$emit('onClose');
		},
		emitYes() {
			this.$emit('onApprove');
		},
		emitNo() {
			this.$emit('onReject');
		}
	},
	mounted() {
	},
	destroyed() {
	}
}
</script>

<style lang="scss" scoped>
.modal {
	overflow-x: hidden;
	overflow-y: auto;
}

button.close {
	cursor: pointer;
}

/* Transitions */
.modal-enter-active,
.modal-leave-active {
	transition: opacity .3s linear;
}

.modal-enter-active .modal,
.modal-leave-active .modal,
.modal-enter-active .modal-backdrop,
.modal-leave-active .modal-backdrop {
	transition: opacity .15s linear;
}

.modal-enter .modal,
.modal-leave-to .modal,
.modal-leave-active .modal,
.modal-enter .modal-backdrop,
.modal-leave-to .modal-backdrop,
.modal-leave-active .modal-backdrop {
	opacity: 0
}

.modal-enter-active .modal-dialog,
.modal-leave-active .modal-dialog {
	transition: transform .3s ease-out;
}

.modal-enter .modal-dialog,
.modal-leave-to .modal-dialog,
.modal-leave-active .modal-dialog {
	transform: translate(0, -25%);
}</style>
