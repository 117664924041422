<template>
  <svg v-bind="$attrs" :height="`${props.height / 16}rem`" :width="`${props.width / 16}rem`">
    <use :xlink:href="`/static/se-icons.svg?#${props.name}`" />
  </svg>
</template>

<script setup lang="ts">
import { withDefaults } from 'vue';

const props = withDefaults(defineProps<{
  name: string,
  height?: number,
  width?: number,
}>(), {
  height: 20,
  width: 20,
});
</script>

<script lang="ts">
export default {
  name: 'SeSvg',
};
</script>
