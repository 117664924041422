<template>
  <div v-if="props.name" class="c-icon" :class="`icon-${props.name}`" :style="{ '--se-icon-size': `${props.size / 16}rem` }" />
</template>

<script setup lang="ts">
import { withDefaults } from 'vue';

const props = withDefaults(defineProps<{
  name: string,
  size?: number,
}>(), {
  size: 20,
});

</script>

<script lang="ts">
export default {
  name: 'SeIcon',
};
</script>
