<template>
    <div class="loading-icon" :style="`width: ${size}rem; height: ${size}rem;`">
        <div>
            <div :class="thickness"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingIcon",
    props: {
        size: {
            type: Number,
            default: 20,
        },
        thickness: {
            type: String,
            default: null,
        },
    },
    computed: {
    },
}
</script>

<style lang="scss" scoped>  
@import "@/src/styles/variables";

.loading-icon {
    display: inline-block;
    background: transparent;
    overflow: hidden;
}
.loading-icon > div {
    position: relative;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}
.loading-icon > div > div {
    animation: loading-icon 2s linear infinite;
    position: absolute;
    box-sizing: content-box;
    width: 60%;
    height: 60%;
    top: 50%;
    left: 50%;
    border-radius: 50%;

    &:not(.thin) {
        border: 1.5rem solid var(--primary);
        border-top-color: transparent;
    }
    
    &.thin {
        border: 1rem solid var(--primary);
        border-top-color: transparent;
    }
    
    &.xs {
        border: .5rem solid var(--primary);
        border-top-color: transparent;
    }
}

@keyframes loading-icon {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

</style>
