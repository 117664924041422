import moment from 'moment'
import jstz from 'jstimezonedetect'
import 'moment-timezone'
import {getStorageValue, setStorageValue} from "@/src/helpers/permanentValueStorage";

// const momentJs = extendMoment(moment)
const momentJs = moment

export { TimeCalculations }

/** Time conversions and recalculations. 
*/
const TimeCalculations = {
    getTimezone: function () {
        let timezone = getStorageValue('_tz')
        if (!timezone) {
            let tz = jstz.determine()
            setStorageValue('_tz', tz.name())
            timezone = tz.name()
        }
        return timezone
    },
    getDaysFromDate(fromDate, toDate) {
        return this.rawMomentObject(toDate).diff(this.rawMomentObject(fromDate), 'days')
    },
    getSecondsFromDate(fromDate, toDate) {
        return this.rawMomentObject(toDate).diff(this.rawMomentObject(fromDate), 'seconds')
    },
    getCurrentTime: function() {
        return momentJs().utc()
    },
    getCurrentUnixTime: function() {
        return momentJs.utc().unix()
    },
    getTimeFromNow: function (time) {
        let timezone = this.getTimezone()
        time = this.rawMomentObject(time).tz(timezone)
        return time.fromNow()
    },
    formatTime: function (time, relative = true, showSeconds = false, showMinutes = true, showHours = true, dataTimezone = 'UTC', outputTimezone = 'UTC') {
        let now = Date.now()
        time = this.rawMomentObject(time, '', dataTimezone)
        time = this.rawSetTimezone(time, outputTimezone)

        if (Math.abs(time.diff(now, 'days')) > 1 || !relative) {
            return time.format('DD-MM-YYYY' + (showHours ? ', HH' + (showMinutes ? ':mm' + (showSeconds ? ':ss' : '') : '') : ''))
        } else if (Math.abs(momentJs().diff(time)) < 60000) { 
            return 'just now'
        } else {
            return time.fromNow()
        }
    },
    formatDate: function (date) {
        return this.formatTime(date, false, false, false, false)
    },
    datesEqual: function (date1, date2, compareBy = 'day') {
        return momentJs(momentJs(date1)).isSame(momentJs(date2), compareBy)
    },
    isTimeBetween: function (dateCheck, dateFrom, dateTo) {
        // const timeRange = momentJs().range(dateFrom, dateTo)
        // return timeRange.contains(dateCheck)
        dateCheck = momentJs(dateCheck)
        dateFrom = momentJs(dateFrom)
        dateTo = momentJs(dateTo)
        return dateCheck.isSame(dateFrom) || 
            dateCheck.isSame(dateTo) ||
            dateCheck.isBefore(dateTo) && dateCheck.isAfter(dateFrom)
    },

    rawMomentObject: function (time, format = '', timezone = 'UTC') {
        // UTC is the timezone configured in Laravel settings.
        if (typeof time === 'number') {
            time = momentJs.unix(time).tz(timezone)
        } else {
            if (typeof time === 'string') {
                time = momentJs.tz(time, timezone)
            }
            // console.log(time)
            if (format) {
                time = momentJs(time, format).tz(timezone)
            } else {
                time = momentJs(time).tz(timezone)
            }
        }
        return time
    },
    rawFormat: function (time, format) {
        return this.rawMomentObject(time).format(format)
    },
    rawFormatBeginningOf: function (time, beginningOf, format) {
        return this.rawMomentObject(time).startOf(beginningOf).format(format)
    },
    rawAddTime: function (timeObject, numberOfIntervals, interval) {
        return timeObject.add(numberOfIntervals, interval)
    },
    rawSetTimezone: function (timeObject, timezone) {
        return timeObject.tz(timezone)
    },
}
