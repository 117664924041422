import {HowItWorksConfig} from "../../_core/types/theme/HowItWorks.type";

const howItWorks: HowItWorksConfig =  {
    title: 'How Timesy Works?',
    body: 'A brief step-by-step guide to make the most of your experience with your Timesy business coach.',
    steps: [
        {
            title: 'Select Your Coach',
            description: 'Read reviews of the coaches that resonate the most with you and the objective of your next reading to make sure that you can get the best psychic experience possible.'
        },
        {
            title: 'Choose Your Session Duration',
            description: 'Decide the duration of your coach session based on your preferences and availability. Tailor your experience to match your needs, whether it\'s a quick insight or a more                   in-depth exploration.'
        },
        {
            title: 'Sign Up With Ease',
            description: 'Sign up hassle-free by either using your existing social media account or creating a new one with your email address. Streamlined and secure, this step ensures a seamless entry into the mystical realm.'
        },
        {
            title: 'Select Your Payment Method ',
            description: 'Choose from a variety of convenient payment methods to make the process smooth and stress-free. Your chosen payment method ensures you have a personalized and uninterrupted coach consultation.'
        },
        {
            title: 'Connect with Your Business Coach',
            description: 'Initiate the chat with your selected business coach and experience the energy connection unfold. Share your questions, thoughts, and curiosities as you embark on a journey of insight and revelation.'
        },
        {
            title: 'Enjoy Your First 3 Free Minutes',
            description: 'Delve into the business realm with the first 3 minutes on the house. Use this time to connect with your coach and feel the energy before deciding to continue into the paid chat.'
        },
        {
            title: 'Seamless Transition to Paid Chat',
            description: 'After your initial 3 free minutes, seamlessly transition into a paid chat if you wish to continue your exploration. Experience the wisdom and guidance of your business coach beyond the introductory period.<br>With these simple steps, your psychic session becomes a personalized and accessible experience, allowing you to navigate the mystical realms and uncover the insights that await you.'
        },
    ],
    topAdvisors: {
        title: 'Check Our Top Coaches',
        ctaText: 'Show All Coaches'
    },
    seo: {
        title: 'How it works - Timesy',
        description: 'Choose your coach > Click \'Chat\' > Enjoy your reading.',
    },
};

// @ts-ignore
export default howItWorks;
