import {AboutConfig} from "../../_core/types/theme/About.type";

const about: AboutConfig =  {
    title: 'About PsychicVOP',
    content: [
        'We understand how painful and confusing it can be when a crisis in your life occurs, or when you are facing a difficult decision and just don\'t know where to turn to for insights and answers.',
        'Welcome to PsychicVOP.com – a sanctuary crafted to ease the burden of life\'s uncertainties. We comprehend the pain and confusion that accompany crises, and we recognize the importance of having a trustworthy source to turn to. PsychicVOP is dedicated to assembling an unparalleled group of psychic advisors in one accessible platform, ensuring you find someone you can confide in.',
        'Our platform is designed to provide you with access to a diverse range of highly skilled and experienced psychics who are committed to offering you the guidance and insights you need - day or night.',
        'With our careful selection process, you can trust that <strong>every advisor on our platform has been thoroughly screened</strong> to ensure their expertise and professionalism. Whether you\'re seeking clarity during a crisis or guidance for important decisions, PsychicVOP is here to connect you with the best psychic advisor for your unique needs.',
        'Our mission is clear – to <strong>provide you with a seamless connection to a diverse array of highly skilled and seasoned psychics.</strong> No matter the time of day or night, our platform is designed to be your beacon of enlightenment. Whether you are grappling with a crisis or seeking counsel for crucial decisions, PsychicVOP is committed to linking you with the perfect psychic advisor for your unique needs.',
    ],
    seo: {
        title: 'About us - PsychicVOP',
        description: 'Experience personalized readings on PsychicVOP. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
    },
    topAdvisors: {
        title: 'Check Our Top Advisors',
        ctaText: 'Show All Advisors'
    }
};

// @ts-ignore
export default about;
