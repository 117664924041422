<template>
    <div>
        <div class="popup is-active" style="height: 100vh" v-if="isActive">
            <div class="popup-container">
                <h3 class="popup-heading">{{ title }}</h3>

                <div class="message" v-html="message"></div>

                <div class="buttons-wrapper">
                    <!-- <button class="btn btn-danger" 
                        @click="hide()" 
                    >Close</button> -->
                    <button class="btn btn-success" @click="close()">
                        <span v-if="buttonCaption">
                            {{ buttonCaption }}
                        </span>
                        <span v-else>
                            OK
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isActive: false,
            title: '',
            message: '',
            buttonCaption: '',
            buttonHandler: null,
        }
    },
    methods: {
        show(title, message, buttonCaption, buttonHandler) {
            this.isActive = true
            this.title = title
            this.message = message
            this.buttonCaption = buttonCaption
            this.buttonHandler = buttonHandler
        },
        hide() {
            this.isActive = false
        },
        close() {
            if (this.buttonHandler) {
                this.buttonHandler()
            }
            this.hide()
        },
    },
}
</script>

<style lang="scss" scoped>
.message {
    max-height: 75vh;
    overflow-y: auto;
}
</style>
