import { onMounted, ref } from 'vue';

const getWindow = (): any => {
  const windowWidth = ref(0);
  const windowHeight = ref(0);
  if (!import.meta.env.SSR) {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;

    const onResize = () => {
      windowWidth.value = window.innerWidth;
      windowHeight.value = window.innerHeight;
    };
    onMounted(() => {
      onResize();
      window.addEventListener('resize', onResize);
    });
  }
  return { windowWidth, windowHeight };
};
export {
  getWindow,
};
