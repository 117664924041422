// ES wrapper emulating lodash behavior.
import { isProxy, toRaw } from 'vue'

const arrayHelpers = {
    chain: function (array) {
        if (!array) {
            array = []
        }

        if (isProxy(array)) {
            // Restoring value from Vue.js Proxy object.
            array = toRaw(array)
        }

        var arrayHelperObject = {}//Object.assign({}, array)

        if (!arrayHelperObject.arrayHelpersInitialized) {
            arrayHelperObject.chain = () => arrayHelpers.chain(array)
            arrayHelperObject.map = (callback) => arrayHelpers.chain(arrayHelpers.map(array, callback))
            arrayHelperObject.reduce = (callback) => arrayHelpers.chain(arrayHelpers.reduce(array, callback))
            arrayHelperObject.filter = (callback) => arrayHelpers.chain(arrayHelpers.filter(array, callback))
            arrayHelperObject.take = (length) => arrayHelpers.chain(arrayHelpers.take(array, length))
            arrayHelperObject.groupBy = (fieldName) => arrayHelpers.chain(arrayHelpers.groupBy(array, fieldName))
            arrayHelperObject.uniqBy = (fieldName) => arrayHelpers.chain(arrayHelpers.uniqBy(array, fieldName))
            arrayHelperObject.sortBy = (fieldName) => arrayHelpers.chain(arrayHelpers.sortBy(array, fieldName))
            arrayHelperObject.maxBy = (fieldName) => arrayHelpers.maxBy(array, fieldName)
            arrayHelperObject.sumBy = (fieldName) => arrayHelpers.sumBy(array, fieldName)
            arrayHelperObject.sum = () => arrayHelpers.sum(array)
            arrayHelperObject.head = () => arrayHelpers.chain(arrayHelpers.head(array))
            arrayHelperObject.value = () => array

            arrayHelperObject.arrayHelpersInitialized = true
        }

        return arrayHelperObject
    },

    map: (array, callback) => {
        let result = []
        if (typeof array === 'object') {
            for (let field in array) {
                result[result.length] = callback(array[field], field)
            }
        } else {
            result = arrayHelpers.toArray(array).map(callback)
        }
        return result
    },

    reduce: (array, callback) => arrayHelpers.toArray(array).reduce(callback),

    filter: (array, callback) => arrayHelpers.toArray(array).filter(callback),

    take: (array, length) => arrayHelpers.toArray(array).slice(0, Math.max(0, length - 1)),

    groupBy: function (array, callback) {
        let result = {}
        for (let i in array) {
            const groupValue = callback(array[i])
            if (!result[groupValue]) {
                result[groupValue] = []
            }
            result[groupValue][result[groupValue].length] = array[i]
        }
        return result
    },

    uniqBy: function (array, fieldName) {
        let result = []
        let foundValues = {}
        for (let i in array) {
            if (!foundValues[fieldName]) {
                result[result.length] = array[i]
                foundValues[fieldName] = true
            }
        }
        return result
    },

    sortBy: function (array, fieldName) {
        if (!Array.isArray(array)) {
            array = Object.entries(array)
        }
        return array.sort((a, b) => {
            if (typeof fieldName === 'function') {
                return fieldName(a) - fieldName(b)
            } else {
                return a[fieldName] - b[fieldName]
            }
        })
    },

    maxBy: function (array, fieldName) {
        let max = undefined
        for (let i in array) {
            let value = (array[i] ? array[i][fieldName] : 0)
            if (max === undefined || max < value) {
                max = value
            }
        }
        return max
    },

    sumBy: function (array, fieldName) {
        let sum = 0
        for (let i in array) {
            let curVal = (array[i] ? array[i][fieldName] : 0)
            if (typeof curVal == 'string') {
                curVal = parseFloat(curVal)
            }
            sum += curVal
        }
        return sum
    },

    sum: function (array) {
        let sum = 0
        for (let i in array) {
            let curVal = array[i]
            if (typeof curVal == 'string') {
                curVal = parseFloat(curVal)
            }
            sum += curVal
        }
        return sum
    },

    head: function (array) {
        let head = null
        for (let i in array) {
            head = array[i]
            break
        }
        return head
    },

    toArray: function (object) {
        if (typeof object === 'array') {
            return object
        } else if (Symbol.iterator in Object(object)) {
            let array = new Array
            for (let i in object) {
                array[i] = object[i]
            }
            return object
        } else {
            return [object]
        }
    }
}


export default arrayHelpers
