import { createSSRApp, h } from 'vue'
import PageShell from '@/src/layouts/PageShell.vue'
import { setPageContext } from '@/src/helpers/usePageContext'
import httpRequest from '@/src/helpers/httpRequest'
import auth from '@/src/helpers/auth'
import { Howl } from 'howler'
import FlipNotifications from '@/src/helpers/flipNotifications'
import router from '@/src/helpers/router'
import config from '@/config'
import { createPinia } from 'pinia'
import { usersStore } from '@/src/store/users'
import { rootStore } from '@/src/store/root'
import metaInfo from '@/src/mixins/metaInfo'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import Vue3Sanitize from "vue-3-sanitize";
import {vueSanitizeOptions} from "@/src/shared/plugins/sanitize";
import {getStorageValue} from "@/src/helpers/permanentValueStorage.js";

export { createApp }

let rootComponent = null
async function createApp(pageContext, clientLibs = {}) {
    const { Page, pageProps } = pageContext
    const pageWithLayout = {
        data: () => ({
            Page: Page,
            pageProps: pageContext.pageProps || {},
        }),
        created() {
            rootComponent = this
        },
        render() {
            return h(
                PageShell,
                {
                },
                {
                    default: () => {
                        return h(Page, pageProps || {})
                    },
                },
            )
        },
    }

    const app = createSSRApp(pageWithLayout)

    // Add AJAX requests module.
    // Init http request module for API (backend) requests.
    let api = httpRequest
    api.defaults.baseURL = config.api.url
    api.defaults.timeout = config.api.timeout
    app.use((app) => {
        api.setApp(app)
        app.$api = api
        app.config.globalProperties.$api = api
    })

    if (!import.meta.env.SSR) {
        app.use((app) => {
            app.$chatSound = new Howl({
                src: [config.theme.sounds.chat],
                loop: true,
                // onend: function () {
                // 	console.log('Chat sound played!');
                // }
            });
            app.$messageSound = new Howl({
                src: [config.theme.sounds.message],
                loop: false,
                // onend: function () {
                // 	console.log('Message sound played!');
                // }
            });
            app.$notificationSound = new Howl({
                src: [config.theme.sounds.notification],
                loop: false,
                // onend: function () {
                // 	console.log('Notification sound played!');
                // }
            });

            app.config.globalProperties.$chatSound = app.$chatSound
            app.config.globalProperties.$messageSound = app.$messageSound
            app.config.globalProperties.$notificationSound = app.$notificationSound
        })

        // Adding $toast
        app.use((app) => {
            const toast = (options) => { new FlipNotifications(options).show() }
            app.$toast = toast
            app.config.globalProperties.$toast = toast
        })
    }

    const store = createPinia()
    store.use(({ app, store }) => {
        store.$api = app.$api
        store.$auth = app.$auth
        store.$router = app.$router
        store.$chatSound = app.$chatSound
        store.$messageSound = app.$messageSound
        store.$analytics = app.$analytics
        store.$notificationSound = app.$notificationSound
        store.$toast = app.$toast
        Object.defineProperty(store, "$messaging", {
            get() {
                return app.$messaging
            },
        })
        Object.defineProperty(store, "$video", {
            get() {
                return app.$video
            },
        })
    })
    app.use(store)
    app.use(router, pageContext)

    app.use(Vue3Sanitize, vueSanitizeOptions);

    // Adding $analytics.
    if (clientLibs?.Analytics) {
        app.use(clientLibs.Analytics)
    }
    // Adding $messaging.
    if (clientLibs?.Messaging) {
        app.use(clientLibs.Messaging)
    }
    // Adding $video.
    if (clientLibs?.VideoAudioChat) {
        app.use(clientLibs.VideoAudioChat)
    }

    // Adding phone input control.
    app.use(VueTelInput, { mode: 'international', inputOptions: { showDialCode: true, placeholder: '+000000000000' } })

    // Adding the theme to the globals, this works only on client.
    app.config.globalProperties.$theme = config.theme
    // Meta tags
    app.mixin(metaInfo)

    // Adding this.auth and this.headers
    app.use(auth)

    if (!import.meta.env.SSR) {
        // Restoring initial state saved on server.
        if (store.state && pageContext.initialStoreState) {
            store.state.value = pageContext.initialStoreState
        }
        // Validating auth token.
        await app.$auth.refresh()

        // Reading common data before application starts.
        if (app.$auth.check()) {
            const users = usersStore(store)
            const root = rootStore(store)
            await Promise.all([
                // Reading current user.
                users.getCurrentUser(null, true),
                // Saving current user IP.
                app.$auth.saveUserIp(),
                // Synchronizing time.
                // root.startTimeSynchronization(),
                root.getTimeDifference(),
                // Reading IDs of users that are online.
                users.getOnlineUsers(),
            ])
        }
    } else {
        const users = usersStore(store)
        let token = getStorageValue('default_auth_token', pageContext)
        if (token) {
            app.$api.setAuthHeaders({[config.api.authorizationHeaderName]: "Bearer " + token})
            await users.getCurrentUser(null)
        }

        await Promise.all([
            users.getCategories(),
            users.getOnlineUsers(),
        ])
    }

    setPageContext(app, pageContext)

    return { app, store }
}
