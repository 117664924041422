/** Wrapper for video/audio chat librariy Agora RTC.
*/

import AgoraRTC from "agora-rtc-sdk-ng"
import { chatStore } from '@/src/store/chat'
import config from '@/config'

// Example of library usage https://github.com/AgoraIO/API-Examples-Web/blob/main/Demo/joinMutlipleChannel/joinMultipleChannel.js
class VideoAudioChat {
    // Keeps volumes values array, measured with an interval as percent values
    // to display whether microphone is on and working.
    audioVolumeHistory = []
    audioVolumeMeasureLimit = 200

    constructor(app) {
        this.chat = chatStore()

        this.app = app
        // this.userId = null
        // this.opponentId = null
        this.options = {}

        this.callElement = null
        this.previewElement = null

        this.chat.setVideoOn(false)
        this.chat.setAudioOn(false)

        this.videoMode = false
        this.audioMode = false

        // this.audioVolumeHistory = []
        this.audioVolumeMonitor = null
        this.audioVolumeMeasureInterval = 200
        // this.audioVolumeMeasureLimit = 50

        this.clients = {
            host: {
                client: null,
                tracks: {
                    // videoTrack: null,
                    // audioTrack: null,
                },
            },
            subscriber: {
                client: null,
                tracks: {
                    // videoTrack: null,
                    // audioTrack: null,
                },
            },
        }
    }

    async init(userId, opponentId, previewVideoElement, mainVideoElement, videoMode = true, audioMode = true, shouldMonitorVolume = false) {
        const audioOnly = !videoMode

        this.chat.setVideoOn(videoMode)
        this.chat.setAudioOn(audioMode)

        this.videoMode = videoMode
        this.audioMode = audioMode

        this.callElement = mainVideoElement
        this.previewElement = previewVideoElement

        this.callElement.classList.add('enabled')
        if (videoMode) {
            this.callElement.classList.add('video')
        } else {
            this.callElement.classList.remove('video')
        }
        if (audioMode) {
            this.callElement.classList.add('audio')
        } else {
            this.callElement.classList.remove('audio')
        }

        AgoraRTC.setLogLevel(config.isProduction ? 4 /*NONE*/ : 0 /*DEBUG*/)

        // Receiving video/audio tokens and settings.
        let _this = this
        let options = await this.app.$api.post('/auth/refresh-video', {
            'opponent_id': opponentId,
        })
            .then(async (res) => {
                return res.data.options
            })
            .catch(async (res) => {
                console.info(res)

                // If unable to update user info this means the user token is obsolete.
                if (res.status == 422) {
                    await _this.app.$auth.logout()
                }
            })

        this.options = options

        let channelName = ''
        let token = ''
        {
            let client = null
            let role = 'host'
            token = options.auth[options.host_channel]
            channelName = options.host_channel

            client = AgoraRTC.createClient(options)
            client.setClientRole("host")

            // alert(options.appId +'.'+ channelName +'.'+ token +'.'+ userId)
            await client.join(options.appId, channelName, token, userId)
            if (client.connectionState != 'CONNECTED') {
                // Retrying if not connected.
                await client.join(options.appId, channelName, token, userId)
            }
            this.clients[role].client = client

            // Creating audio and video tracks.
            if (!this.clients.host.tracks.audioTrack) {
                try {
                    this.clients.host.tracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
                } catch (e) {
                    this.app.$toast({
                        message: '<b>Error: </b>' + 'Unable to use microphone. Please check that <b>microphone</b> is connected and working correctly.',
                        type: 'error',
                    })
                    this.stopTestCall()
                    return false
                }
            }
            if (!this.clients.host.tracks.videoTrack && !audioOnly) {
                try {
                    this.clients.host.tracks.videoTrack = await AgoraRTC.createCameraVideoTrack()
                } catch (e) {
                    this.app.$toast({
                        message: '<b>Error: </b>' + 'Unable to use camera. Please check that <b>camera</b> is connected and working correctly.',
                        type: 'error',
                    })
                    this.stopTestCall()
                    return false
                }
                // Play current user's tracks.
                if (previewVideoElement) {
                    this.clients.host.tracks.videoTrack.play(previewVideoElement)
                }
            }
            // this.clients.host.tracks.audioTrack.play()

            // Publish them to the channel.
            try {
                await client.publish(Object.values(this.clients.host.tracks))
                console.log("publish success")
            } catch (e) {
                // Trying again.
                try {
                    await client.publish(Object.values(this.clients.host.tracks))
                    console.log("publish success")
                } catch (e) {
                    console.error("publish failed", e)
                }
            }
        }

        {
            let client = null
            let role = 'subscriber'
            if (options.subscriber_channel != options.host_channel) {
                token = options.auth[options.subscriber_channel]
                channelName = options.subscriber_channel

                client = AgoraRTC.createClient(options)
                // alert(options.appId +'.'+ channelName +'.'+ token +'.'+ userId)
                await client.join(options.appId, channelName, token, userId)
                if (client.connectionState != 'CONNECTED') {
                    // Retrying if not connected.
                    await client.join(options.appId, channelName, token, userId)
                }
            } else {
                // Host camera and microphone test mode.
                if (this.callElement && this.clients.host.tracks.videoTrack) {
                    this.clients.host.tracks.videoTrack.play(this.callElement)
                }
                // this.clients.host.tracks.audioTrack.setDelay(1000)
                // this.clients.host.tracks.audioTrack.setVolume(1000) // Max volume.
                if (this.clients.host.tracks.audioTrack) {
                    this.clients.host.tracks.audioTrack.play()
                }
            }
            this.clients[role].client = client

            // Subscribing opponent user tracks.
            client.on("user-published", async (user, mediaType) => {
                // const uid = user.uid;
                // subscribe to a remote user
                console.log("subscribing")
                await client.subscribe(user, mediaType)
                console.log("subscribe success")
                if (mediaType == 'video' && !audioOnly) {
                    // const player = $(`
                    // <div id="player-wrapper-${uid}">
                    // <p class="player-name">remoteUser(${uid})</p>
                    // <div id="player-${uid}" class="player"></div>
                    // </div>
                    // `);
                    // $("#remote-playerlists").append(player);
                    if (this.callElement) {
                        user.videoTrack.play(this.callElement)
                        this.clients.subscriber.tracks.videoTrack = user.videoTrack
                    }
                } else if (mediaType == 'audio') {
                    // user.audioTrack.setVolume(1000) // Max volume.
                    user.audioTrack.play()
                    this.clients.subscriber.tracks.audioTrack = user.audioTrack
                }
            })

            if (shouldMonitorVolume) {
                // Measuring and accumulation volume levels.
                this.audioVolumeHistory = new Array(this.audioVolumeMeasureLimit).fill(0)
                this.audioVolumeMonitor = setInterval(() => {
                    if (this.clients.host.tracks.audioTrack) {
                        let volume = Math.round(this.clients.host.tracks.audioTrack.getVolumeLevel() * 100)
                        this.audioVolumeHistory.unshift(volume)
                        if (this.audioVolumeHistory.length > this.audioVolumeMeasureLimit) {
                            this.audioVolumeHistory.splice(this.audioVolumeMeasureLimit)
                        }
                    }
                    // console.log(this.audioVolumeHistory)
                }, this.audioVolumeMeasureInterval)
            }

            client.on("user-unpublished", (user, mediaType) => {
                // if (mediaType === 'video') {
                //     const id = user.uid;
                //     delete remoteUsers[id];
                //     $(`#player-wrapper-${id}`).remove();
                // }
            });
        }

    }

    async disconnect() {

        for (let trackName in this.clients.host.tracks) {
            var track = this.clients.host.tracks[trackName]
            if (track) {
                track.stop()
                track.close()
                this.clients.host.tracks[trackName] = null
            }
        }
        for (let trackName in this.clients.subscriber.tracks) {
            var track = this.clients.subscriber.tracks[trackName]
            if (track) {
                track.stop()
                track.close()
                this.clients.subscriber.tracks[trackName] = null
            }
        }

        for (let role in this.clients) {
            if (this.clients[role].client) {
                this.clients[role].client.leave()
            }
            this.clients[role].client = null
        }

        let element = this.callElement
        if (element) {
            element.classList.remove('enabled')
            this.callElement = null
        }
    }

    testCall(videoMode, audioMode, element, userId) {
        this.init(userId, userId, null, element, videoMode, audioMode, true)
        // let _this = this
        // element.addEventListener('click', function() {
        //     _this.stopTestCall()
        // })
    }
    stopTestCall() {
        this.disconnect()

        if (this.audioVolumeMonitor) {
            clearInterval(this.audioVolumeMonitor)
            this.audioVolumeMonitor = null
        }
        this.audioVolumeHistory = []
    }
    toggleAudioMute() {
        if (this.clients.host.tracks.audioTrack) {
            if (this.chat.audioOn) {
                this.clients.host.tracks.audioTrack.setMuted(true)
            } else {
                this.clients.host.tracks.audioTrack.setMuted(false)
            }
        }
        this.chat.setAudioOn(!this.chat.audioOn)
    }
    toggleVideoMute() {
        if (this.clients.host.tracks.videoTrack) {
            if (this.chat.videoOn) {
                this.clients.host.tracks.videoTrack.setMuted(true)
            } else {
                this.clients.host.tracks.videoTrack.setMuted(false)
            }
        }
        this.chat.setVideoOn(!this.chat.videoOn)
    }

    /** Returns true if currently on call and all requested streams are not muted.
    */
    isOnAir() {
        return (
            (this.videoMode || this.audioMode)
            && (
                this.videoMode
                && this.chat.videoOn
                && !!this.clients.host.tracks.videoTrack
                && !this.clients.host.tracks.videoTrack.muted
                || !this.videoMode
            )
            && (
                this.audioMode
                && this.chat.audioOn
                && !!this.clients.host.tracks.audioTrack
                && !this.clients.host.tracks.audioTrack.muted
                || !this.audioMode
            )
        )
    }

    /** Checking whether there is a malfunction.
    */
    hasMalfunction() {
        // console.log('[on air]', 
        //     this.callElement.querySelector('#' + this.callElement.id + ' > div > video'),
        //     this.previewElement.querySelector('#' + this.previewElement.id + ' > div > video')
        // )
        // console.log('[on air]', this.videoMode, this.clients.host.tracks.videoTrack.muted)
        console.log('[air]', {
            audioMode: this.audioMode,
            audioOn: this.chat.audioOn,
            audioTrack: !!this.clients.host.tracks.audioTrack,
            subscriberAudioTrack: !!this.clients.subscriber.tracks.audioTrack,
            notMuted: !this.clients.host.tracks.audioTrack.muted,
        })
        return !(
            (this.videoMode || this.audioMode)
            && (
                this.videoMode
                && this.chat.videoOn
                && !!this.clients.host.tracks.videoTrack
                && !!this.clients.subscriber.tracks.videoTrack
                && !this.clients.host.tracks.videoTrack.muted
                && this.callElement
                && this.previewElement
                && this.callElement.querySelector('#' + this.callElement.id + ' > div > video')
                && this.previewElement.querySelector('#' + this.previewElement.id + ' > div > video')
                || !this.videoMode
            )
            && (
                this.audioMode
                && this.chat.audioOn
                && !!this.clients.host.tracks.audioTrack
                && !!this.clients.subscriber.tracks.audioTrack
                && !this.clients.host.tracks.audioTrack.muted
                || !this.audioMode
            )
        )
    }
}

export default {
    install: function (app, options) {
        const video = new VideoAudioChat(app)
        app.config.globalProperties.$video = video
        app.$video = video
    }
}
